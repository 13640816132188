import {
  fetch,
  normalize,
  extractHubURL,
  mercureSubscribe as subscribe
} from '../../utils/dataAccess';
import { success as deleteSuccess } from './delete';
import {addAlert} from "common-web";

export function error(error: string | null) {
  return { type: 'CLINIC_LIST_ERROR', error };
}

export function loading(loading: boolean) {
  return { type: 'CLINIC_LIST_LOADING', loading };
}

export function success(retrieved: any) {
  return { type: 'CLINIC_LIST_SUCCESS', retrieved };
}

export function list(page: string , authToken: string) {
  return (dispatch: any) => {
    dispatch(loading(true));
    dispatch(error(null));

    fetch(page, authToken)
        .then((response: any) =>
            response
                .json()
                .then((retrieved: any) => ({ retrieved, hubURL: extractHubURL(response) }))
        )
        .then(({ retrieved, hubURL }: {[key: string]: any}) => {
          retrieved = normalize(retrieved);

          dispatch(loading(false));
          dispatch(success(retrieved));

          if (hubURL && retrieved['hydra:member'].length)
            dispatch(
                mercureSubscribe(
                    hubURL,
                    retrieved['hydra:member'].map((i: any) => i['@id'])
                )
            );
        })
        .catch((e: any) => {
          dispatch(loading(false));
          dispatch(addAlert({message: e.message}));
          dispatch(error(e.message));
        });
  };
}

export function reset(eventSource: any) {
  return (dispatch: any) => {
    if (eventSource) eventSource.close();

    dispatch({ type: 'CLINIC_LIST_RESET' });
    dispatch(deleteSuccess(null));
  };
}

export function mercureSubscribe(hubURL: any, topics: any) {
  return (dispatch: any) => {
    const eventSource = subscribe(hubURL, topics);
    dispatch(mercureOpen(eventSource));
    eventSource.addEventListener('message', event =>
      dispatch(mercureMessage(normalize(JSON.parse(event.data))))
    );
  };
}

export function mercureOpen(eventSource: any) {
  return { type: 'CLINIC_LIST_MERCURE_OPEN', eventSource };
}

export function mercureMessage(retrieved: any) {
  return (dispatch: any) => {
    if (1 === Object.keys(retrieved).length) {
      dispatch({ type: 'CLINIC_LIST_MERCURE_DELETED', retrieved });
      return;
    }

    dispatch({ type: 'CLINIC_LIST_MERCURE_MESSAGE', retrieved });
  };
}
