import React from 'react';
import {Translation} from "common-web";

export enum InsuranceStatus {
    PAID = 'status_paid',
    ACTIVE = 'status_active',
    EXPIRED = 'status_expired',
    DRAFT = 'status_draft',
    MODIFIED = 'status_modified',
}

interface IInsuranceStatusBadgeProps {
  status: InsuranceStatus;
}

class InsuranceStatusBadge extends React.Component<IInsuranceStatusBadgeProps> {

  render() {
    const status = this.props.status;

    return (
        <div className="badge-wrapper">
            <span className={`${"badge-text"} ${status}`}>
                <Translation text={`insuranceClinicReport.reportTable.status.${status}`}/>
            </span>
        </div>
    );
  }
}


export default InsuranceStatusBadge;
