import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {authTokenSelector, MultiSelect, MultiSelectType, IMultiselectOption} from 'common-web';
import {fixInjectedProperties, lazyInject} from '../../../../ioc';
import {connect} from 'react-redux';
import {RootState} from '../../../../store/reducers';
import {IAlertManagerService} from '../../../../service/alertManagerService';
import {list} from '../../../../actions/inquiry/list';
import {list as clinicList, reset as clinicReset} from '../../../../actions/clinic/list';
import {WithTranslation, withTranslation} from 'react-i18next';
import {
    clinicsListLoadingSelector,
    retrievedClinicsListSelector
} from '../../../../store/selectors/clinicListSelectors';
import {Subject, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

interface IConnectedSelectClinicInputProps {
    readonly retrievedClinics: { [key: string]: any };
    readonly clinicLoading: boolean;
    readonly list: any;
    readonly clinicList: any;
    readonly clinicReset: any;
    readonly authToken: string;
}

interface IExternalSelectClinicInputProps {
}

interface ISelectClinicInputProps extends IConnectedSelectClinicInputProps,
    IExternalSelectClinicInputProps,
    RouteComponentProps,
    WithTranslation {
}

interface ISelectClinicInputState {
    isLoading: boolean;
    reportInsuranceNumber: string | null;
    clinicListOpened: boolean;
    searchInputValue: typeof IMultiselectOption | null;
    debounced: string;
    defaultOptions: any[];
    options: any[];
}


class SelectClinicInput extends React.Component<ISelectClinicInputProps, ISelectClinicInputState> {
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

    private subscription: Subscription | null = null;
    private readonly onSearchSubject = new Subject();

    constructor(props: ISelectClinicInputProps) {
        super(props);

        this.state = {
            isLoading: false,
            reportInsuranceNumber: null,
            clinicListOpened: false,
            searchInputValue: null,
            debounced: '',
            defaultOptions: [],
            options: []
        };

        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.subscription = this.onSearchSubject
            .pipe(
                debounceTime(750),
                distinctUntilChanged()
            )
            .subscribe(
                (debounced: any) => this.handleClinicList(debounced)
            );

        this.props.clinicList(
            `clinics`,
            this.props.authToken
        );

        const {t} = this.props;
        const defaultOptions = [
            {
                value: 'all',
                label: t('insuranceClinicReport.allInsurances'),
                isDisabled: false
            },
            {
                value: 'clinic_hunter',
                label: t('insuranceClinicReport.clinicHunterOnly'),
                isDisabled: false
            },

        ];
        this.setState({defaultOptions: defaultOptions, options: defaultOptions});
    }


    componentDidUpdate(
        prevProps: Readonly<ISelectClinicInputProps>,
        prevState: Readonly<ISelectClinicInputState>,
        snapshot?: any
    ): void {
        if (this.props.retrievedClinics !== prevProps.retrievedClinics) {
            const {t} = this.props;
            let updatedOptions = [...this.state.defaultOptions];
            if (!this.props.retrievedClinics || !this.props.retrievedClinics['hydra:member'] ||
                !Array.isArray(this.props.retrievedClinics['hydra:member']) || !this.props.retrievedClinics['hydra:member'].length) {
                updatedOptions.push({
                    value: 'no_data',
                    label: t(`insuranceClinicReport.noClinic`),
                    isDisabled: true
                })
            } else {
                this.props.retrievedClinics['hydra:member'].map((item: any) => {
                    const option = {
                        value: item.id,
                        label: item.companyName,
                        isDisabled: false
                    }
                    updatedOptions.push(option);
                });
            }
            this.setState({options: updatedOptions});
        }
    }

    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    render() {
        const {t} = this.props;
        return (
            <React.Fragment>
                <div className="select clinic-select form-control mr-4">
                    <MultiSelect
                        multiselectType={MultiSelectType.SINGLE}
                        handleChange={
                            (e: any) => this.onSearch(e)
                        }
                        options={this.state.options}
                        name={'select_clinics'}
                        placeholder={t('insuranceClinicReport.selectClinic')}
                        value={[this.state.searchInputValue]}
                    />
                </div>
            </React.Fragment>
        );
    }

    private onSearch = (item: any) => {
        if (item.value.length <= 2) {
            return;
        }
        const searchValue = {value: item.value, label: item.label};
        this.setState({searchInputValue: searchValue});
        this.onSearchSubject.next(searchValue);
    };


    private handleClinicList = (searchInputValue: any) => {
        const {t} = this.props,
            allInsurancesText = t('insuranceClinicReport.allInsurances'),
            clinicHunterInsurancesText = t('insuranceClinicReport.clinicHunterOnly');
        if (searchInputValue.label === allInsurancesText || searchInputValue.label === clinicHunterInsurancesText) {
            this.setState({searchInputValue: this.state.searchInputValue, reportInsuranceNumber: null});
        }
        if (searchInputValue.label === allInsurancesText) {
            return this.props.list(
                `inquiries?order[createdAt]=DESC`,
                this.props.authToken,
            );
        }
        if (searchInputValue.label === clinicHunterInsurancesText) {
            return this.props.list(
                `inquiries?clinic[null]=true`,
                this.props.authToken,
            );
        }
        this.props.list(
            `inquiries?page=1&clinic.id=${searchInputValue.value}&order[createdAt]=DESC`,
            this.props.authToken,
        );
    };
}

export default withTranslation()(connect(
    (state: RootState) => ({
        retrievedClinics: retrievedClinicsListSelector(state),
        clinicLoading: clinicsListLoadingSelector(state),
        authToken: authTokenSelector(state),
    }),
    {
        clinicList,
        clinicReset,
        list
    }
)(withRouter(SelectClinicInput)));
