import {combineEpics, Epic, ofType, StateObservable} from "redux-observable";
import { catchError, switchMap, mergeMap } from "rxjs/operators";
import { of } from "rxjs";
import { PayloadAction } from "redux-starter-kit";
import {addAlert} from "common-web";
import {
  IRetrieveInsuranceAxaReport,
  retrieveInsuranceAxaReport,
  setInsuranceAxaReportState,
  changeInsuranceAxaReportStateLoading,
  setInsuranceAxaReportStateFailure
} from "../reducers/insuranceAxaReportSlice";
import {insuranceAxaReportAPI} from "../../api/insuranceAxaReportAPI";

const getInsuranceAxaReportEpic: Epic = (action$, state$: StateObservable<any>) =>
  action$.pipe(
    ofType(retrieveInsuranceAxaReport.type),
    switchMap((action: PayloadAction<IRetrieveInsuranceAxaReport>): any => {
      return insuranceAxaReportAPI(
        (state$ as any).value.auth.authToken,
        (action.payload as any).params
      ).pipe(
        mergeMap((resp) => {
          return of(
            setInsuranceAxaReportState(resp),
            changeInsuranceAxaReportStateLoading(false)
          )
        }),
        catchError((error: any) => {
          return of(
            setInsuranceAxaReportStateFailure(error.toString()),
            addAlert({message: error.response['hydra:description']}),
            changeInsuranceAxaReportStateLoading(false)
          )
        })
      )
    })
  );

const insuranceAxaReportEpic = combineEpics(
  getInsuranceAxaReportEpic
);

export default insuranceAxaReportEpic;
