import { combineReducers } from "redux";
import saga from "./sagaSlice";
import {connectRouter} from "connected-react-router";
import {createBrowserHistory} from "history";
import {
    alertSlice,
    authSlice,
    loginSlice,
    registrationSlice,
    resetPasswordSlice,
    changePasswordSlice,
    accountSlice,
    inquirySummarySlice,
    clinicProfileSlice
} from "common-web";
import specialistList from "../../reducers/specialist";
import specialistSlice from "./specialistSlice";
import clinicSlice from "./clinicSlice";
import clinicList from "../../reducers/clinic";
import treatmentCategory from "../../reducers/treatmentcategory";
import treatmentType from "../../reducers/treatmenttype";
import inquiryList from "../../reducers/inquiry";
import insuranceAxaReportSlice from "./insuranceAxaReportSlice";
import metadataSlice from "./metadataSlice";
import consultation from "../../reducers/consultation";
import recommendationDefinition from "../../reducers/recommendationDefinition";
import subscriptionDefinition from "../../reducers/subscriptionDefinition";
import invoices from "../../reducers/invoices";

const history = createBrowserHistory();

const rootReducer = combineReducers({
    saga: saga,
    account: accountSlice,
    auth: authSlice,
    login: loginSlice,
    registration: registrationSlice,
    resetPassword: resetPasswordSlice,
    changePassword: changePasswordSlice,
    alert: alertSlice,
    clinic: clinicSlice,
    specialist: specialistSlice,
    specialistList: specialistList,
    clinicList: clinicList,
    treatmentCategory: treatmentCategory,
    treatmentTypes: treatmentType,
    inquiryList: inquiryList,
    inquirySummary: inquirySummarySlice,
    insuranceAxaReport: insuranceAxaReportSlice,
    metadata: metadataSlice,
    clinicProfile: clinicProfileSlice,
    consultation: consultation,
    recommendationDefinition: recommendationDefinition,
    subscriptionDefinition: subscriptionDefinition,
    invoice: invoices,
    router: connectRouter(history)
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
