import { combineReducers } from 'redux';

export function error(state = null, action: {[key: string]: any}) {
    switch (action.type) {
        case 'CONSULTATION_LIST_ERROR':
            return action.error;

        case 'CONSULTATION_LIST_MERCURE_DELETED':
            return `${action.retrieved['@id']} has been deleted by another user.`;

        case 'CONSULTATION_LIST_RESET':
            return null;

        default:
            return state;
    }
}

export function loading(state = false, action: {[key: string]: any}) {
    switch (action.type) {
        case 'CONSULTATION_LIST_LOADING':
            return action.loading;

        case 'CONSULTATION_LIST_RESET':
            return false;

        default:
            return state;
    }
}

export function retrieved(state: {[key: string]: any} | null = null, action: {[key: string]: any}) {
    switch (action.type) {
        case 'CONSULTATION_LIST_SUCCESS':
            return action.retrieved;

        case 'CONSULTATION_LIST_RESET':
            return null;

        case 'CONSULTATION_LIST_MERCURE_MESSAGE':
            return {
                ...state,
                'hydra:member': state ? (state['hydra:member'].map((item: {[key: string]: any}) =>
                    item['@id'] === action.retrieved['@id'] ? action.retrieved : item
                )) : null
            };

        case 'CONSULTATION_LIST_MERCURE_DELETED':
            return {
                ...state,
                'hydra:member': state ? (state['hydra:member'].filter(
                    (item: {[key: string]: any}) => item['@id'] !== action.retrieved['@id']
                )) : null
            };

        default:
            return state;
    }
}

export function eventSource(state = null, action: {[key: string]: any}) {
    switch (action.type) {
        case 'CONSULTATION_LIST_MERCURE_OPEN':
            return action.eventSource;

        case 'CONSULTATION_LIST_RESET':
            return null;

        default:
            return state;
    }
}

export default combineReducers({ error, loading, retrieved, eventSource });
