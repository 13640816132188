import { Observable } from "rxjs";
import { RestQueryParams } from "common-web";
import {clinicAPI} from "./provider/clinicAPI";

export function insuranceAxaReportAPI(
  accessToken: string,
  params?: string,
): Observable<any> {
  let path = params ? `ftp_uploaded_files?${params}` : `ftp_uploaded_files`;

  return clinicAPI.get(
    path,
    new RestQueryParams(),
    {
      Authorization: `Bearer ${accessToken}`
    }
  );
}
