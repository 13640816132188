import { Observable } from "rxjs";
import { RestQueryParams } from "common-web";
import {clinicAPI} from "./provider/clinicAPI";

export function settleWithClinicAPI(
  accessToken: string,
  inquiryId: string,
  isSettled: boolean
): Observable<any> {
  const values: {[key: string]: any} = {};
  values[inquiryId] = isSettled;

  return clinicAPI.put(
    `inquiries/batch-update-settled-with-clinic`,
    {
      values
    },
    new RestQueryParams(),
    {
      Authorization: `Bearer ${accessToken}`
    }
  );
}
