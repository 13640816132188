import React from 'react';

export enum IOfferDetailType {
  PRICE = 'price',
  CLINIC_HUNTER_COMMISSION = 'clinic_hunter_commission',
  CLINIC_COMMISSION = 'clinic_commission',
  AXA_COMMISSION = 'axa_commission'
}

interface IConnectedInsuranceOfferDetailsProps {}

interface IExternalInsuranceOfferDetailsProps {
  item: {[key: string]: any};
  type: IOfferDetailType;
  style?: string;
}

interface IInsuranceOfferDetailsProps extends
  IConnectedInsuranceOfferDetailsProps,
  IExternalInsuranceOfferDetailsProps {}

interface IInsuranceOfferDetailsState {}

class InsuranceOfferDetails extends React.Component<IInsuranceOfferDetailsProps, IInsuranceOfferDetailsState> {

  render() {
    return (<span>
        {this.getInsuranceOfferDetails(this.props.item, this.props.type)}
      </span>
    );
  }

  private getInsuranceOfferDetails(item: any, type: IOfferDetailType) {
    if (!item || !item.offers || !Array.isArray(item.offers) || !item.offers.length) {
      return '';
    }
    const offer = item.offers[0],
      currency = offer.premium.currency.code,
      axaCommission = offer.premium.amount - offer.systemFee.amount - offer.clinicFee.amount;

    switch (type) {
      case IOfferDetailType.PRICE:
        return `${currency} ${offer.premium.amount === '0' ? '0.00' :(offer.premium.amount / 100 ).toFixed(2)}`;

      case IOfferDetailType.CLINIC_HUNTER_COMMISSION:
        return `${currency} ${(offer.systemFee.amount / 100).toFixed(2)}`;

      case IOfferDetailType.CLINIC_COMMISSION:
        const commissionPercent = (offer.clinicFee.amount === '0' || offer.premium.amount === '0') ? '0' :
            Math.round(offer.clinicFee.amount / offer.premium.amount * 100).toFixed(2);
        return `${currency} ${(offer.clinicFee.amount / 100).toFixed(2)} (${commissionPercent} %)`;

      case IOfferDetailType.AXA_COMMISSION:
        return `${currency} ${offer.premium.amount === '0' ? '0.00' : (axaCommission / 100).toFixed(2)}`;

      default:
        return '';
    }
  };
}

export default InsuranceOfferDetails;
