import { RootState } from "../reducers";
import { createSelector } from "redux-starter-kit";
import {IClinicState} from "../reducers/clinicSlice";

export interface IClinicListBaseState {
    loading: boolean;
    error: string;
}

export interface IClinicListState extends IClinicListBaseState {
    retrieved: any; // model for inquiry
    eventSource: EventSource;
}

export const selectClinicList = (state: RootState): IClinicListState => {
    return state.clinicList.list;
};

export const selectClinicState = (state: RootState): IClinicState => {
    return state.clinic;
};

export const retrievedClinicsListSelector = createSelector(
    [selectClinicList],
    (state: IClinicListState) => state.retrieved
);

export const clinicsListLoadingSelector = createSelector(
    [selectClinicList],
    (state: IClinicListState) => state.loading
);

export const clinicsListErrorSelector = createSelector(
    [selectClinicList],
    (state: IClinicListState) => state.error
);

export const clinicsListEventSourceSelector = createSelector(
    [selectClinicList],
    (state: IClinicListState) => state.eventSource
);

export const clinicStateLoadingSelector = createSelector(
    [selectClinicState],
    (state: IClinicState) => state.isLoading
);
