import {
    currencyList,
    FormButtonType,
    FormControlType,
    IFormConfig,
    InputType, MultiSelectType,
    ValidationRules
} from 'common-web';

export const addInvoiceFormConfig: typeof IFormConfig = {
    controlType: 'group',
    class: '',
    dataAccessor: (data: any) => data, // data is not grouped
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);

        return previousStateSnapshot;
    },
    controls: [
        {
            controlType: 'group',
            key: 'name',
            class: 'form-row custom-row',
            controls: {
                name: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        // {name: ValidationRules.IS_REQUIRED},
                    ],
                    placeholder: 'subscriptionPlans.form.planName',
                    label: 'subscriptionPlans.form.planName',
                    type: InputType.TEXT,
                    hostClass: 'col-50'
                },
                date: {
                    hostClass: 'col-50 horizontal',
                    controlType: 'control',
                    defaultValue: '',
                    additionalStyles: 'horizontal',
                    formControlType: FormControlType.DATE,
                    validationRules: [
                        { name: ValidationRules.IS_REQUIRED }
                    ],
                    placeholder: 'subscriptionPlans.form.date',
                    label: 'subscriptionPlans.form.date',
                    maxDate: '2100-01-01',
                    type: InputType.DATE,
                },
            }
        },
        {
            controlType: 'group',
            key: 'planPrice',
            class: 'form-row custom-row',
            controls: {
                price: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    placeholder: 'subscriptionPlans.form.planAmount',
                    label: 'subscriptionPlans.form.planPrice',
                    validationRules: [
                        // {name: ValidationRules.IS_REQUIRED},
                    ],
                    type: InputType.NUMBER,
                    hostClass: 'col-75 pr-0',
                    isCurrencyInput: true
                },
                currency: {
                    controlType: 'control',
                    defaultValue: null,
                    placeholder: 'subscriptionPlans.form.planCurrency',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    isCurrencySelect: true,
                    validationRules: [
                        // { name: ValidationRules.IS_REQUIRED },
                    ],
                    multiselectOptions: () => {
                        return currencyList.map((currency: any) => ({
                            value: currency.value,
                            label: currency.label
                        }))
                    },
                    inputDataMapper: (data: any) => {
                        if (!data) {
                            return [];
                        }

                        return currencyList
                            .filter((currency: any) => currency.value === data)
                            .map((currency: any) => ({
                                value: currency.value,
                                label: currency.label,
                            }));
                    },
                    outputDataMapper: (data: any) => {
                        if (Array.isArray(data)) {
                            return (data.length > 0) ? data[0].value : null;
                        }
                        if (data && typeof data === 'object') {
                            return data.value;
                        }

                        return data;
                    },
                    hostClass: 'col-25 pl-0',
                }
            }
        },
        {
            controlType: 'group',
            key: 'planStatus',
            class: 'form-row',
            controls: {
                paidAt: {
                    hostClass: 'col-50 horizontal',
                    controlType: 'control',
                    defaultValue: '',
                    additionalStyles: 'horizontal',
                    formControlType: FormControlType.DATE,
                    validationRules: [
                        // { name: ValidationRules.IS_REQUIRED }
                    ],
                    placeholder: 'subscriptionPlans.clinicInvoices.form.paid',
                    label: 'subscriptionPlans.clinicInvoices.form.paid',
                    maxDate: '2100-01-01',
                    type: InputType.DATE,
                }
            }
        },
        {
            controlType: 'group',
            key: 'subscription_submit',
            class: 'row',
            controls: {
                submitButton: {
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    hostClass: 'col-xl-12 mb-0',
                    defaultContainerStyles: '',
                    containerStyles: 'button-container mt-2',
                    defaultValue: null,
                    btnText: 'subscriptionPlans.clinicInvoices.editInvoice',
                },
            },
        },
    ]
};
