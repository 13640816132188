import { Observable } from "rxjs";
import { RestQueryParams } from "common-web";
import {clinicAPI} from "./provider/clinicAPI";

export interface IInquirySubject {
    id?: string;
    firstName: string;
    lastName: string;
    birthDate: string;
}

export interface IInquiryPatient {
    account: {
        user: {
            login: string;
        }
    }
}

export function updateInquiryAPI(
    accessToken: string,
    inquiryId: string,
    inquirySubjects: IInquirySubject[] | [],
    patientDetails: IInquiryPatient
): Observable<any> {
    return clinicAPI.put(
        `inquiry/${inquiryId}/update_users_credentials`,
        {
            inquirySubjects: inquirySubjects,
            patient: patientDetails
        },
        new RestQueryParams(),
        {
            Authorization: `Bearer ${accessToken}`
        }
    );
}
