import React from 'react';
import {Link, RouteComponentProps, withRouter} from 'react-router-dom';
import {
    authTokenSelector,
    BasicModal,
    CustomCard,
    CustomCardType, CustomPagination,
    Form,
    IFormConfig,
    RestQueryParams,
    Translation,
} from 'common-web';
import {initialClinicFormConfig} from "./formConfig";
import {connect} from "react-redux";
import {RootState} from "../../store/reducers";
import {list, reset} from '../../actions/clinic/list';
import {
    clinicsListErrorSelector,
    clinicsListEventSourceSelector,
    clinicsListLoadingSelector,
    clinicStateLoadingSelector,
    retrievedClinicsListSelector
} from "../../store/selectors/clinicListSelectors";
import create from '../../reducers/clinic/create';
import {addClinic} from '../../store/reducers/clinicSlice';
import {fixInjectedProperties, lazyInject} from "../../ioc";
import {IAlertManagerService} from "../../service/alertManagerService";
import {WithTranslation, withTranslation} from "react-i18next";
import DownloadClinicListButton from "./DownloadClinicListButton";

interface IConnectedClinicProps {
    readonly retrieved: any;
    readonly isClinicListLoading: boolean;
    readonly isClinicStateLoading: boolean;
    readonly error: string;
    readonly eventSource: EventSource;
    readonly list: any;
    readonly reset: any;
    readonly create: typeof create;
    readonly authToken: string;
    readonly addClinic: typeof addClinic;
}

interface IExternalClinicProps {
}

interface IClinicProps extends IConnectedClinicProps,
    IExternalClinicProps,
    RouteComponentProps,
    WithTranslation {
}

interface IClinicState {
    modalShown: boolean;
    isLoading: boolean;
    isFormValid: boolean;
    formConfig: typeof IFormConfig;
    value: any;
}

class Clinics extends React.Component<IClinicProps, IClinicState> {
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

    constructor(props: IClinicProps) {
        super(props);

        this.state = {
            modalShown: false,
            isLoading: false,
            isFormValid: false,
            formConfig: initialClinicFormConfig,
            value: null
        };

        fixInjectedProperties(this);
    }

    componentDidUpdate(
        prevProps: Readonly<IClinicProps>,
        prevState: Readonly<{}>,
        snapshot?: any
    ): void {
        if (this.props.error !== prevProps.error) {
            this.alertManager.handleApiError(this.props.error);
        }

        if (this.props.isClinicStateLoading !== prevProps.isClinicStateLoading
            && !this.props.isClinicStateLoading && this.state.modalShown) {
            this.setState({modalShown: false});
        }
    }

    componentWillUnmount() {
        this.props.reset(this.props.eventSource);
    }

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="view-header">
                            <div className="view-title">
                                <Translation text={'axaClinicList.title'}/>
                            </div>
                            <div className="action-container">
                                <DownloadClinicListButton authToken={this.props.authToken}/>
                                <button className="btn btn-theme"
                                        onClick={() => this.toggleModal()}>
                                    <Translation text={'axaClinicList.actions.addClinic'}/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                        <CustomCard showLocalLoader={this.props.isClinicListLoading}>
                            <CustomCard.Body>
                                <table className="data-table">
                                    <thead>
                                        <tr>
                                            <th><Translation text={'axaClinicList.name'}/> / <Translation text={'axaClinicList.clinicOwner'}/></th>
                                            <th><Translation text={'axaClinicList.address'}/></th>
                                            <th><Translation text={'axaClinicList.clinicId'}/></th>
                                            <th><Translation text={'axaClinicList.phone'}/></th>
                                            <th className="text-center"><Translation text={'axaClinicList.plan'}/></th>
                                            <th />
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {this.renderTableRows()}
                                    </tbody>
                                </table>

                                <CustomPagination retrieved={this.props.retrieved}
                                                  basePath="dashboard"
                                                  path="clinics"
                                                  provider={this.getClinicsList}
                                                  defaultPage={1}
                                                  defaultItemsPerPage={10}
                                />
                            </CustomCard.Body>
                        </CustomCard>
                    </div>
                </div>

                <BasicModal isModalShown={this.state.modalShown} closeModal={this.closeModal}>
                    <CustomCard showLocalLoader={this.props.isClinicStateLoading} type={CustomCardType.MODAL_CARD}>
                        <CustomCard.Body>
                            <div className="modal-header">
                                <Translation text={'axaClinicList.actions.addClinic'}/>
                                <button className="btn-modal-close" onClick={() => this.toggleModal()}>
                                    <span className="feather icon-x"/>
                                </button>
                            </div>
                            <div className="modal-body">
                                <Form config={this.state.formConfig}
                                      onValidationStateChange={this.onFormValidityChange}
                                      submitForm={this.addClinic}
                                      value={this.state.value}
                                      controlName={'addClinicForm'}/>
                            </div>
                        </CustomCard.Body>
                    </CustomCard>
                </BasicModal>
            </React.Fragment>
        );
    }

    private toggleModal = () => {
        this.setState({modalShown: !this.state.modalShown});
    };
    private closeModal = () => {
        return this.setState({modalShown: false});
    };

    private renderTableRows() {
        if (!this.props.retrieved) {
            return;
        }

        const rows: any[] = [];
        this.props.retrieved['hydra:member'].map((item: any) => {
            return rows.push(
                <tr key={item['@id']}>
                    <td>
                        <span className="d-block font-weight-bold">{item.companyName}</span>
                        <span className="d-block">{item.account.firstName} {item.account.lastName}</span>
                    </td>
                    <td>
                        {this.getClinicAddressDetails(item)}
                    </td>
                    <td>{item.id}</td>
                    <td>{item.account.phone}</td>
                    <td className="align-middle text-center">
                        {/*toDo add details about the plan*/}
                        <p>---</p>
                    </td>
                    <td className="align-middle text-right">
                        <Link className={"btn btn-action"}
                              to={{
                            pathname: `/dashboard/clinics/${item['id']}`,
                            state: {
                                profileId: item.profile ? `${item.profile.id}` : null,
                            }
                        }}>
                            <span className="feather icon-file-text"/>
                        </Link>
                    </td>
                </tr>
            )
        });

        return rows;
    }

    onFormValidityChange = (controlName: string, isValid: boolean, errorMessages: ReadonlyArray<string>) => {
        this.setState({isFormValid: isValid});
    };

    private getClinicsList = (searchParams: typeof RestQueryParams) => {
        searchParams = searchParams.add('order[createdAt]', 'DESC');
        // searchParams = searchParams.add('itemsPerPage', '10');
        // searchParams = searchParams.add('page', '1');
        this.props.list(`clinics${searchParams.prepareQuery()}`, this.props.authToken);
    };

    private addClinic = (event: any, value: any, valid: boolean, touched: boolean): void => {
        if (!valid || !touched) {
            return;
        }

    this.setState({isFormValid: true});
    this.props.addClinic(
      {
        firstName: '',
        lastName: '',
        birthdate: '1990-06-26T08:13:23.178Z',
        phone: '',
        address: {
          addressLine1: '',
          addressLine2: '',
          addressLine3: '',
          postcode: '',
          city: '',
          country: '',
        },
        invoiceAddress: {
          taxId: '1',
          addressLine1: '',
          addressLine2: '',
          addressLine3: '',
          postcode: '',
          city: '',
          country: ''
        },
        residency: ''
      },
      value.clinicName,
      'ref',
      true,
      0,
    );
  };

  private getClinicAddressDetails = (clinic: {[key: string]: any}) => {
      const clinicAddress = clinic.account.address,
          street = clinicAddress.addressLine1 ? clinicAddress.addressLine1 : '---',
          postcode = clinicAddress.postcode ? clinicAddress.postcode : '---',
          city = clinicAddress.city ? clinicAddress.city : '---',
          country = clinicAddress.country ? clinicAddress.country : '---';

    return (
        <div>
          <p>{street}</p>
          <p>{postcode} {city}</p>
          <p>{country}</p>
        </div>
    )
  }
}

export default withTranslation()(connect(
    (state: RootState) => ({
        retrieved: retrievedClinicsListSelector(state),
        isClinicListLoading: clinicsListLoadingSelector(state),
        isClinicStateLoading: clinicStateLoadingSelector(state),
        error: clinicsListErrorSelector(state),
        eventSource: clinicsListEventSourceSelector(state),
        authToken: authTokenSelector(state)
    }),
    {
        list,
        reset,
        create,
        addClinic
    }
)(withRouter(Clinics)));
