import { Observable } from "rxjs";
import { RestQueryParams } from "common-web";
import {treatmentTypeAPI} from "./provider/treatmentTypeAPI";
import {ITreatmentCategoryTranslation} from "./addTreatmentCategory";

export function addTreatmentTypeAPI(
  accessToken: string,
  inquiries: string[] = [],
  treatmentCategory: string,
  nameTranslation: ITreatmentCategoryTranslation,
  specialists: string[] = []
): Observable<any> {
  return treatmentTypeAPI.post(
    `treatment-type`,
    {
      inquiries: inquiries,
      treatmentCategory: treatmentCategory,
      nameTranslation: nameTranslation,
      doctors: specialists,
    },
    new RestQueryParams(),
    {
      Authorization: `Bearer ${accessToken}`
    }
  );
}
