import {FormButtonType, FormControlType, IFormConfig, InputType, ValidationRules} from 'common-web';

export const initialClinicFormConfig: typeof IFormConfig = {
    controlType: 'group',
    class: '',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);

        return previousStateSnapshot;
    },
    controls: [
        {
            controlType: 'group',
            key: 'name',
            class: 'row',
            controls: {
                clinicName: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                    ],
                    placeholder: 'Clinic name',
                    label: 'Clinic name',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-12',
                }
            }
        },
        // {
        //   controlType: 'group',
        //   key: 'photo',
        //   class: 'row',
        //   controls: {
        //     photo: {
        //       controlType: 'control',
        //       defaultValue: '',
        //       formControlType: FormControlType.INPUT,
        //       placeholder: 'Photo',
        //       label: 'Photo',
        //       hostClass: 'col-xl-12',
        //       type: InputType.TEXT,
        //     }
        //   }
        // }
        {
            controlType: 'group',
            key: 'clinic_submit',
            class: 'row',
            controls: {
                submitButton: {
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    hostClass: 'col-xl-12 mb-0',
                    defaultContainerStyles: '',
                    containerStyles: 'button-container mt-2',
                    defaultValue: null,
                    btnText: 'Create New Clinic',
                },
            },
        },
    ]
};
