import { createSlice } from "redux-starter-kit";

export interface IMetadataCategory {
  label: string;
  value: string;
}

export interface IMetaDataState {
  treatmentCategories: IMetadataCategory[];
  paymentOptions: IMetadataCategory[];
  extraServices: IMetadataCategory[];
  isLoading: boolean;
  error: string | null;
}

export interface IChangeMetadataCategory {
  readonly payload: {
      categories: IMetadataCategory[];
  };
}

export interface IRetrieveMetadataCategory {
  readonly payload: {
    authToken: string;
  };
}

export interface IChangeMetadataLoading {
  readonly payload: {
    isLoading: boolean;
  };
}

export interface ISetMetadataError {
  readonly payload: {
    error: string | null;
  };
}

const initialState: IMetaDataState = {
  treatmentCategories: [],
  paymentOptions: [],
  extraServices: [],
  isLoading: false,
  error: null
};

const metadataSlice = createSlice({
  slice: "metadata",
  initialState: initialState,
  reducers: {
    changeTreatmentCategories: {
      reducer: (state: IMetaDataState, action: IChangeMetadataCategory) => {
        return {
          treatmentCategories: action.payload.categories,
          paymentOptions: state.paymentOptions,
          extraServices: state.extraServices,
          isLoading: state.isLoading,
          error: state.error
        }
      },
      prepare(categories: IMetadataCategory[]) {
        return {
           payload: { categories: categories }
        };
      }
    },
    changePaymentOptions: {
      reducer: (state: IMetaDataState, action: IChangeMetadataCategory) => {
        return {
          treatmentCategories: state.treatmentCategories,
          paymentOptions: action.payload.categories,
          extraServices: state.extraServices,
          isLoading: state.isLoading,
          error: state.error
        }
      },
      prepare(categories: IMetadataCategory[]) {
        return {
          payload: { categories: categories }
        };
      }
    },
    changeExtraServices: {
      reducer: (state: IMetaDataState, action: IChangeMetadataCategory) => {
        return {
          treatmentCategories: state.treatmentCategories,
          paymentOptions: state.paymentOptions,
          extraServices: action.payload.categories,
          isLoading: state.isLoading,
          error: state.error
        }
      },
      prepare(categories: IMetadataCategory[]) {
        return {
          payload: { categories: categories }
        };
      }
    },
    changeMetadataLoading: {
      reducer: (state: IMetaDataState, action: IChangeMetadataLoading) => {
        return {
          treatmentCategories: state.treatmentCategories,
          paymentOptions: state.paymentOptions,
          extraServices: state.extraServices,
          isLoading: action.payload.isLoading,
          error: state.error
        }
      },
      prepare(isLoading: boolean) {
        return {
          payload: { isLoading: isLoading }
        };
      }
    },
    retrievePaymentOptions: {
      reducer: (state: IMetaDataState, action: IRetrieveMetadataCategory) => {
        return {
          treatmentCategories: state.treatmentCategories,
          paymentOptions: state.paymentOptions,
          extraServices: state.extraServices,
          isLoading: state.isLoading,
          error: state.error
        }
      },
      prepare(authToken: string) {
        return {
          payload: { authToken: authToken }
        };
      }
    },
    retrieveTreatmentCategories: {
      reducer: (state: IMetaDataState, action: IRetrieveMetadataCategory) => {
        return {
          treatmentCategories: state.treatmentCategories,
          paymentOptions: state.paymentOptions,
          extraServices: state.extraServices,
          isLoading: state.isLoading,
          error: state.error
        }
      },
      prepare(authToken: string) {
        return {
          payload: { authToken: authToken }
        };
      }
    },
    retrieveExtraServices: {
      reducer: (state: IMetaDataState, action: IRetrieveMetadataCategory) => {
        return {
          treatmentCategories: state.treatmentCategories,
          paymentOptions: state.paymentOptions,
          extraServices: state.extraServices,
          isLoading: state.isLoading,
          error: state.error
        }
      },
      prepare(authToken: string) {
        return {
          payload: { authToken: authToken }
        };
      }
    },
    setMetadataError: {
      reducer: (state: IMetaDataState, action: ISetMetadataError) => {
        return {
          treatmentCategories: state.treatmentCategories,
          paymentOptions: state.paymentOptions,
          extraServices: state.extraServices,
          isLoading: state.isLoading,
          error: action.payload.error
        }
      },
      prepare(error: string) {
        return {
          payload: { error: error }
        };
      }
    }
  }
});

export const {
  changeTreatmentCategories,
  changePaymentOptions,
  changeExtraServices,
  changeMetadataLoading,
  retrievePaymentOptions,
  retrieveTreatmentCategories,
  retrieveExtraServices,
  setMetadataError
} = metadataSlice.actions;
export default metadataSlice.reducer;
