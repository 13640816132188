import {RootState} from "../reducers";
import {ISpecialistState} from "../reducers/specialistSlice";
import {createSelector} from "redux-starter-kit";
import {ISpecialistListBaseState} from "./specialistListSelectors";

export const selectSpecialist = (state: RootState): ISpecialistState => {
    return (state as any).specialist;
};

export const specialistStateLoading = createSelector(
    [selectSpecialist],
    (state: ISpecialistState) => state.isLoading
);

export interface IRetrievedSpecialistState extends ISpecialistListBaseState {
    retrieved: any; // object
    eventSource: EventSource;
}

export interface IRetrievedSpecialistState extends ISpecialistListBaseState {
    retrieved: any; // object
    eventSource: EventSource;
}

export interface IDeleteSpecialistState extends ISpecialistListBaseState {
    deleted: any; // model for inquiry
}

export interface ICreateSpecialistState extends ISpecialistListBaseState {
    created: any; // model for inquiry
}

export interface IUpdateSpecialistState {
    retrieved: any; // model for inquiry
    retrieveError: string;
    retrieveLoading: boolean;
    updateError: string;
    updateLoading: boolean;
    eventSource: EventSource;
    updated: any; // model for inquiry
}

export const selectRetrievedSpecialist = (state: RootState): IRetrievedSpecialistState => {
    return state.specialistList.show;
};

export const selectDeleteSpecialist = (state: RootState): IDeleteSpecialistState => {
    return state.specialistList.del;
};

export const selectCreateSpecialist = (state: RootState): ICreateSpecialistState => {
    return state.specialistList.create;
};

export const selectUpdateSpecialist = (state: RootState): IUpdateSpecialistState => {
    return state.specialistList.update;
};

export const retrievedSpecialistSelector = createSelector(
    [selectRetrievedSpecialist],
    (state: IRetrievedSpecialistState) => state.retrieved
);

export const retrievedSpecialistLoadingSelector = createSelector(
    [selectRetrievedSpecialist],
    (state: IRetrievedSpecialistState) => state.loading
);

export const retrievedSpecialistErrorSelector = createSelector(
    [selectRetrievedSpecialist],
    (state: IRetrievedSpecialistState) => state.error
);

export const retrievedSpecialistEventSourceSelector = createSelector(
    [selectRetrievedSpecialist],
    (state: IRetrievedSpecialistState) => state.eventSource
);

export const retrievedSpecialistDeleteErrorSelector = createSelector(
    [selectDeleteSpecialist],
    (state: IDeleteSpecialistState) => state.error
);

export const retrievedSpecialistDeleteLoadingSelector = createSelector(
    [selectDeleteSpecialist],
    (state: IDeleteSpecialistState) => state.loading
);

export const retrievedSpecialistDeletedSelector = createSelector(
    [selectDeleteSpecialist],
    (state: IDeleteSpecialistState) => state.deleted
);

export const retrievedSpecialistCreateErrorSelector = createSelector(
    [selectCreateSpecialist],
    (state: ICreateSpecialistState) => state.error
);

export const retrievedSpecialistCreateLoadingSelector = createSelector(
    [selectCreateSpecialist],
    (state: ICreateSpecialistState) => state.loading
);

export const retrievedSpecialistCreatedSelector = createSelector(
    [selectCreateSpecialist],
    (state: ICreateSpecialistState) => state.created
);

export const retrievedSpecialistUpdateRetrievedSelector = createSelector(
    [selectUpdateSpecialist],
    (state: IUpdateSpecialistState) => state.retrieved
);

export const retrievedSpecialistUpdateRetrieveErrorSelector = createSelector(
    [selectUpdateSpecialist],
    (state: IUpdateSpecialistState) => state.retrieveError
);

export const retrievedSpecialistUpdateRetrieveLoadingSelector = createSelector(
    [selectUpdateSpecialist],
    (state: IUpdateSpecialistState) => state.retrieveLoading
);

export const retrievedSpecialistUpdateErrorSelector = createSelector(
    [selectUpdateSpecialist],
    (state: IUpdateSpecialistState) => state.updateError
);

export const retrievedSpecialistUpdateLoadingSelector = createSelector(
    [selectUpdateSpecialist],
    (state: IUpdateSpecialistState) => state.updateLoading
);

export const retrievedSpecialistUpdatedSelector = createSelector(
    [selectUpdateSpecialist],
    (state: IUpdateSpecialistState) => state.updated
);

export const retrievedSpecialistUpdateEventSourceSelector = createSelector(
    [selectUpdateSpecialist],
    (state: IUpdateSpecialistState) => state.eventSource
);
