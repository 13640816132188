import {FormButtonType, FormControlType, IFormConfig, InputType, ValidationRules} from 'common-web';

export const specialistFormConfig: typeof IFormConfig = {
    class: '',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);

        return previousStateSnapshot;
    },
    controls: [
        {
            controlType: 'group',
            key: 'specialist_name',
            class: 'row',
            controls: {
                firstName: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED}
                    ],
                    placeholder: 'First Name',
                    label: 'First Name',
                    type: InputType.TEXT,
                    hostClass: 'col-sm-6',
                },
                lastName: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED}
                    ],
                    placeholder: 'Last Name',
                    label: 'Last Name',
                    type: InputType.TEXT,
                    hostClass: 'col-sm-6',
                }
            }
        },
        {
            controlType: 'group',
            key: 'titles',
            class: 'row',
            controls: {
                jobTitle: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                        {name: ValidationRules.MIN_LENGTH, params: {length: 2}}
                    ],
                    placeholder: 'Job Title',
                    label: 'Job Title',
                    type: InputType.TEXT,
                    hostClass: 'col-sm-6',
                },
                title: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    placeholder: 'Title',
                    label: 'Title',
                    hostClass: 'col-sm-6',
                    type: InputType.TEXT,
                }
            },
        },
        {
            controlType: 'group',
            key: 'description',
            class: 'row',
            controls: {
                description: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    placeholder: 'Information about specialist',
                    label: 'Information about specialist',
                    hostClass: 'col-sm-12',
                    type: InputType.TEXT,
                }
            }
        },
        {
            controlType: 'group',
            key: 'experience',
            class: 'row',
            controls: {
                yearsExperience: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    placeholder: 'Work Experience',
                    label: 'Work Experience',
                    hostClass: 'col-sm-12',
                    type: InputType.TEXT,
                }
            }
        },
        {
            controlType: 'group',
            key: 'languages_specialization',
            class: 'row',
            controls: {
                languages: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    placeholder: 'Languages',
                    label: 'Languages',
                    hostClass: 'col-sm-6',
                    type: InputType.TEXT,
                },
                specializations: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    placeholder: 'Specialization',
                    label: 'Specialization',
                    hostClass: 'col-sm-6',
                    type: InputType.TEXT,
                }
            }
        },
        {
            controlType: 'group',
            key: 'education_clinic',
            class: 'row',
            controls: {
                clinics: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    placeholder: 'Clinics',
                    label: 'Clinics',
                    hostClass: 'col-sm-6',
                    type: InputType.TEXT,
                },
                education: {
                    controlType: 'control',
                    defaultValue: '',
                    valid: false,
                    formControlType: FormControlType.INPUT,
                    placeholder: 'Education',
                    label: 'Education',
                    touched: false,
                    hostClass: 'col-sm-6',
                    type: InputType.TEXT,
                }
            }
        },
        {
            controlType: 'group',
            key: 'certificates',
            class: 'row',
            controls: {
                membershipAndAwards: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    placeholder: 'Membership and awards',
                    label: 'Membership and awards',
                    hostClass: 'col-sm-6',
                    type: InputType.TEXT,
                },
                certificates: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    placeholder: 'Certificates',
                    label: 'Certificates',
                    hostClass: 'col-sm-6',
                    type: InputType.TEXT,
                }
            }
        },
        {
            controlType: 'group',
            key: 'languages',
            class: 'row',
            controls: {
                concerns: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    placeholder: 'Concerns',
                    label: 'Concerns',
                    hostClass: 'col-sm-6',
                    type: InputType.TEXT,
                },
                treatments: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    placeholder: 'Treatments',
                    label: 'Treatments',
                    hostClass: 'col-sm-6',
                    type: InputType.TEXT,
                }
            }
        },
        // {
        //   controlType: 'group',
        //   key: 'photo',
        //   class: 'row',
        //   controls: {
        //     photo: {
        //       controlType: 'control',
        //       defaultValue: '',
        //       formControlType: FormControlType.INPUT,
        //       placeholder: 'Photo',
        //       label: 'Photo',
        //       hostClass: 'col-sm-12',
        //       type: InputType.TEXT,
        //     }
        //   }
        // }
        {
            controlType: 'group',
            key: 'add_specialist_submit',
            class: 'row',
            controls: {
                submitButton: {
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    hostClass: 'col-xl-12 mb-0',
                    defaultContainerStyles: '',
                    containerStyles: 'button-container mt-2 pr-0',
                    defaultValue: null,
                    btnText: 'Add specialist',
                },
            },
        },
    ]
};
