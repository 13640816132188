import { Observable } from "rxjs";
import { RestQueryParams } from "common-web";
import {treatmentTypeAPI} from "./provider/treatmentTypeAPI";


export function proposeSubscriptionPlanAPI(
    authToken: string,
    subscriptionPlanId: string,
    clinicId: string,
): Observable<any> {
    return treatmentTypeAPI.put(
        `subscription_definitions/${subscriptionPlanId}/propose`,
        {
            clinicId: clinicId
        },
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`
        }
    );
}
