import { RootState } from "../reducers";
import { createSelector } from "redux-starter-kit";

export interface ITreatmentTypeBaseState {
    loading: boolean;
    error: string;
}

export interface ITreatmentTypeListState extends ITreatmentTypeBaseState {
    retrieved: any;
    eventSource: EventSource;
}

export const selectTreatmentTypeList = (state: RootState): ITreatmentTypeListState => {
    return state.treatmentTypes.list;
};

export const retrievedTreatmentTypeListSelector = createSelector(
    [selectTreatmentTypeList],
    (state: ITreatmentTypeListState) => state.retrieved
);

export const treatmentTypeListLoadingSelector = createSelector(
    [selectTreatmentTypeList],
    (state: ITreatmentTypeListState) => state.loading
);

export const treatmentTypeListErrorSelector = createSelector(
    [selectTreatmentTypeList],
    (state: ITreatmentTypeListState) => state.error
);

export const treatmentTypeListEventSourceSelector = createSelector(
    [selectTreatmentTypeList],
    (state: ITreatmentTypeListState) => state.eventSource
);
