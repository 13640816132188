import {injectable} from 'inversify';
import {fixInjectedProperties} from '../ioc';
import {isNullOrUndefined} from "../utils/runtimeUtils";

export interface IAftercareMapperService {
    convertValueToDateInterval(value: number, unit: string): string;
    calculateRecommendationEndDate(
        startDate: string,
        intervalValue: number,
        intervalUnit: string,
        consecutiveUnit: string,
        consecutiveValue: number | string
    ): string;
    treatmentPointStartDate(date: string, hour: number, dayPeriod: string): string;
    convertToRecommendationEndsAtInterval(
        consecutiveType: string,
        consecutiveValue: number,
        intervalUnit: string,
        intervalValue: number
    ): string | null;
    convertDateIntervalToValue(dateInterval: string | null): {[key: string]: any} | null;
    convertDateIntervalToDaysAmount(dateInterval: string | null): number | null;
}


@injectable()
class AftercareMapperService implements IAftercareMapperService {
    constructor() {
        fixInjectedProperties(this);
    }

    public convertValueToDateInterval(value: number, unit: string): string {
        // if (isNullOrUndefined(value) || isNullOrUndefined(unit)) {
        //     return null;
        // }

        let hourInterval = '0',
            dayInterval = '0',
            monthInterval = '0',
            yearInterval = '0';

        if (unit === 'hour') {
            hourInterval = value.toString();
        }

        if (unit === 'day') {
            if(value < 28) {
                dayInterval = value.toString();
            }

            dayInterval = (value % 28).toString();
            monthInterval = Math.floor(value / 28).toString();
        }

        if (unit === 'month') {
            if (value < 12) {
                monthInterval = value.toString();
            }

            monthInterval = (value % 12).toString();
            yearInterval = Math.floor(value / 12).toString();
        }

        if (unit === 'week') {
            if (value < 4) {
                dayInterval = (value * 7).toString();
            }

            monthInterval = (Math.floor(value / 4)).toString();
            dayInterval = ((value % 4) * 7).toString();
        }

        return `P${yearInterval}Y${monthInterval}M${dayInterval}DT${hourInterval}H0M0S`;
    }

    public calculateRecommendationEndDate(
        startDate: string,
        intervalValue: number,
        intervalUnit: string,
        consecutiveUnit: string,
        consecutiveValue: number | string
    ): string {
        if (consecutiveUnit === 'until') {
            return new Date(consecutiveValue).toISOString();
        }

        let date = new Date(startDate);
        let days;
        if (intervalUnit === 'week') {
            days = intervalValue * 7;
        } else if (intervalUnit === 'month') {
            days = intervalValue * 28;
        } else {
            days = intervalValue;
        }

        let frequency = days * Number(consecutiveValue);
        let treatmentPointEndDate = date.setDate(date.getDate() + frequency);
        return new Date(treatmentPointEndDate).toISOString();
    }

    public treatmentPointStartDate(date: string, hour: number, dayPeriod: string): string {
        let recommendationDate = new Date(date);
        if (dayPeriod === 'pm') {
            recommendationDate.setHours(hour);
        }

        recommendationDate.setHours(hour + 12);

        // let timezoneOffset = (new Date(recommendationDate)).getTimezoneOffset() * 60000;
        // let localISOTime = (new Date(new Date(recommendationDate).getTime() - timezoneOffset)).toISOString().slice(0,-1);
        // console.log('localISOTime:', localISOTime);

        return new Date(recommendationDate).toISOString();
    }

    public convertToRecommendationEndsAtInterval(
        consecutiveType: string,
        consecutiveValue: number,
        intervalUnit: string,
        intervalValue: number
    ): string | null {
        if (isNullOrUndefined(consecutiveType) ||
            isNullOrUndefined(consecutiveValue) ||
            isNullOrUndefined(intervalUnit) ||
            isNullOrUndefined(intervalValue)) {
            return null;
        }

        if (consecutiveType === 'period') {
            return `P0Y0M${consecutiveValue}DT0H0M0S`;
        }

        let days;
        if (intervalUnit === 'week') {
            days = intervalValue * 7;
        } else if (intervalUnit === 'month') {
            days = intervalValue * 28;
        } else {
            days = intervalValue;
        }

        let frequency = days * consecutiveValue;
        let dayInterval = (frequency % 28).toString();
        let monthInterval = Math.floor(frequency / 28).toString();

        return `P0Y${monthInterval}M${dayInterval}DT0H0M0S`;
    }

    public convertDateIntervalToValue(dateInterval: string | null): {[key: string]: any} | null {
        if (!dateInterval) {
            return null;
        }

        let year = dateInterval.substring(
            dateInterval.lastIndexOf("P") + 1,
            dateInterval.lastIndexOf("Y")
        );

        let month = dateInterval.substring(
            dateInterval.lastIndexOf("Y") + 1,
            dateInterval.indexOf("M")
        );

        let day = dateInterval.substring(
            dateInterval.indexOf("M") + 1,
            dateInterval.lastIndexOf("D")
        );

        let hour = dateInterval.substring(
            dateInterval.indexOf("T") + 1,
            dateInterval.indexOf("H")
        );

        let unit = 'day';
        let amount = Number(day);

        if (Number(month) > 0) {
            unit = 'month';
            amount = Number(month);
        }

        if (amount >= 7 && amount % 7 === 0) {
            unit = 'week';
            amount = Number(day) / 7;
        }

        if (Number(year) === 0 &&
            Number(month) === 0 &&
            Number(day) === 0) {
            unit = 'hour';
            amount = Number(hour);
        }

        let reminderDetails = {
            amount: amount,
            unit: unit
        };

        return reminderDetails;
    }

    public convertDateIntervalToDaysAmount(dateInterval: string | null): number | null {
        if (!dateInterval) {
            return null;
        }

        let year = dateInterval.substring(
            dateInterval.lastIndexOf("P") + 1,
            dateInterval.lastIndexOf("Y")
        );

        let month = dateInterval.substring(
            dateInterval.lastIndexOf("Y") + 1,
            dateInterval.indexOf("M")
        );

        let day = dateInterval.substring(
            dateInterval.indexOf("M") + 1,
            dateInterval.lastIndexOf("D")
        );

        let amount = Number(day);

        if (Number(month) > 0) {
            amount = Number(month) * amount;
        }

        return amount;
    }
}

export default AftercareMapperService;
