import { RootState } from "../reducers";
import { createSelector } from "redux-starter-kit";

export interface IInvoiceBaseState {
    loading: boolean;
    error: string;
}

export interface IInvoiceListState extends IInvoiceBaseState {
    retrieved: any; // model for subscription
    eventSource: EventSource;
}

export const selectInvoiceList = (state: RootState): IInvoiceListState => {
    return state.invoice.list;
};

export const retrievedInvoiceListSelector = createSelector(
    [selectInvoiceList],
    (state: IInvoiceListState) => state.retrieved
);

export const invoiceListLoadingSelector = createSelector(
    [selectInvoiceList],
    (state: IInvoiceListState) => state.loading
);

export const invoiceListErrorSelector = createSelector(
    [selectInvoiceList],
    (state: IInvoiceListState) => state.error
);

export const invoiceListEventSourceSelector = createSelector(
    [selectInvoiceList],
    (state: IInvoiceListState) => state.eventSource
);
