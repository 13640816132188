import React from 'react';
import {settleWithClinicAPI} from "../../../api/settleWithClinic";
import {catchError, tap} from "rxjs/operators";
import {of, Subscription} from "rxjs";
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {IAlertManagerService} from "../../../service/alertManagerService";
import {Switch} from "common-web";

interface IConnectedSettleClinicCommissionProps {}

interface IExternalSettleClinicCommissionProps {
  name: string;
  checked: boolean;
  authToken: string;
}

interface ISettleClinicCommissionProps extends
  IConnectedSettleClinicCommissionProps,
  IExternalSettleClinicCommissionProps {}

class SettleClinicCommission extends React.Component<ISettleClinicCommissionProps> {
  @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

  private subscription: Subscription | null = null;

  constructor(props: any) {
    super(props);

    fixInjectedProperties(this);
  }

  componentWillUnmount() {
    if (null !== this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  render() {
    return (
        <Switch checked={this.props.checked}
                name={this.props.name}
                handleChange={this.handleChange}/>
    );
  }

  private handleChange = (e: any) => {
    const isChecked = e.target.checked,
      inquiryId = e.target.name;
    this.setState({isChecked: isChecked});

    this.subscription = this.settleInquiry(this.props.authToken, inquiryId, isChecked).subscribe();
  };

  private settleInquiry(authToken: string, inquiryId: string, isChecked: boolean) {
    return settleWithClinicAPI(this.props.authToken, inquiryId, isChecked)
      .pipe(
        tap(() => {
          let alertMessage;
          isChecked ? alertMessage = `Insurance #${inquiryId} has been settled with clinic` :
            alertMessage = `Insurance #${inquiryId} has not been settled with clinic`;

          this.alertManager.addAlert(alertMessage);
        }),
        catchError((error: any) => {
          return of(this.alertManager.handleApiError(error.response));
        }),
      );
  }
}

export default SettleClinicCommission;
