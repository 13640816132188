import { Observable } from "rxjs";
import { RestQueryParams } from "common-web";
import {treatmentTypeAPI} from "./provider/treatmentTypeAPI";
import {ITreatmentCategoryTranslation} from "./addTreatmentCategory";

export function editTreatmentCategoryAPI(
    accessToken: string,
    categoryId: string,
    name: string,
    nameTranslation: ITreatmentCategoryTranslation,
): Observable<any> {
    return treatmentTypeAPI.put(
        `treatment_categories/${categoryId}`,
        {
            name: name,
            nameTranslation: nameTranslation
        },
        new RestQueryParams(),
        {
            Authorization: `Bearer ${accessToken}`
        }
    );
}
