import { createSlice } from "redux-starter-kit";

export interface IInsuranceAxaReportState {
  reports: {[key: string]: any} | null;
  isLoading: boolean;
  error: string | null;
}

export interface ISetReportsState {
  readonly payload: {
    reports: {[key: string]: any}
  }
}

export interface IRetrieveInsuranceAxaReport {
  readonly payload: {
    params: string | null;
  }
}

export interface ISetInsuranceAxaReportStateFailure {
  readonly payload: {
    error: string;
  }
}

export interface IChangeInsuranceAxaReportStateLoading {
  readonly payload: {
    isLoading: boolean;
  }
}

const initialState: IInsuranceAxaReportState = {
  reports: null,
  isLoading: true,
  error: null
};

const insuranceAxaReportSlice = createSlice({
  slice: "insuranceAxaReport",
  initialState: initialState,
  reducers: {
    retrieveInsuranceAxaReport: {
      reducer: (state: IInsuranceAxaReportState, action: IRetrieveInsuranceAxaReport) => {
        return {
          reports: state.reports,
          isLoading: true,
          error: state.error
        };
      },
      prepare(params: string | null) {
        return {
          payload: {
            params: params
          }
        };
      }
    },
    setInsuranceAxaReportState: {
      reducer: (state: IInsuranceAxaReportState, action: ISetReportsState) => {
        return {
          reports: action.payload.reports,
          isLoading: state.isLoading,
          error: state.error
        };
      },
      prepare(reports: {[key: string]: any}) {
        return {
          payload: {reports: reports}
        };
      }
    },
    setInsuranceAxaReportStateFailure: {
      reducer: (state: IInsuranceAxaReportState, action: ISetInsuranceAxaReportStateFailure) => {
        return {
          reports: state.reports,
          isLoading: state.isLoading,
          error: action.payload.error
        };
      },
      prepare(error: string) {
        return {
          payload: {error: error}
        };
      }
    },
    changeInsuranceAxaReportStateLoading: {
      reducer: (state: IInsuranceAxaReportState, action: IChangeInsuranceAxaReportStateLoading) => {
        return {
          reports: state.reports,
          isLoading: action.payload.isLoading,
          error: state.error
        };
      },
      prepare(isLoading: boolean) {
        return {
          payload: {isLoading: isLoading}
        };
      }
    },
  }
});

export const {
  retrieveInsuranceAxaReport,
  setInsuranceAxaReportState,
  setInsuranceAxaReportStateFailure,
  changeInsuranceAxaReportStateLoading
} = insuranceAxaReportSlice.actions;

export default insuranceAxaReportSlice.reducer;
