import { Observable } from "rxjs";
import { RestQueryParams } from "common-web";
import {clinicAPI} from "./provider/clinicAPI";

export function getClinicExtraServicesAPI(accessToken: string): Observable<any> {
    return clinicAPI.get(
        `extra_services`,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${accessToken}`
        }
    );
}
