import { RootState } from "../reducers";
import { createSelector } from "redux-starter-kit";

export interface ITreatmentCategoryBaseState {
    loading: boolean;
    error: string;
}
export interface ITreatmentCategoryItemState extends ITreatmentCategoryBaseState {
    retrieved: any;
    eventSource: EventSource;
}

export interface IDeleteTreatmentCategoryItemState extends ITreatmentCategoryBaseState {
    deleted: any;
}

export interface ICreateTreatmentCategoryItemState extends ITreatmentCategoryBaseState {
    created: any;
}

export interface IUpdateTreatmentCategoryItemState {
    retrieved: any;
    retrieveError: string;
    retrieveLoading: boolean;
    updateError: string;
    updateLoading: boolean;
    eventSource: EventSource;
    updated: any;
}

export const selectTreatmentCategoryItem = (state: RootState): ITreatmentCategoryItemState => {
    return state.treatmentCategory.show;
};

export const selectDeleteTreatmentCategoryItem = (state: RootState): IDeleteTreatmentCategoryItemState => {
    return state.treatmentCategory.del;
};

export const selectCreateTreatmentCategoryItem = (state: RootState): ICreateTreatmentCategoryItemState => {
    return state.treatmentCategory.create;
};

export const selectUpdateTreatmentCategoryItem = (state: RootState): IUpdateTreatmentCategoryItemState => {
    return state.treatmentCategory.update;
};

export const retrievedTreatmentCategoryItemSelector = createSelector(
    [selectTreatmentCategoryItem],
    (state: ITreatmentCategoryItemState) => state.retrieved
);

export const treatmentCategoryItemLoadingSelector = createSelector(
    [selectTreatmentCategoryItem],
    (state: ITreatmentCategoryItemState) => state.loading
);

export const treatmentCategoryItemErrorSelector = createSelector(
    [selectTreatmentCategoryItem],
    (state: ITreatmentCategoryItemState) => state.error
);

export const treatmentCategoryItemEventSourceSelector = createSelector(
    [selectTreatmentCategoryItem],
    (state: ITreatmentCategoryItemState) => state.eventSource
);

export const treatmentCategoryItemDeleteErrorSelector = createSelector(
    [selectDeleteTreatmentCategoryItem],
    (state: IDeleteTreatmentCategoryItemState) => state.error
);

export const treatmentCategoryItemDeleteLoadingSelector = createSelector(
    [selectDeleteTreatmentCategoryItem],
    (state: IDeleteTreatmentCategoryItemState) => state.loading
);

export const treatmentCategoryItemDeletedSelector = createSelector(
    [selectDeleteTreatmentCategoryItem],
    (state: IDeleteTreatmentCategoryItemState) => state.deleted
);

export const treatmentCategoryItemCreateErrorSelector = createSelector(
    [selectCreateTreatmentCategoryItem],
    (state: ICreateTreatmentCategoryItemState) => state.error
);

export const treatmentCategoryItemCreateLoadingSelector = createSelector(
    [selectCreateTreatmentCategoryItem],
    (state: ICreateTreatmentCategoryItemState) => state.loading
);

export const treatmentCategoryItemCreatedSelector = createSelector(
    [selectCreateTreatmentCategoryItem],
    (state: ICreateTreatmentCategoryItemState) => state.created
);

export const treatmentCategoryItemUpdateRetrievedSelector = createSelector(
    [selectUpdateTreatmentCategoryItem],
    (state: IUpdateTreatmentCategoryItemState) => state.retrieved
);

export const treatmentCategoryItemUpdateRetrieveErrorSelector = createSelector(
    [selectUpdateTreatmentCategoryItem],
    (state: IUpdateTreatmentCategoryItemState) => state.retrieveError
);

export const treatmentCategoryItemUpdateRetrieveLoadingSelector = createSelector(
    [selectUpdateTreatmentCategoryItem],
    (state: IUpdateTreatmentCategoryItemState) => state.retrieveLoading
);

export const treatmentCategoryItemUpdateErrorSelector = createSelector(
    [selectUpdateTreatmentCategoryItem],
    (state: IUpdateTreatmentCategoryItemState) => state.updateError
);

export const treatmentCategoryItemUpdateLoadingSelector = createSelector(
    [selectUpdateTreatmentCategoryItem],
    (state: IUpdateTreatmentCategoryItemState) => state.updateLoading
);

export const treatmentCategoryItemUpdatedSelector = createSelector(
    [selectUpdateTreatmentCategoryItem],
    (state: IUpdateTreatmentCategoryItemState) => state.updated
);

export const treatmentCategoryItemUpdateEventSourceSelector = createSelector(
    [selectUpdateTreatmentCategoryItem],
    (state: IUpdateTreatmentCategoryItemState) => state.eventSource
);
