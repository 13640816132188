import {FormButtonType, FormControlType, IFormConfig, InputType, ValidationRules} from 'common-web';
import moment from "moment";
import { MultiSelectType } from 'common-web';

export enum SourceValues {
    CLINIC = 'clinic',
    FRIEND = 'friend',
    FACEBOOK = 'facebook',
    GOOGLE = 'google',
    OTHER = 'other'
}

export const originSelectOptions = [
    {value: SourceValues.CLINIC, label: 'My Clinic'},
    {value: SourceValues.FRIEND, label: 'A Friend'},
    {value: SourceValues.FACEBOOK, label: 'Facebook Group'},
    {value: SourceValues.GOOGLE, label: 'Google Search'},
    {value: SourceValues.OTHER, label: 'Other'},
]

export const editInsuranceFormConfig = (instancesData: {[key: string]: any}[], value: any): typeof IFormConfig => {
    let customSourceControls: any[] = [];
    if (value && value.source === SourceValues.OTHER) {
        customSourceControls.push(customSourceControl());
    }

    if ((value && value.source !== SourceValues.OTHER && customSourceControls.length)) {
        customSourceControls.slice(0, 1);
    }

    return {
        controlType: 'group',
        class: '',
        dataAccessor: (data: any) => data,
        outputDataMapper: (data: any, previousStateSnapshot: any) => {
            Object.assign(previousStateSnapshot, data);

            return previousStateSnapshot;
        },
        controls: [
            {
                controlType: 'group',
                key: 'name',
                class: 'form-row',
                controls: {
                    name: {
                        controlType: 'control',
                        defaultValue: '',
                        hostClass: 'col-xl-12',
                        formControlType: FormControlType.INPUT,
                        validationRules: [],
                        placeholder: 'insuranceClinicReport.editReport.form.placeholders.firstName',
                        label: "insuranceClinicReport.editReport.form.labels.firstName",
                        type: InputType.TEXT,
                    },
                    lastName: {
                        controlType: 'control',
                        defaultValue: '',
                        hostClass: 'col-xl-12',
                        formControlType: FormControlType.INPUT,
                        validationRules: [],
                        placeholder: 'insuranceClinicReport.editReport.form.placeholders.lastName',
                        label: "insuranceClinicReport.editReport.form.labels.lastName",
                        type: InputType.TEXT,
                    }
                }
            },
            {
                controlType: 'group',
                key: 'birthdate',
                class: 'form-row',
                controls: {
                    birthDate: {
                        controlType: 'control',
                        defaultValue: null,
                        hostClass: 'col-xl-12',
                        formControlType: FormControlType.DATE,
                        validationRules: [],
                        placeholder: 'insuranceClinicReport.editReport.form.placeholders.birthdate',
                        label: "insuranceClinicReport.editReport.form.labels.birthdate",
                        maxDate: moment().format('YYYY-MM-DD'),
                        openToDate: moment('01/01/2000').format('YYYY-MM-DD'),
                        type: InputType.DATE,
                        showMonthDropdown: true,
                        showYearDropdown: true,
                    }
                }
            },
            {
                controlType: 'group',
                key: 'email',
                class: 'form-row mb-0',
                controls: {
                    email: {
                        controlType: 'control',
                        defaultValue: '',
                        formControlType: FormControlType.INPUT,
                        validationRules: [{name: ValidationRules.IS_EMAIL, params: {}}],
                        placeholder: 'insuranceClinicReport.editReport.form.placeholders.email',
                        isLabelHidden: false,
                        label: 'insuranceClinicReport.editReport.form.labels.email',
                        type: InputType.EMAIL,
                        hostClass: 'col-xl-12'
                    },
                },
            },
            ...instancesData.map((instanceData, i) => {
                return companionsFormConfig(instanceData, i);
            }),
            {
                controlType: 'group',
                key: 'visit_date',
                class: 'form-row',
                controls: {
                    visitDate: {
                        hostClass: 'col-xl-12',
                        controlType: 'control',
                        defaultValue: '',
                        additionalStyles: '',
                        formControlType: FormControlType.DATE,
                        validationRules: [
                            { name: ValidationRules.IS_REQUIRED },
                        ],
                        placeholder: 'insuranceClinicReport.editReport.form.placeholders.visitDate',
                        label: "insuranceClinicReport.editReport.form.labels.visitDate",
                        maxDate: '2100-01-01',
                        minDate: moment().format('YYYY-MM-DD'),
                        openToDate: moment().format('YYYY-MM-DD'),
                        type: InputType.DATE,
                    },
                }
            },
            {
                controlType: 'group',
                key: 'source',
                class: 'form-row',
                controls: {
                    source: {
                        controlType: 'control',
                        defaultValue: '',
                        hostClass: 'col-xl-12',
                        placeholder: 'insuranceClinicReport.editReport.form.placeholders.source',
                        formControlType: FormControlType.AUTOCOMPLETE,
                        multiselectType: MultiSelectType.SINGLE,
                        label: "insuranceClinicReport.editReport.form.labels.source",
                        validationRules: [],
                        multiselectOptions: originSelectOptions,
                        inputDataMapper: (data: any) => {
                            if (!data) {
                                return [];
                            }

                            return originSelectOptions
                                .filter((option: any) => option.value === data)
                                .map((option: any) => (
                                    {
                                        value: option.value,
                                        label: option.label,
                                    }
                                ));
                        },
                        outputDataMapper: (data: any) => {
                            if (Array.isArray(data)) {
                                return (data.length > 0) ? data[0].value : null;
                            }
                            if (data && typeof data === 'object') {
                                return data.value;
                            }

                            return data;
                        },
                    }
                }
            },
            ...customSourceControls,
            {
                controlType: 'group',
                key: 'treatment_type_submit',
                class: 'form-row',
                controls: {
                    submitButton: {
                        controlType: 'control',
                        formControlType: FormControlType.BUTTON,
                        buttonType: FormButtonType.SUBMIT,
                        hostClass: 'col-xl-12 mb-0',
                        defaultContainerStyles: '',
                        containerStyles: 'button-container mt-2',
                        defaultValue: null,
                        btnText: 'insuranceClinicReport.editReport.edit',
                    },
                },
            },
        ]
    }
};


export const companionsFormConfig = (instance: {[key: string]: any}, index: number): typeof IFormConfig => {
   return {
        controlType: 'group',
        key: `companion_details_${index}`,
        class: 'form-row',
        controls: {
            companionName: {
                controlType: 'control',
                defaultValue: '',
                hostClass: 'col-xl-12',
                formControlType: FormControlType.INPUT,
                validationRules: [],
                placeholder: 'insuranceClinicReport.editReport.form.placeholders.companionFirstName',
                label: "insuranceClinicReport.editReport.form.labels.companionFirstName",
                type: InputType.TEXT,
            },
            companionLastName: {
                controlType: 'control',
                defaultValue: '',
                hostClass: 'col-xl-12',
                formControlType: FormControlType.INPUT,
                validationRules: [],
                placeholder: 'insuranceClinicReport.editReport.form.placeholders.companionLastName',
                label: "insuranceClinicReport.editReport.form.labels.companionLastName",
                type: InputType.TEXT,
            },
            companionBirthDate: {
                controlType: 'control',
                defaultValue: '',
                hostClass: 'col-xl-12',
                formControlType: FormControlType.DATE,
                validationRules: [],
                placeholder: 'insuranceClinicReport.editReport.form.placeholders.companionBirthdate',
                label: "insuranceClinicReport.editReport.form.labels.companionBirthdate",
                maxDate: moment().format('YYYY-MM-DD'),
                openToDate: moment('01/01/2000').format('YYYY-MM-DD'),
                type: InputType.DATE,
                showMonthDropdown: true,
                showYearDropdown: true,
            }
        }
    };
};

export const customSourceControl = () => (
    {
        controlType: 'group',
        key: 'custom_source',
        class: 'form-row',
        controls: {
            customSource: {
                controlType: 'control',
                defaultValue: null,
                hostClass: 'col-xl-12',
                formControlType: FormControlType.INPUT,
                validationRules: [
                    {name: ValidationRules.IS_REQUIRED},
                ],
                placeholder: 'insuranceClinicReport.editReport.form.placeholders.customSource',
                label: "insuranceClinicReport.editReport.form.labels.customSource",
                type: InputType.TEXT,
            },
        }
    }
);
