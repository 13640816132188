import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {Form, FormControlChangeType, IFormConfig, IMultiselectOption} from "common-web";
import {WithTranslation, withTranslation} from "react-i18next";
import {IAlertManagerService} from '../../../../../service/alertManagerService';
import {fixInjectedProperties, lazyInject} from "../../../../../ioc";
import {BehaviorSubject, Subscription} from "rxjs";
import {filter, tap} from "rxjs/operators";
import {addTravelPlannerFormConfig} from "./formConfig";
import {IAftercareMapperService} from "../../../../../service/aftercareMapperService";
import {RecommendationDefinitionType} from "../../index";

interface IConnectedAddTravelPlanProps {}

interface IAddTravelPlanProps extends IConnectedAddTravelPlanProps,
    RouteComponentProps,
    WithTranslation {
    closeRecommendationModal: any;
    selectedRecommendation: {[key: string]: any} | null;
    addRecommendation: (value: any) => void;
}

interface IAddTravelPlanState {
    formConfig: typeof IFormConfig;
    isFormValid: boolean;
    isLoading: boolean;
    value: any;
}

class AddTravelPlan extends React.Component<IAddTravelPlanProps, IAddTravelPlanState> {
    readonly subscriptions: Subscription[] = [];
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;
    @lazyInject('AftercareMapperService') private aftercareMapper: IAftercareMapperService;

    constructor(props: IAddTravelPlanProps) {
        super(props);

        this.state = {
            formConfig: addTravelPlannerFormConfig,
            isFormValid: true,
            isLoading: false,
            value: null
        };

        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.subscriptions.push(
            this.onValueStateChange$.pipe(
                filter((data: any) => data && data.changeType === FormControlChangeType.User),
                tap((data: any) => this.onFormValueChange(data.value, data.changeType)),
            ).subscribe()
        );

        if (this.props.selectedRecommendation) {
            this.changeFormControlValues(this.props.selectedRecommendation);
        } else {
            this.updateButtonText('aftercare.predefinedEvents.addEventButton');
        }

        this.setHourReminderIntervalRate(0, 23);
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <React.Fragment>
                <Form config={this.state.formConfig}
                      onValueStateChange={this.onValueStateChange}
                      onValidationStateChange={this.onValidationStateChange}
                      value={this.state.value}
                      controlName={'addRecommendationForm'}
                      submitForm={() => this.props.addRecommendation(this.state.value)}/>
            </React.Fragment>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private onValidationStateChange = (controlName: string, isValid: boolean) => {
        this.setState({isFormValid: isValid});
    };

    private onFormValueChange = (value: any, changeType: typeof FormControlChangeType) => {
        Object.keys(value).map((key: string) => {
            if (key === 'reminderUnit' && value[key] !== null) {
                return value[key] === 'hour' ? this.setHourReminderIntervalRate(0, 23) :
                    this.setHourReminderIntervalRate(1, 30);
            }
        });

        this.setState({value: value});
    };

    private closeRecommendationModal = () => {
        this.setState({value: null});
        this.props.closeRecommendationModal();
    };

    private setHourReminderIntervalRate(startValue: number, repeatValue: number) {
        let options: typeof IMultiselectOption[] = [];
        for (let step = startValue; step <= repeatValue; step++) {
            options.push({
                value: step,
                label: step
            })
        }

        addTravelPlannerFormConfig.controls.map((control: any) => {
            if (control.hasOwnProperty("controls")) {
                Object.keys(control.controls).map((key: string) => {
                    if (key === 'reminderAmount') {
                        control.controls[key].multiselectOptions = options;
                    }
                });
            }

            return control;
        });

        this.setState({formConfig: addTravelPlannerFormConfig});
    }

    private changeFormControlValues(recommendation: {[key: string]: any}) {
        let recommendationProperties = recommendation?.structure?.properties,
            reminderDetails = this.aftercareMapper.convertDateIntervalToValue(
                recommendationProperties?.notificationPropagationStart
            );


        this.setState({
            value: {
                recommendationName: recommendation.name,
                recommendationDescription: recommendation.description,
                repeatEvent: RecommendationDefinitionType.SINGLE,
                reminderAmount: reminderDetails?.amount,
                reminderUnit: reminderDetails?.unit,
            }
        });

        this.updateButtonText('button.update');
    }

    private updateButtonText = (btnText: string) => {
        const updatedFormConfig = Object.assign({}, addTravelPlannerFormConfig);
        updatedFormConfig.controls.map((control: any) => {
            if (control.hasOwnProperty("controls")) {
                Object.keys(control.controls).map((key: string) => {
                    if (key === 'submitButton') {
                        control.controls[key].btnText = btnText;
                    }
                });
            }

            return control;
        });

        return this.setState({formConfig: updatedFormConfig});
    }
}

export default withTranslation()(withRouter(AddTravelPlan));
