import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {RootState} from "../../../../../store/reducers";
import {
    authTokenSelector,
    BasicModal,
    CustomCard,
    CustomCardType,
    Form,
    FormControlChangeType,
    getUTCMomentFromString,
    IFormConfig,
    Translation,
    RestQueryParams
} from "common-web";
import {connect} from "react-redux";
import {WithTranslation, withTranslation} from "react-i18next";
import {IAlertManagerService} from '../../../../../service/alertManagerService';
import {fixInjectedProperties, lazyInject} from "../../../../../ioc";
import {BehaviorSubject, of, Subscription} from "rxjs";
import {catchError, filter, tap} from "rxjs/operators";
import {addInvoiceFormConfig} from "./formConfig";
import {Uploader} from "rsuite";
import moment from "moment";
import {
    IUpdateInvoicePayload,
    updateInvoiceAPI,
    IAddInvoicePayload
} from "../../../../../api/updateInvoice";


interface IConnectedAddInvoicesProps {
    readonly authToken: string;
}

interface IAddInvoicesProps extends IConnectedAddInvoicesProps,
    RouteComponentProps,
    WithTranslation {
    isInvoicesModalShown: boolean;
    toggleInvoicesModal: any;
    selectedFile: {[key: string]: any};
    clinicId: string;
    retrieveInvoices: any;
}

interface IAddInvoicesState {
    isLoading: boolean;
    value: {[key: string]: any};
    formConfig: typeof IFormConfig;
    isFormValid: boolean;
    invoiceMedia: any;
}

class AddInvoices extends React.Component<IAddInvoicesProps, IAddInvoicesState> {
    readonly subscriptions: Subscription[] = [];
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

    constructor(props: IAddInvoicesProps) {
        super(props);

        this.state = {
            isLoading: false,
            value: {},
            formConfig: addInvoiceFormConfig,
            isFormValid: true,
            invoiceMedia: []
        };

        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.subscriptions.push(
            this.onValueStateChange$.pipe(
                filter((data: any) => data && data.changeType === FormControlChangeType.User),
                tap((data: any) => this.onFormValueChange(data.value, data.changeType)),
            ).subscribe()
        );

        if (this.props.selectedFile) {
            this.changeFormControlValues(this.props.selectedFile);
            this.updateButtonText('subscriptionPlans.clinicInvoices.updateInvoice');
        } else {
            this.updateButtonText('subscriptionPlans.clinicInvoices.uploadInvoice');
        }
    }

    componentDidUpdate(
        prevProps: Readonly<IAddInvoicesProps>,
        prevState: Readonly<IAddInvoicesState>,
        snapshot?: any
    ): void {}

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <React.Fragment>
                <BasicModal isModalShown={this.props.isInvoicesModalShown} closeModal={this.props.toggleInvoicesModal}>
                    <CustomCard showLocalLoader={this.state.isLoading} type={CustomCardType.MODAL_CARD}>
                        <CustomCard.Body>
                            <div className="modal-header">
                                {this.props.selectedFile ? (<Translation text={'subscriptionPlans.clinicInvoices.updateInvoice'} />) :
                                    (<Translation text={'subscriptionPlans.clinicInvoices.uploadInvoice'} />)
                                }

                                <button className="btn-modal-close"
                                        onClick={() => {this.props.toggleInvoicesModal()}}>
                                    <span className="feather icon-x"/>
                                </button>
                            </div>
                            <div className="modal-body aftercare-event-modal">

                                {this.props.selectedFile ? null :
                                    (<div className="mb-5">
                                        <Uploader draggable
                                                  accept=".pdf"
                                                  listType="text"
                                                  onChange={this.uploadFile}
                                                  autoUpload={false}
                                                  fileList={this.state.invoiceMedia}>
                                            <div>
                                                <i className="feather icon-upload-cloud" />
                                                <p><Translation text={'subscriptionPlans.clinicInvoices.dropInvoice'} />
                                                <span className="ml-1"><Translation text={'subscriptionPlans.clinicInvoices.chooseInvoice'} /></span></p>
                                            </div>
                                        </Uploader>
                                    </div>)
                                }

                                <Form config={this.state.formConfig}
                                      onValueStateChange={this.onValueStateChange}
                                      onValidationStateChange={this.onValidationStateChange}
                                      value={this.state.value}
                                      controlName={'addInvoiceForm'}
                                      multiple={false}
                                      submitForm={this.handleInvoiceChange}/>
                            </div>
                        </CustomCard.Body>
                    </CustomCard>
                </BasicModal>
            </React.Fragment>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private onValidationStateChange = (controlName: string, isValid: boolean) => {
        let isFormValid = isValid && this.state.invoiceMedia.length > 0;
        this.setState({isFormValid:  isFormValid});
    };

    private onFormValueChange = (value: any, changeType: typeof FormControlChangeType) => {
        this.setState({value: value});
    };

    private uploadFile = (file: any) => {
        return this.setState({
            invoiceMedia: file.slice(file.length - 1),
        })
    };

    private handleInvoiceChange = () => {
        this.setState({isLoading: true});

        const value = this.state.value,
            payload: IAddInvoicePayload = {
                name: value.name,
                description: null,
                number: null,
                clinicId: this.props.clinicId,
                issuedAt: value.date,
                paidAt: value.paidAt,
                valueAmount: value.price,
                valueCurrency: value.currency
            };

        if (!this.props.selectedFile) {
            this.addInvoice(payload);
        }

        if (this.props.selectedFile) {
            const updatePayload: IUpdateInvoicePayload = {
                name: value.name,
                description: null,
                number: null,
                issuedAt: value.date,
                paidAt: value.paidAt,
                valueAmount: value.price,
                valueCurrency: value.currency
            };
            this.updateInvoice(this.props.selectedFile.id, updatePayload);
        }
    };

    private addInvoice = (payload: IAddInvoicePayload) => {
        const formData = new FormData();
        const isInvoiceAdded = this.state.invoiceMedia.length > 0;

        if (isInvoiceAdded) {
            let invoice = this.state.invoiceMedia[0].blobFile;
            invoice && formData.append('file', invoice);
        }

        payload.name && formData.append('name', payload.name);
        payload.description && formData.append('description', payload.description);
        payload.number && formData.append('number', payload.number);
        payload.valueAmount && formData.append('valueAmount', payload.valueAmount);
        payload.valueCurrency && formData.append('valueCurrency', payload.valueCurrency);
        formData.append('clinicId', this.props.clinicId);
        formData.append('issuedAt', payload.issuedAt);
        payload.paidAt && formData.append('paidAt', payload.paidAt);

        const options = {
            method: "POST",
            body: formData,
            headers: {
                Authorization: `Bearer ${this.props.authToken}`
            }
        };

        window.fetch(
            `${process.env.REACT_APP_AUTH_API_URL}/invoices`,
            options
        ).then((resp: any) => {
            console.log(resp);
            if (resp.status === 201) {
                this.setState({isLoading: false});
                this.props.toggleInvoicesModal();
                this.getInvoicesList();
                return this.alertManager.addAlert('Invoice was successfully added');
            } else {
                this.setState({isLoading: false});
                return this.alertManager.addAlert('Something went wrong. Please try again later');
            }
        });
    };

    private updateInvoice = (invoiceId: string, payload: IUpdateInvoicePayload) => {
        return(
            this.subscriptions.push(
                updateInvoiceAPI(this.props.authToken, invoiceId, payload).pipe(
                    tap(() => {
                        this.setState({isLoading: false});
                        this.props.toggleInvoicesModal();
                        this.getInvoicesList();
                        this.alertManager.addAlert('Invoice was updated');
                    }),
                    catchError((error: any) => {
                        this.setState({isLoading: false});
                        return of(this.alertManager.handleApiError(error))
                    })
                ).subscribe()
            )
        )
    };

    private changeFormControlValues(file: {[key: string]: any}) {
        this.updateDateFormControl(file.issuedAt);
        this.setState({
            value: {
                name: file.name,
                date: moment(file.issuedAt).format('YYYY-MM-DD'),
                price: file.valueAmount,
                currency: file.valueCurrency,
                paidAt: file.paidAt ? moment(file.paidAt).format('YYYY-MM-DD') : null
            },
            isFormValid: true
        });
    }

    private updateButtonText = (btnText: string) => {
        const updatedFormConfig = Object.assign({}, addInvoiceFormConfig);
        updatedFormConfig.controls.map((control: any) => {
            if (control.hasOwnProperty("controls")) {
                Object.keys(control.controls).map((key: string) => {
                    if (key === 'submitButton') {
                        control.controls[key].btnText = btnText;
                    }
                });
            }

            return control;
        });

        return this.setState({formConfig: updatedFormConfig});
    };

    private updateDateFormControl = (date: any) => {
        if (!date) {
            return
        }

        const updatedFormConfig = Object.assign({}, addInvoiceFormConfig);
        updatedFormConfig.controls.map((control: any) => {
            if (control.hasOwnProperty("controls")) {
                Object.keys(control.controls).map((key: string) => {
                    if (key === 'date') {
                        control.controls[key].openToDate = getUTCMomentFromString(date);
                    }
                });
            }

            return control;
        });

        return this.setState({formConfig: updatedFormConfig});
   };

    private getInvoicesList = () => {
        let localSearchParams = {
            page: 1,
            itemsPerPage: 10,
        };

        this.props.retrieveInvoices(new RestQueryParams(localSearchParams));
    };
}

export default withTranslation()(connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
    }),
    {}
)(withRouter(AddInvoices)));
