import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../../../store/reducers";
import {
  CustomCard,
  accountSelector,
  authTokenSelector,
  Translation,
  Form,
  IFormConfig,
  clinicProfileLoadingSelector,
  addClinicCategoriesAPI
} from "common-web";
import {IMetadataCategory, retrieveTreatmentCategories} from "../../../../store/reducers/metadataSlice";
import { treatmentCategoriesSelector } from "../../../../store/selectors/metadataSelectors";
import {treatmentCategoriesFormConfig} from "./formConfig";
import {catchError, map} from "rxjs/operators";
import {fixInjectedProperties, lazyInject} from "../../../../ioc";
import {IAlertManagerService} from "../../../../service/alertManagerService";
import {of, Subscription} from "rxjs";

export interface IMultiselectOption {
  value?: string;
  label?: string;
}

interface IConnectedClinicTreatmentsProps {
  readonly isLoading: boolean;
  readonly authToken: string;
  readonly account: any;
  readonly treatmentCategories: IMetadataCategory[];
  readonly retrieveTreatmentCategories: typeof retrieveTreatmentCategories
}

interface IExternalClinicTreatmentsProps {
  readonly clinic: any;
  readonly clinicId: string;
}

interface IClinicTreatmentsProps extends
  IExternalClinicTreatmentsProps,
  IConnectedClinicTreatmentsProps {}

interface IClinicTreatmentsState {
  isLoading: boolean;
  formConfig: typeof IFormConfig;
  value: any;
}

class ClinicTreatments extends React.Component<IClinicTreatmentsProps, IClinicTreatmentsState> {
  @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;
  private subscription: Subscription | null = null;

  constructor(props: IClinicTreatmentsProps) {
    super(props);

    this.state = {
      isLoading: true,
      formConfig: treatmentCategoriesFormConfig,
      value: null
    };

    fixInjectedProperties(this);
  }

  componentDidMount(): void {
    this.props.retrieveTreatmentCategories(this.props.authToken);
    if(this.props.clinic) {
      this.updateFormFromState();
    }
  }

  componentDidUpdate(
    prevProps: Readonly<IClinicTreatmentsProps>,
    prevState: Readonly<IClinicTreatmentsState>,
    snapshot?: any
  ): void {
    if(this.props.isLoading !== prevProps.isLoading
      && !this.props.isLoading) {
      this.setState({isLoading: false})
    }

    if(this.props.treatmentCategories !== prevProps.treatmentCategories) {
      this.setTreatmentCategories();
    }

    if(this.props.clinic !== prevProps.clinic) {
      this.updateFormFromState();
    }
  }

  componentWillUnmount() {
    if (null !== this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  render() {
    return (
      <CustomCard showLocalLoader={this.state.isLoading}>
        <CustomCard.Header>
            <Translation text={'profile.treatmentCategories.title'}/>
        </CustomCard.Header>
        <CustomCard.Body>
          <Form config={this.state.formConfig}
                value={this.state.value}
                controlName={'clinicTreatmentsForm'}
                submitForm={this.changeTreatmentCategories}/>
        </CustomCard.Body>
      </CustomCard>
    );
  }

  private setTreatmentCategories = () => {
    if (!this.props.treatmentCategories) {
      return;
    }

    treatmentCategoriesFormConfig.controls.map((control: any) => {
      if (control.hasOwnProperty("controls")) {
        Object.keys(control.controls).map((key: string) => {
          if (key === 'treatmentCategories') {
            control.controls[key].multiselectOptions = this.props.treatmentCategories;
          }
        });
      }

      return control;
    });

    this.setState({formConfig: treatmentCategoriesFormConfig});
  };

  private mapAccountToFormData(clinic: any): any {
    return {
      treatmentCategories: clinic.treatmentCategories
    };
  }

  updateFormFromState() {
    this.setState({
      value: this.mapAccountToFormData(this.props.clinic)
    })
  }

  private changeTreatmentCategories = (event: any, value: any, valid: boolean, touched: boolean): void => {
    if (!valid || !touched) {
      return;
    }
    this.setState({isLoading: true});
    let categories: string[] = [];
    value.treatmentCategories.map((category: {[key: string]: any}) => {
      return categories.push(category.value);
    });

    this.subscription = this.addClinicTreatmentCategory(categories).subscribe();
  };

  private addClinicTreatmentCategory(categories: string[]) {
    return addClinicCategoriesAPI(
      this.props.clinicId,
      this.props.authToken,
      categories
    ).pipe(
      map((resp: any) => {
        this.setState({
          isLoading: false,
        });
        this.alertManager.addAlert('Clinic treatment categories were successfully updated');
      }),
      catchError((error: any) => {
        this.setState({
          isLoading: false,
        });
        return of(this.alertManager.handleApiError(error.response));
      }),
    );
  }
}

export default connect(
  (state: RootState) => ({
    isLoading: clinicProfileLoadingSelector(state),
    authToken: authTokenSelector(state),
    account: accountSelector(state),
    treatmentCategories: treatmentCategoriesSelector(state)
  }),
  {
    retrieveTreatmentCategories
  }
)(ClinicTreatments);
