import { Observable } from "rxjs";
import { RestQueryParams } from "common-web";
import {clinicAPI} from "./provider/clinicAPI";

export interface IUpdateInvoicePayload {
    name: string | null,
    description: string | null,
    number: string | null,
    issuedAt: string,
    paidAt: string | null,
    valueAmount: string | null,
    valueCurrency: string | null
}

export interface IAddInvoicePayload extends IUpdateInvoicePayload {
    clinicId: string | null
}

export function updateInvoiceAPI(
    accessToken: string,
    invoiceId: string,
    invoice: IUpdateInvoicePayload
): Observable<any> {
    return clinicAPI.put(
        `invoices/${invoiceId}`,
        {
            name: invoice.name,
            description: invoice.description,
            number: invoice.number,
            issuedAt: invoice.issuedAt,
            paidAt: invoice.paidAt,
            valueAmount: invoice.valueAmount,
            valueCurrency: invoice.valueCurrency
        },
        new RestQueryParams(),
        {
            Authorization: `Bearer ${accessToken}`
        }
    );
}
