import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../../../store/reducers";
import {
  authTokenSelector,
  clinicProfileLoadingSelector,
  CustomCard,
  Translation,
  updateClinicLogoStart
} from "common-web";
import { Uploader } from 'rsuite';

export interface IFileUploadListElement {
  name: string;
  fileKey: string;
  url: string;
}

interface IClinicPhotosExternalProps {
  clinic: {[key: string]: any};
  clinicId: string;
}

interface IClinicPhotosPropsConnectedProps {
  readonly isLoading: boolean;
  readonly updateClinicLogoStart: typeof updateClinicLogoStart;
  readonly authToken: string;
}

interface IClinicPhotosProps extends
  IClinicPhotosExternalProps,
  IClinicPhotosPropsConnectedProps {}

interface IClinicPhotosState {
  logo: string | null;
  isLoading: boolean;
  clinicMedia: IFileUploadListElement[];
  value: any[];
}

class ClinicPhotos extends React.Component<IClinicPhotosProps, IClinicPhotosState> {
  constructor(props: IClinicPhotosProps) {
    super(props);

    this.state = {
      logo: null,
      isLoading: false,
      clinicMedia: [],
      value: []
    };
  }

  componentDidMount(): void {
    this.getClinicMedia();
  }

  componentDidUpdate(
    prevProps: Readonly<IClinicPhotosProps>,
    prevState: Readonly<IClinicPhotosState>,
    snapshot?: any
  ): void {
    if(this.props.isLoading !== prevProps.isLoading
      && !this.props.isLoading) {
      this.setState({isLoading: false})
    }

    if(this.props.clinic !== prevProps.clinic) {
      this.getClinicMedia();
    }
  }

  render() {
    return (
      <CustomCard showLocalLoader={this.state.isLoading}>
        <CustomCard.Header>
          <Translation text={'profile.clinicPhotos.title'}/>
        </CustomCard.Header>
        <CustomCard.Body>
          <form>
            <Uploader draggable
                      removable={false}
                      accept="image/x-png, image/jpeg"
                      listType="picture"
                      onChange={this.addFile}
                      autoUpload={false}
                      fileList={this.state.value && this.state.value.length ?
                          this.state.value : this.state.clinicMedia}>
                <div>
                  <i className="feather icon-upload-cloud" />
                  <p>Drop you file here or <span>choose from your computer</span></p>
                </div>
            </Uploader>
            <div className="button-container mt-4">
              <button onClick={() => this.changeLogo()}
                      type="button"
                      className="btn btn-primary">
                  Save
              </button>
            </div>
          </form>
        </CustomCard.Body>
      </CustomCard>
    );
  }

  private addFile = (file: any) => {
    if (!file.length) {
      return this.setState({value: [], logo: null});
    }

    return this.setState({
      value: file.slice(file.length - 1),
      logo: file.length > 1 ? file[1].blobFile : file[0].blobFile,
    })
  };

  private changeLogo = () => {
    if (!this.state.logo) {
      return;
    }

    const formData = new FormData();
    const imageData = this.state.logo;
    formData.append('file', imageData);
    formData.append('public', 'true');

    this.setState({isLoading: true});
    window.fetch(
        `${process.env.REACT_APP_AUTH_API_URL}/media_objects`,
        {
          method: 'POST',
          body: formData,
          headers: new Headers({
            'Authorization': 'Bearer ' + this.props.authToken
          })
        }
    ).then((resp: any) => {
      return resp.json().then( (fileUploadObj: any) => {
        const imageId = fileUploadObj['@id'].replace('/media_object/', '');
        this.props.updateClinicLogoStart(this.props.clinicId, imageId);
        this.setState({isLoading: false});
      })
    });
  };

  private getClinicMedia() {
    if(this.props.clinic && this.props.clinic.logo) {
      const clinicLogo = this.props.clinic.logo;
      let mediaList: IFileUploadListElement[] = [{
        name: 'clinic_logo',
        fileKey: clinicLogo.id,
        url: clinicLogo.contentUrl
      }];

     this.setState({clinicMedia: mediaList});
    }
  }
}

export default connect(
  (state: RootState) => ({
    authToken: authTokenSelector(state),
    isLoading: clinicProfileLoadingSelector(state),
  }),
  {
    updateClinicLogoStart
  }
)(ClinicPhotos);
