import { Observable } from "rxjs";
import { RestQueryParams } from "common-web";
import {treatmentTypeAPI} from "./provider/treatmentTypeAPI";

export function archiveProposedPlanAPI(
    accessToken: string,
    planId: string
): Observable<any> {
    return treatmentTypeAPI.delete(
        `subscription_definitions/${planId}/archive`,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${accessToken}`
        }
    );
}
