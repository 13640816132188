import { Observable } from "rxjs";
import { RestQueryParams } from "common-web";
import {clinicAPI} from "./provider/clinicAPI";

export function deleteInvoiceAPI(
    accessToken: string,
    invoiceId: string
    ): Observable<any> {
    return clinicAPI.delete(
        `invoices/${invoiceId}`,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${accessToken}`
        }
    );
}
