import { RootState } from "../reducers";
import { createSelector } from "redux-starter-kit";

export interface ITreatmentCategoryBaseState {
    loading: boolean;
    error: string;
}

export interface ITreatmentCategoryListState extends ITreatmentCategoryBaseState {
    retrieved: any;
    eventSource: EventSource;
}

export const selectTreatmentCategoryList = (state: RootState): ITreatmentCategoryListState => {
    return state.treatmentCategory.list;
};

export const retrievedTreatmentCategoryListSelector = createSelector(
    [selectTreatmentCategoryList],
    (state: ITreatmentCategoryListState) => state.retrieved
);

export const treatmentCategoryListLoadingSelector = createSelector(
    [selectTreatmentCategoryList],
    (state: ITreatmentCategoryListState) => state.loading
);

export const treatmentCategoryListErrorSelector = createSelector(
    [selectTreatmentCategoryList],
    (state: ITreatmentCategoryListState) => state.error
);

export const treatmentCategoryListEventSourceSelector = createSelector(
    [selectTreatmentCategoryList],
    (state: ITreatmentCategoryListState) => state.eventSource
);
