const pl = {
  axaAdditionalInformation: {
    heading: "Formulaż konfiguracyjny ubezpieczenia podróżnego",
    title: "Clinic Hunter Cover",
    subtitle: "medyczne ubezpieczenia podróżne",
    howItWorks: "Jak to działa",
    aboutUs: "O nas",
    terms: "Warunki",
    claims: "Roszczenia",
    faq: "faq",
  },
  treatmentCategories: {
    title: "Treatment Categories",
    btnAdd: "Add Treatment Category",
    deleteModal: {
      title: "Are you sure you want to delete treatment category?"
    },
    addModal: {
      title: "Create New Treatment category",
      addBtn: "Add Category"
    },
    category: {
      title: "Treatment Category",
      btnAdd: "Add Treatment Type",
      form: {
        field: "Field",
        value: "Value"
      },
      modal: {
        title: "Create New Treatment Type"
      }
    }
  },
  action: {
    back: "Back to list",
    edit: "Edit",
    delete: "Delete",
    yes: "Yes",
    no: "No"
  }
};

export default pl;
