import { Observable } from "rxjs";
import { RestQueryParams } from "common-web";
import {clinicAPI} from "./provider/clinicAPI";

export function getClinicPaymentOptionsAPI(accessToken: string): Observable<any> {
    return clinicAPI.get(
        `payment_options`,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${accessToken}`
        }
    );
}
