import { RootState } from "../reducers";
import { createSelector } from "redux-starter-kit";

export interface ISubscriptionDefinitionBaseState {
    loading: boolean;
    error: string;
}

export interface ISubscriptionDefinitionListState extends ISubscriptionDefinitionBaseState {
    retrieved: any; // model for subscription
    eventSource: EventSource;
}

export const selectSubscriptionDefinitionList = (state: RootState): ISubscriptionDefinitionListState => {
    return state.subscriptionDefinition.list;
};

export const retrievedSubscriptionDefinitionListSelector = createSelector(
    [selectSubscriptionDefinitionList],
    (state: ISubscriptionDefinitionListState) => state.retrieved
);

export const subscriptionDefinitionListLoadingSelector = createSelector(
    [selectSubscriptionDefinitionList],
    (state: ISubscriptionDefinitionListState) => state.loading
);

export const subscriptionDefinitionListErrorSelector = createSelector(
    [selectSubscriptionDefinitionList],
    (state: ISubscriptionDefinitionListState) => state.error
);

export const subscriptionDefinitionListEventSourceSelector = createSelector(
    [selectSubscriptionDefinitionList],
    (state: ISubscriptionDefinitionListState) => state.eventSource
);
