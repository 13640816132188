import { createSlice } from "redux-starter-kit";

export interface IClinicPhoto {
    public: boolean,
    contentUrl: string
}

export interface IClinicAddress {
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    postcode: string;
    city: string;
    country: string;
}

export interface IClinicInvoiceAddress {
    taxId: string,
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    postcode: string;
    city: string;
    country: string;
}

export interface IClinicAccount {
    firstName: string,
    lastName: string;
    birthdate: string;
    phone: string;
    address: IClinicAddress;
    invoiceAddress: IClinicInvoiceAddress;
    residency: string;
}

export interface IClinicState {
    account: IClinicAccount | null;
    companyName: string | null;
    reference: string | null;
    isMiddleMan: boolean | null;
    feePercent: number | null;
    error: string | null;
    isLoading: boolean;
}

export interface ISetClinicState {
    readonly payload: {
        account: IClinicAccount;
        companyName: string;
        reference: string;
        isMiddleMan: boolean;
        feePercent: number;
    }
}

export interface IAddClinic {
    readonly payload: {
        account: IClinicAccount;
        companyName: string;
        reference: string;
        isMiddleMan: boolean;
        feePercent: number;
    }
}

export interface ISetClinicStateFailure {
    readonly payload: {
        error: string;
    }
}

export interface IChangeClinicStateLoading {
    readonly payload: {
        isLoading: boolean;
    }
}

const initialState: IClinicState = {
    account: null,
    companyName: null,
    reference: null,
    isMiddleMan: null,
    feePercent: null,
    error: null,
    isLoading: false
};

const clinicSlice = createSlice({
    slice: "clinic",
    initialState: initialState,
    reducers: {
        setClinicState: {
            reducer: (state: IClinicState, action: ISetClinicState) => {
                return {
                    account: action.payload.account,
                    companyName: action.payload.companyName,
                    reference: action.payload.reference,
                    isMiddleMan: action.payload.isMiddleMan,
                    feePercent: action.payload.feePercent,
                    error: state.error,
                    isLoading: state.isLoading
                };
            },
            prepare(account: IClinicAccount,
                    companyName: string,
                    reference: string,
                    isMiddleMan: boolean,
                    feePercent: number,
            ) {
                return {
                    payload: {
                        account: account,
                        companyName: companyName,
                        reference: reference,
                        isMiddleMan: isMiddleMan,
                        feePercent: feePercent,
                    }
                };
            }
        },
        addClinic: {
            reducer: (state: IClinicState, action: IAddClinic) => {
                return {
                    account: action.payload.account,
                    companyName: action.payload.companyName,
                    reference: action.payload.reference,
                    isMiddleMan: action.payload.isMiddleMan,
                    feePercent: action.payload.feePercent,
                    error: state.error,
                    isLoading: true
                };
            },
            prepare(account: IClinicAccount,
                    companyName: string,
                    reference: string,
                    isMiddleMan: boolean,
                    feePercent: number
            ) {
                return {
                    payload: {
                        account: account,
                        companyName: companyName,
                        reference: reference,
                        isMiddleMan: isMiddleMan,
                        feePercent: feePercent,
                    }
                };
            }
        },
        setClinicStateFailure: {
            reducer: (state: IClinicState, action: ISetClinicStateFailure) => {
                return {
                    account: state.account,
                    companyName: state.companyName,
                    reference: state.reference,
                    isMiddleMan: state.isMiddleMan,
                    feePercent: state.feePercent,
                    error: action.payload.error,
                    isLoading: state.isLoading
                };
            },
            prepare(error: string) {
                return {
                    payload: {
                        error: error
                    }
                };
            }
        },
        changeClinicStateLoading: {
            reducer: (state: IClinicState, action: IChangeClinicStateLoading) => {
                return {
                    account: state.account,
                    companyName: state.companyName,
                    reference: state.reference,
                    isMiddleMan: state.isMiddleMan,
                    feePercent: state.feePercent,
                    error: state.error,
                    isLoading: action.payload.isLoading
                };
            },
            prepare(isLoading: boolean) {
                return {
                    payload: {
                        isLoading: isLoading
                    }
                };
            }
        },
    }
});

export const {
    setClinicState,
    setClinicStateFailure,
    addClinic,
    changeClinicStateLoading
} = clinicSlice.actions;

export default clinicSlice.reducer;
