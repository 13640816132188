import {parseToStartOfDay} from "common-web";
import {IInquirySubject} from "../../../../api/editInquiryData";

export class InquirySubjectFactory {
    createSubjects(inquiryForm: {[key: string]: any}, inquiry: any): IInquirySubject[] | [] {
        if (!inquiryForm.name || !inquiryForm.lastName || !inquiryForm.birthDate) {
            return [];
        }
        const mainInquirySubject: IInquirySubject = {
            firstName: inquiryForm.name,
            lastName: inquiryForm.lastName,
            birthDate: parseToStartOfDay(inquiryForm.birthDate),
        };

        if (inquiry && inquiry.inquirySubjects) {
            const mainSubject = inquiry.inquirySubjects.find((subject: any) => subject.main === true);
            mainInquirySubject['id'] = mainSubject ? mainSubject.id : undefined;
        }

        let inquirySubjectsArray: IInquirySubject[] = [];
        inquirySubjectsArray.push(mainInquirySubject);

        if (inquiryForm.companionName &&
            inquiryForm.companionLastName &&
            inquiryForm.companionBirthDate) {
            const companionBirthDate = parseToStartOfDay(inquiryForm.companionBirthDate);
            const companionInquirySubject: IInquirySubject = {
                firstName: inquiryForm.companionName,
                lastName: inquiryForm.companionLastName,
                birthDate: companionBirthDate,
            };

            if (inquiry && inquiry.inquirySubjects && inquiry.inquirySubjects.length > 1) {
                const companionSubject = inquiry.inquirySubjects.find((subject: any) => subject.main === false);
                companionInquirySubject['id'] = companionSubject ? companionSubject.id : undefined;
            }

            inquirySubjectsArray.push(companionInquirySubject);
        }

        return inquirySubjectsArray;
    }
}
