import React from "react";
import { connect } from "react-redux";
import {
  CustomCard,
  Form,
  Translation,
  IFormConfig,
  authTokenSelector,
  ValidationRules,
  FormControlChangeType,
} from "common-web";
import { planDetailsFormConfig } from "./planDetailsFormConfig";
import {RootState} from "../../../../store/reducers";
import {updateSubscriptionDefinitionAPI} from "../../../../api/updateSubscriptionDefinition";
import {IAddSubscriptionPayload} from "../../../../api/addSubscriptionDefinition";
import {BehaviorSubject, of, Subscription} from "rxjs";
import {catchError, filter, map, tap} from "rxjs/operators";
import {fixInjectedProperties, lazyInject} from "../../../../ioc";
import {IAlertManagerService} from "../../../../service/alertManagerService";


interface IPlanDetailsConnectedProps {
  readonly authToken: string;
}

interface IPlanDetailsExternalProps {
  subscriptionPlan: {[key: string]: any} | null;
  isLoading: boolean;
}

interface IPlanDetailsProps extends
  IPlanDetailsConnectedProps,
  IPlanDetailsExternalProps {}

interface IPlanDetailsState {
  formConfig: typeof IFormConfig;
  value: any;
}

class PlanDetails extends React.Component<IPlanDetailsProps, IPlanDetailsState> {
  readonly subscriptions: Subscription[] = [];
  readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
  @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

  constructor(props: IPlanDetailsProps) {
    super(props);

    this.state = {
      formConfig: planDetailsFormConfig,
      value: null
    };

    fixInjectedProperties(this);
  }

  componentDidMount(): void {
    this.subscriptions.push(
        this.onValueStateChange$.pipe(
            filter((data: any) => data && data.changeType === FormControlChangeType.User),
            tap((data: any) => this.onFormValueChange(data.value, data.changeType)),
        ).subscribe()
    );

    if (this.props.subscriptionPlan) {
      this.updateFormFromState();
    }
  }

  componentDidUpdate(
    prevProps: Readonly<IPlanDetailsProps>,
    prevState: Readonly<IPlanDetailsState>,
    snapshot?: any
  ): void {
    if (this.props.subscriptionPlan !== prevProps.subscriptionPlan &&
        this.props.subscriptionPlan) {
      this.updateFormFromState();
    }
  }

  componentWillUnmount() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  render() {
    return (
      <CustomCard showLocalLoader={this.props.isLoading}>
        <CustomCard.Header>
          <Translation text={'subscriptionPlans.view.details'}/>
        </CustomCard.Header>
        <CustomCard.Body>
          <Form config={this.state.formConfig}
                onValueStateChange={this.onValueStateChange}
                value={this.state.value}
                controlName={'clinicDetailsForm'}
                submitForm={this.changePlanDetails}/>
        </CustomCard.Body>
      </CustomCard>
    );
  }
  private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
    this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
  };

  private onFormValueChange = (value: any, changeType: typeof FormControlChangeType) => {
    Object.keys(value).map((key: string) => {
      if (key === 'onlineConsultation' &&
          value[key] !== null &&
          value[key] !== this.state.value?.onlineConsultation) {
        this.updateOnlineConsultationControls(value[key])
      }

      if (key === 'clinicWidget' && value[key] !== null) {
        this.updateWidgetAmountControl(value[key]);
      }
    });

    this.setState({value: value});
  };

  private mapAccountToFormData(subscriptionPlan: any): any {
    const planDetails = subscriptionPlan.subscriptionDetails,
        planRestrictions = planDetails.subscriptionRestrictions;

    return {
      planName: planDetails.name,
      planPrice: planDetails.price.amount / 100,
      isPlanActive: subscriptionPlan.active,
      // aftercare: planRestrictions.treatmentPlanEnabled,
      clinicHunterWidget: planRestrictions.appCalendarWidgetAvailable,
      clinicWidget: planRestrictions.clinicCalendarWidgetAvailable,
      onlineConsultation: planRestrictions.appCalendarWidgetAvailable ||
        planRestrictions.clinicCalendarWidgetAvailable || planRestrictions.consultationTimeLimit > 0,
      // reviews: plan.reviews,
      telemedicineHours: planRestrictions.consultationTimeLimit / 60,
      widgetAmount: planRestrictions.calendarCountLimit
    };
  }

  updateFormFromState() {
    this.setState({
      value: this.mapAccountToFormData(this.props.subscriptionPlan)
    }, () => {
      this.updateOnlineConsultationControls(this.state.value.onlineConsultation);
      this.updateWidgetAmountControl(this.state.value.clinicWidget);
    })
  }

  private changePlanDetails = (event: any, value: any, valid: boolean, touched: boolean): void => {
    if (!valid || !touched || !this.props.subscriptionPlan) {
      return;
    }
    const subscriptionPlan = this.props.subscriptionPlan,
      payload: IAddSubscriptionPayload = {
        name: value.planName,
        description: subscriptionPlan.subscriptionDetails.description,
        price: {
          amount: (Number(value.planPrice) * 100).toString(),
          currency: {
            code: 'EUR'
          }
        },
        duration: 30,
        subscriptionRestrictions: {
          consultationTimeLimit: value.onlineConsultation ? (value.telemedicineHours ? Number(value.telemedicineHours) * 60 : 0) : 0,
          clinicCalendarWidgetAvailable: value.onlineConsultation ? (value.clinicWidget ? value.clinicWidget : false) : false,
          appCalendarWidgetAvailable: value.onlineConsultation ? (value.clinicHunterWidget ? value.clinicHunterWidget : false) : false,
          treatmentPlanEnabled: true,
          calendarCountLimit: !value.clinicWidget ? 0 : (value.widgetAmount ? Number(value.widgetAmount) : 0)
        }
      };

    updateSubscriptionDefinitionAPI(
        this.props.authToken,
        subscriptionPlan.id,
        payload,
        subscriptionPlan.public,
        subscriptionPlan.itemOrder,
        value.isPlanActive).pipe(
            map(() => {
              this.alertManager.addAlert("Subscription plan details were updated");
            }),
            catchError((error: any) => {
              return of(this.alertManager.handleApiError(error));
        })
    ).subscribe();
  };

  private updateOnlineConsultationControls(value: string): void {
    const cloneFormConfig = Object.assign({}, this.state.formConfig);
    cloneFormConfig.controls.map((control: any) => {
      if (control.hasOwnProperty("controls")) {
        Object.keys(control.controls).map((key: string) => {
          if (value) {
            let requiredField = [
              { name: ValidationRules.IS_REQUIRED },
            ];
            if (key === 'telemedicineHours') {
              control.controls[key].disabled = false;
              control.controls[key].validationRules = requiredField;
            }
            if (key === 'clinicWidget') {
              control.controls[key].disabled = false;
              control.controls[key].validationRules = requiredField;
            }
            if (key === 'clinicHunterWidget') {
              control.controls[key].disabled = false;
              control.controls[key].validationRules = requiredField;
            }
          } else {
            if (key === 'telemedicineHours') {
              control.controls[key].disabled = true;
              control.controls[key].validationRules = [];
            }
            if (key === 'clinicWidget') {
              control.controls[key].disabled = true;
              control.controls[key].validationRules = [];
            }
            if (key === 'clinicHunterWidget') {
              control.controls[key].disabled = true;
              control.controls[key].validationRules = [];
            }
          }
        });
      }

      return control;
    });

    this.setState({
      formConfig: cloneFormConfig,
    });
  }

  private updateWidgetAmountControl(value: string): void {
    const cloneFormConfig = Object.assign({}, this.state.formConfig);
    cloneFormConfig.controls.map((control: any) => {
      if (control.hasOwnProperty("controls")) {
        Object.keys(control.controls).map((key: string) => {
          if (value) {
            let requiredField = [
              { name: ValidationRules.IS_REQUIRED },
            ];
            if (key === 'widgetAmount') {
              control.controls[key].disabled = false;
              control.controls[key].validationRules = requiredField;
            }
          } else {
            if (key === 'widgetAmount') {
              control.controls[key].disabled = true;
              control.controls[key].validationRules = [];
            }
          }
        });
      }

      return control;
    });

    this.setState({
      formConfig: cloneFormConfig,
    });
  }
}

export default connect(
  (state: RootState) => ({
    authToken: authTokenSelector(state)
  }),
  {}
)(PlanDetails);
