import {FormButtonType, FormControlType, IFormConfig, InputType, ValidationRules} from 'common-web';

export const treatmentTypeFormConfig = (isFormEdited?: boolean): typeof IFormConfig => ({
    controlType: 'group',
    class: '',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);

        return previousStateSnapshot;
    },
    controls: [
        {
            key: 'name',
            controlType: 'group',
            class: 'row',
            controls: {
                enTranslation: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [{name: ValidationRules.IS_REQUIRED}],
                    placeholder: 'Treatement Type',
                    label: 'Treatment Type (English Translation)',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-12',
                },
                plTranslation: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [{name: ValidationRules.IS_REQUIRED}],
                    placeholder: 'Treatment Type',
                    label: 'Treatment Type (Polish Translation)',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-12',
                }
            },
        }, {
            controlType: 'group',
            key: 'treatment_type_submit',
            class: 'row',
            controls: {
                submitButton: {
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    hostClass: 'col-xl-12 mb-0',
                    defaultContainerStyles: '',
                    containerStyles: 'button-container mt-2',
                    defaultValue: null,
                    btnText: isFormEdited ? 'treatmentCategories.editTreatmentTypeModal.button' : 'treatmentCategories.addTreatmentTypeModal.button',
                },
            },
        },
    ]
});
