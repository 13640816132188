import React from "react";
import ChangePasswordForm from "./ChangePassword";
import {Toast, Translation} from "common-web";

interface IConnectedSettingsProps {
}

interface IExternalSettingsProps {}

interface ISettingsProps extends
    IConnectedSettingsProps,
    IExternalSettingsProps {}


class Settings extends React.Component<ISettingsProps> {

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-xl-6">
                        <div className="view-header">
                            <div className="view-title">
                                <Translation text={'setting.title'}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-6">
                        <ChangePasswordForm/>
                    </div>
                </div>
                <Toast/>
            </React.Fragment>
        );
    }
}

export default Settings;
