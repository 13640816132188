import { createSelector } from "redux-starter-kit";
import { RootState } from "../reducers";
import {IInsuranceAxaReportState} from "../reducers/insuranceAxaReportSlice";

export const selectInsuranceAxaReport = (state: RootState): IInsuranceAxaReportState => {
  return state.insuranceAxaReport;
};

export const insuranceAxaReportSelector = createSelector(
  [selectInsuranceAxaReport],
  (state: IInsuranceAxaReportState) => state.reports
);

export const insuranceAxaReportLoaderSelector = createSelector(
  [selectInsuranceAxaReport],
  (state: IInsuranceAxaReportState) => state.isLoading
);

export const insuranceAxaReportErrorSelector = createSelector(
  [selectInsuranceAxaReport],
  (state: IInsuranceAxaReportState) => state.error
);
