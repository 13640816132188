import {
    FormButtonType,
    FormControlType,
    IFormConfig,
    IMultiselectOption,
    InputType,
    MultiselectMenuPlacement,
    MultiSelectType,
    ValidationRules
} from 'common-web';

export const recommendationFrequencyUnits = [
    {
        value: 'day',
        label: 'day'
    },
    {
        value: 'week',
        label: 'week'
    },
    {
        value: 'month',
        label: 'month'
    }
];

export const reminderFrequencyUnits = [{value: 'hour', label: 'hour'}, ... recommendationFrequencyUnits];

export const addAftercareFormConfig: typeof IFormConfig = {
    controlType: 'group',
    class: '',
    dataAccessor: (data: any) => data, // data is not grouped
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);

        return previousStateSnapshot;
    },
    controls: [
        {
            controlType: 'group',
            key: 'recommendationName',
            class: 'form-row',
            controls: {
                recommendationName: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                    ],
                    placeholder: 'aftercare.predefinedEvents.addEventModal.form.eventName',
                    label: 'aftercare.predefinedEvents.addEventModal.form.eventName',
                    type: InputType.TEXT,
                    hostClass: 'col-100'
                }
            }
        },
        {
            controlType: 'group',
            key: 'recommendationDescription',
            class: 'form-row',
            controls: {
                recommendationDescription: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.TEXTAREA,
                    placeholder: 'aftercare.predefinedEvents.addEventModal.form.eventDescription',
                    label: 'aftercare.predefinedEvents.addEventModal.form.eventDescription',
                    type: InputType.TEXT,
                    hostClass: 'col-100'
                }
            }
        },
        {
            controlType: 'group',
            key: 'recommendationReminder',
            class: 'form-row',
            controls: {
                reminderAmount: {
                    hostClass: 'col-33',
                    controlType: 'control',
                    defaultValue: null,
                    placeholder: 'aftercare.predefinedEvents.addEventModal.form.selectAmountPlaceholder',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    label:'aftercare.predefinedEvents.addEventModal.form.reminderDate',
                    validationRules: [
                        { name: ValidationRules.IS_REQUIRED },
                    ],
                    multiselectOptions: () => getDefaultIntervalOptions(23, 0),
                    inputDataMapper: (data: any) => {
                        if (!data && data !== 0) {
                            return [];
                        }

                        return addAftercareFormConfig.controls[2].controls.reminderAmount.multiselectOptions
                            .filter((reminder: any) => reminder.value === data)
                            .map((recommendationReminder: any) => ({
                                value: recommendationReminder.value,
                                label: recommendationReminder.label,
                            }));
                    },
                    outputDataMapper: (data: any) => {
                        if (Array.isArray(data)) {
                            return (data.length > 0) ? data[0].value : null;
                        }
                        if (data && typeof data === 'object') {
                            return data.value;
                        }
                        return data;
                    },
                },
                reminderUnit: {
                    hostClass: 'col-66 custom-input-group',
                    controlType: 'control',
                    defaultValue: null,
                    placeholder: 'aftercare.predefinedEvents.addEventModal.form.selectTypePlaceholder',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    label:'aftercare.predefinedEvents.addEventModal.form.before',
                    validationRules: [
                        { name: ValidationRules.IS_REQUIRED },
                    ],
                    multiselectOptions: reminderFrequencyUnits,
                    inputDataMapper: (data: any) => {
                        if (!data) {
                            return [];
                        }

                        return addAftercareFormConfig.controls[2].controls.reminderUnit.multiselectOptions
                            .filter((reminder: any) => reminder.value === data)
                            .map((reminderUnit: any) => ({
                                value: reminderUnit.value,
                                label: reminderUnit.label,
                            }));
                    },
                    outputDataMapper: (data: any) => {
                        if (Array.isArray(data)) {
                            return (data.length > 0) ? data[0].value : null;
                        }
                        if (data && typeof data === 'object') {
                            return data.value;
                        }
                        return data;
                    },
                }
            }
        },
        {
            controlType: 'group',
            key: 'repeatEvent',
            class: 'form-row',
            controls: {
                repeatEvent: {
                    hostClass: 'col-100 d-flex',
                    controlType: 'control',
                    // defaultValue: null,
                    formControlType: FormControlType.RADIO,
                    options: [
                        {value: 'single', displayValue: 'Single'},
                        {value: 'consecutive', displayValue: 'Consecutive'}
                    ],
                    validationRules: [
                        { name: ValidationRules.IS_REQUIRED },
                    ],
                    placeholder: 'aftercare.predefinedEvents.addEventModal.form.eventOccurrence',
                    label: 'aftercare.predefinedEvents.addEventModal.form.eventOccurrence',
                    type: InputType.RADIO,
                }
            }
        },
        {
            controlType: 'group',
            key: 'consecutiveType',
            class: 'form-row',
            controls: {
                consecutiveType: {
                    hostClass: 'col-50',
                    controlType: 'control',
                    defaultValue: null,
                    placeholder: 'aftercare.predefinedEvents.addEventModal.form.selectTypePlaceholder',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    label:'aftercare.predefinedEvents.addEventModal.form.consecutiveType',
                    disabled: true,
                    validationRules: [],
                    multiselectOptions: [
                        {
                            value: 'number',
                            label: 'Events number'
                        },
                        {
                            value: 'period',
                            label: 'Till date'
                        }
                    ],
                    menuPlacement: MultiselectMenuPlacement.TOP,
                    inputDataMapper: (data: any) => {
                        if (!data) {
                            return [];
                        }

                        return addAftercareFormConfig.controls[4].controls.consecutiveType.multiselectOptions
                            .filter((recommendation: any) => recommendation.value === data)
                            .map((consecutiveType: any) => ({
                                value: consecutiveType.value,
                                label: consecutiveType.label,
                            }));
                    },
                    outputDataMapper: (data: any) => {
                        if (Array.isArray(data)) {
                            return (data.length > 0) ? data[0].value : null;
                        }
                        if (data && typeof data === 'object') {
                            return data.value;
                        }
                        return data;
                    },
                },
                consecutiveValue: {
                    hostClass: 'col-50 custom-input-group',
                    controlType: 'control',
                    defaultValue: null,
                    placeholder: 'aftercare.predefinedEvents.addEventModal.form.selectAmountPlaceholder',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    disabled: true,
                    validationRules: [],
                    multiselectOptions: () => getDefaultIntervalOptions(30),
                    menuPlacement: MultiselectMenuPlacement.TOP,
                    inputDataMapper: (data: any) => {
                        if (!data) {
                            return [];
                        }

                        return addAftercareFormConfig.controls[4].controls.consecutiveValue.multiselectOptions
                            .filter((recommendation: any) => recommendation.value === data)
                            .map((consecutiveValue: any) => ({
                                value: consecutiveValue.value,
                                label: consecutiveValue.label,
                            }));
                    },
                    outputDataMapper: (data: any) => {
                        if (Array.isArray(data)) {
                            return (data.length > 0) ? data[0].value : null;
                        }
                        if (data && typeof data === 'object') {
                            return data.value;
                        }
                        return data;
                    },
                }
            }
        },
        {
            controlType: 'group',
            key: 'interval',
            class: 'form-row',
            controls: {
                intervalValue: {
                    hostClass: 'col-33',
                    controlType: 'control',
                    defaultValue: null,
                    placeholder: 'aftercare.predefinedEvents.addEventModal.form.selectAmountPlaceholder',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    label:'aftercare.predefinedEvents.addEventModal.form.interval',
                    disabled: true,
                    validationRules: [],
                    multiselectOptions: () => getDefaultIntervalOptions(30),
                    menuPlacement: MultiselectMenuPlacement.TOP,
                    inputDataMapper: (data: any) => {
                        if (!data) {
                            return [];
                        }

                        return addAftercareFormConfig.controls[5].controls.intervalValue.multiselectOptions
                            .filter((interval: any) => interval.value === data)
                            .map((intervalAmount: any) => ({
                                value: intervalAmount.value,
                                label: intervalAmount.label,
                            }));
                    },
                    outputDataMapper: (data: any) => {
                        if (Array.isArray(data)) {
                            return (data.length > 0) ? data[0].value : null;
                        }
                        if (data && typeof data === 'object') {
                            return data.value;
                        }
                        return data;
                    },
                },
                intervalUnit: {
                    hostClass: 'col-33',
                    controlType: 'control',
                    defaultValue: null,
                    placeholder: 'aftercare.predefinedEvents.addEventModal.form.selectTypePlaceholder',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    label: '',
                    disabled: true,
                    validationRules: [],
                    multiselectOptions: recommendationFrequencyUnits,
                    menuPlacement: MultiselectMenuPlacement.TOP,
                    inputDataMapper: (data: any) => {
                        if (!data) {
                            return [];
                        }

                        return addAftercareFormConfig.controls[5].controls.intervalUnit.multiselectOptions
                            .filter((interval: any) => interval.value === data)
                            .map((intervalUnit: any) => ({
                                value: intervalUnit.value,
                                label: intervalUnit.label,
                            }));
                    },
                    outputDataMapper: (data: any) => {
                        if (Array.isArray(data)) {
                            return (data.length > 0) ? data[0].value : null;
                        }
                        if (data && typeof data === 'object') {
                            return data.value;
                        }
                        return data;
                    },
                }
            }
        },
        {
            controlType: 'group',
            key: 'recommendation_submit',
            class: 'form-row',
            controls: {
                submitButton: {
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    hostClass: 'col-100 mb-0',
                    defaultContainerStyles: '',
                    containerStyles: 'button-container mt-2',
                    defaultValue: null,
                    btnText: 'aftercare.predefinedEvents.addEventButton',
                    buttonDisabled: (mappedOutputValue: any, isFormValid: boolean, isFormTouched: boolean) => {
                        if (mappedOutputValue.repeatEvent === 'single' && isFormValid) {
                            return false;
                        }

                        if (mappedOutputValue.repeatEvent === 'consecutive') {
                            return !(mappedOutputValue.consecutiveType &&
                                mappedOutputValue.consecutiveValue &&
                                mappedOutputValue.intervalUnit &&
                                mappedOutputValue.intervalValue);

                        }
                        return !isFormValid;
                    }
                },
            },
        },
    ]
};

function getDefaultIntervalOptions(cycleValue: number, cycleStartValue?: number) {
    let options: typeof IMultiselectOption[] = [];
    let startValue = cycleStartValue ? cycleStartValue : 1;
    for (let step = startValue; step <= cycleValue; step++) {
        options.push({
            value: step,
            label: step
        })
    }
    return options;
}
