import { RootState } from "../reducers";
import { createSelector } from "redux-starter-kit";
import {IClinicListBaseState} from "./clinicListSelectors";

export interface IClinicItemState extends IClinicListBaseState {
  retrieved: any; // object
  eventSource: EventSource;
}

export interface IDeleteClinicItemState extends IClinicListBaseState {
  deleted: any; // model for inquiry
}

export interface ICreateClinicItemState extends IClinicListBaseState {
  created: any; // model for inquiry
}

export interface IUpdateClinicItemState {
  retrieved: any; // model for inquiry
  retrieveError: string;
  retrieveLoading: boolean;
  updateError: string;
  updateLoading: boolean;
  eventSource: EventSource;
  updated: any; // model for inquiry
}

export const selectClinicItem = (state: RootState): IClinicItemState => {
  return state.clinicList.show;
};

export const selectDeleteInquiryItem = (state: RootState): IDeleteClinicItemState => {
  return state.clinicList.del;
};

export const selectCreateClinicItem = (state: RootState): ICreateClinicItemState => {
  return state.clinicList.create;
};

export const selectUpdateClinicItem = (state: RootState): IUpdateClinicItemState => {
  return state.clinicList.update;
};

export const retrievedClinicItemSelector = createSelector(
  [selectClinicItem],
  (state: IClinicItemState) => state.retrieved
);

export const clinicItemLoadingSelector = createSelector(
  [selectClinicItem],
  (state: IClinicItemState) => state.loading
);

export const clinicItemErrorSelector = createSelector(
  [selectClinicItem],
  (state: IClinicItemState) => state.error
);

export const clinicItemEventSourceSelector = createSelector(
 [selectClinicItem],
 (state: IClinicItemState) => state.eventSource
);

export const clinicItemDeleteErrorSelector = createSelector(
  [selectDeleteInquiryItem],
  (state: IDeleteClinicItemState) => state.error
);

export const clinicItemDeleteLoadingSelector = createSelector(
  [selectDeleteInquiryItem],
  (state: IDeleteClinicItemState) => state.loading
);

export const clinicItemDeletedSelector = createSelector(
  [selectDeleteInquiryItem],
  (state: IDeleteClinicItemState) => state.deleted
);

export const clinicItemCreateErrorSelector = createSelector(
  [selectCreateClinicItem],
  (state: ICreateClinicItemState) => state.error
);

export const clinicItemCreateLoadingSelector = createSelector(
  [selectCreateClinicItem],
  (state: ICreateClinicItemState) => state.loading
);

export const clinicItemCreatedSelector = createSelector(
  [selectCreateClinicItem],
  (state: ICreateClinicItemState) => state.created
);

export const clinicItemUpdateRetrievedSelector = createSelector(
  [selectUpdateClinicItem],
  (state: IUpdateClinicItemState) => state.retrieved
);

export const clinicItemUpdateRetrieveErrorSelector = createSelector(
  [selectUpdateClinicItem],
  (state: IUpdateClinicItemState) => state.retrieveError
);

export const clinicItemUpdateRetrieveLoadingSelector = createSelector(
  [selectUpdateClinicItem],
  (state: IUpdateClinicItemState) => state.retrieveLoading
);

export const clinicItemUpdateErrorSelector = createSelector(
  [selectUpdateClinicItem],
  (state: IUpdateClinicItemState) => state.updateError
);

export const clinicItemUpdateLoadingSelector = createSelector(
  [selectUpdateClinicItem],
  (state: IUpdateClinicItemState) => state.updateLoading
);

export const clinicItemUpdatedSelector = createSelector(
  [selectUpdateClinicItem],
  (state: IUpdateClinicItemState) => state.updated
);

export const clinicItemUpdateEventSourceSelector = createSelector(
  [selectUpdateClinicItem],
  (state: IUpdateClinicItemState) => state.eventSource
);
