import React, {Component} from 'react';
import {
    BasicModal,
    CustomCard,
    CustomCardType,
    Form,
    FormControlChangeType,
    IFormConfig,
    RestQueryParams,
    Translation
} from "common-web";
import {fixInjectedProperties, lazyInject} from "../../../../ioc";
import {IAlertManagerService} from "../../../../service/alertManagerService";
import {BehaviorSubject, of, Subscription} from "rxjs";
import {editInsuranceFormConfig, originSelectOptions, SourceValues} from "./editInsuranceFormConfig";
import {catchError, filter, switchMap, tap} from "rxjs/operators";
import moment from "moment/moment";
import {InquirySubjectFactory} from "./inquirySubjectFactory";
import {IInquiryPatient, IInquirySubject, updateInquiryAPI} from "../../../../api/editInquiryData";
import {changeInquiryAPI} from "../../../../api/updateInquiryAPI";

interface IEditInsuranceReportProps {
    readonly authToken: string | null;
    readonly isModalShown: boolean;
    readonly toggleModal: (inquiry?: {[key: string]: any}) => void;
    readonly inquiry: {[key: string]: any} | null;
    readonly getInquiryList: (searchParams: typeof RestQueryParams) => void;
}

interface IEditInsuranceReportState {
    isLoading: boolean;
    formConfig: typeof IFormConfig;
    value: {[key: string]: any} | null;
    isCustomSourceField: boolean;
}


class EditInsuranceReport extends Component<IEditInsuranceReportProps, IEditInsuranceReportState> {
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    private subscriptions: Subscription[] = [];

    constructor(props: IEditInsuranceReportProps) {
        super(props);

        this.state = {
            isLoading: false,
            formConfig: null,
            value: null,
            isCustomSourceField: false,
        };

        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.setFormConfig(this.state.value);
        this.setDefaultFormValues();

        this.subscriptions.push(
            this.onValueStateChange$.pipe(
                filter((data: any) => data && data.changeType === FormControlChangeType.User),
                tap((data: any) => this.onFormValueChange(data.value)),
            ).subscribe()
        );
    }

    componentDidUpdate(prevProps: Readonly<IEditInsuranceReportProps>) {
        if (this.props.inquiry !== prevProps.inquiry && this.props.inquiry) {
            this.setDefaultFormValues();
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }


    render() {
        return (
            <BasicModal isModalShown={this.props.isModalShown} closeModal={this.props.toggleModal}>
                <CustomCard showLocalLoader={this.state.isLoading} type={CustomCardType.MODAL_CARD}>
                    <CustomCard.Body>
                        <div className="modal-header">
                            <Translation text={'insuranceClinicReport.editReport.title'}/>
                            <button className="btn-modal-close" onClick={() => this.props.toggleModal()}>
                                <span className="feather icon-x"/>
                            </button>
                        </div>
                        <div className="modal-body">
                            <Form config={this.state.formConfig}
                                  onValueStateChange={this.onValueStateChange}
                                  submitForm={this.editInsurance}
                                  value={this.state.value}
                                  controlName={'addTreatmentCategoryForm'}/>
                        </div>
                    </CustomCard.Body>
                </CustomCard>
            </BasicModal>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private editInsurance = () => {
        if (this.props.inquiry && this.state.value && this.props.authToken) {
            this.setState({isLoading: true});
            const inquirySubjectsArray: IInquirySubject[] | [] = (new InquirySubjectFactory()).createSubjects(this.state.value, this.props.inquiry),
                patientData: IInquiryPatient = {
                    account: {
                        user: {
                            login: this.state.value.email
                        }
                    }
                }

            return(
                this.subscriptions.push(
                    updateInquiryAPI(this.props.authToken, this.props.inquiry.id, inquirySubjectsArray, patientData).pipe(
                        switchMap((response: any) => {
                            if (this.state.value && this.props.authToken) {
                                const source = this.state.value && this.state.value.source === SourceValues.OTHER ?
                                    this.state.value.customSource : this.state.value.source;

                                return changeInquiryAPI(
                                    response.id,
                                    this.props.authToken,
                                    response.from,
                                    response.to,
                                    response.treatmentCategory.id,
                                    response.destination,
                                    response.inquirySubjects,
                                    response?.clinic?.id,
                                    response.clinicName,
                                    response.patientEmail,
                                    this.state.value.visitDate,
                                    source,
                                    response.settledWithClinic,
                                    true,
                                    null,
                                ).pipe(
                                    tap(() => {
                                        this.setState({isLoading: false});
                                        this.props.toggleModal();
                                        this.alertManager.addAlert('insuranceClinicReport.editReport.editedSuccessMessage');
                                        this.props.getInquiryList(new RestQueryParams());
                                    }),
                                    catchError((error: any) => {
                                        this.setState({isLoading: false});
                                        this.alertManager.handleApiError(error);
                                        return of();
                                    })
                                )
                            }

                           return of();
                        }),
                        catchError((error: any) => {
                            this.setState({isLoading: false});
                            this.alertManager.handleApiError(error);
                            return of();
                        })
                    ).subscribe()
                )
            )
        }
    }

    private onFormValueChange = (value: any) => {
        const mappedValue: any = {};
        Object.keys(value).forEach((key: string) => {
            let fieldValue = value[key];

            if (undefined === fieldValue || null === fieldValue) {
                mappedValue[key] = null;

                return;
            }

            if (key === 'source') {
                if (value[key] === SourceValues.OTHER && !this.state.isCustomSourceField) {
                    this.setState({isCustomSourceField: true});
                    this.setFormConfig(value);
                }

                if (value[key] !== SourceValues.OTHER && this.state.isCustomSourceField) {
                    this.setState({isCustomSourceField: false});
                    this.setFormConfig(value);
                }
            }
            mappedValue[key] = fieldValue;
        })

        this.setState({value: mappedValue});
    };

    private setFormConfig = (value: any) => {
        const companionPatients = this.props.inquiry?.inquirySubjects?.filter((subject: any) => subject.main !== true),
            formConfig = editInsuranceFormConfig(companionPatients, value);
        this.setState({formConfig});
    }

    private setDefaultFormValues = () => {
        const mainPatient = this.props.inquiry?.inquirySubjects?.filter((subject: any) => subject.main === true)[0],
            companionPatient = this.props.inquiry?.inquirySubjects?.filter((subject: any) => subject.main !== true)[0];

        const values = {
            birthDate: mainPatient?.birthDate ? moment(mainPatient?.birthDate).format('YYYY-MM-DD') : null,
            companionBirthDate: companionPatient?.birthDate ? moment(companionPatient?.birthDate).format('YYYY-MM-DD') : null,
            companionLastName: companionPatient?.lastName,
            companionName: companionPatient?.firstName,
            email: this.props.inquiry?.patientEmail ? this.props.inquiry.patientEmail :
                this.props.inquiry?.patient?.account?.user?.login,
            lastName: mainPatient?.lastName,
            name: mainPatient?.firstName,
            visitDate: this.props.inquiry?.visitDate ? moment(this.props.inquiry?.visitDate).format('YYYY-MM-DD') : null,
            source: this.props.inquiry?.source === null ? null :
                originSelectOptions.some(x => x.value === this.props.inquiry?.source) ? this.props.inquiry?.source : SourceValues.OTHER,
            customSource: originSelectOptions.some(x => x.value !== this.props.inquiry?.source) ? this.props.inquiry?.source : null,
        }

        if (!originSelectOptions.some(x => x.value === this.props.inquiry?.source)) {
            this.setState({isCustomSourceField: true});
            this.setFormConfig(values);
        }
        this.setState({value: values});
    }
}


export default EditInsuranceReport;
