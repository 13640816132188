import {FormControlType, IFormConfig, InputType, ValidationRules} from 'common-web';

export const eventTypeFormConfig: typeof IFormConfig = {
    controlType: 'group',
    class: '',
    dataAccessor: (data: any) => data, // data is not grouped
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);

        return previousStateSnapshot;
    },
    controls: [
        {
            controlType: 'group',
            key: 'eventType',
            class: 'form-row',
            controls: {
                eventType: {
                    hostClass: 'col-100 d-flex',
                    controlType: 'control',
                    // defaultValue: null,
                    formControlType: FormControlType.RADIO,
                    options: [
                        {value: 'travel_plan', displayValue: 'Travel Plan'},
                        {value: 'after_care', displayValue: 'Aftercare'}
                    ],
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                    ],
                    placeholder: 'aftercare.predefinedEvents.addEventModal.form.eventOccurrence',
                    label: 'aftercare.predefinedEvents.addEventModal.form.eventOccurrence',
                    type: InputType.RADIO,
                }
            }
        }
    ]
};
