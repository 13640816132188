import React from "react";
import {
    RichTextEditorComponent,
    Toolbar,
    Inject,
    HtmlEditor,
    Count,
    QuickToolbar,
    FormatModel
} from "@syncfusion/ej2-react-richtexteditor";

interface IRichTextComponentProps {
    value: string | undefined;
}

export class RichTextComponent extends React.Component<IRichTextComponentProps, any>  {
    private items: string[];
    private toolbarSettings: any;
    private rteObj: any;
    private format: FormatModel | undefined;

    constructor(props: IRichTextComponentProps) {
        super(props);
        // Rich Text Editor menu items list
        this.items = [
            "Formats",
            "UnorderedList",
        ];

        //Rich Text Editor ToolbarSettings
        this.toolbarSettings = {
            items: this.items
        };
        this.format = {
            default: 'Paragraph',
            width:'7rem',
            types: [
                { text: 'Paragraph', value: 'p', cssClass: 'e-paragraph' },
                { text: 'Heading 1', value: 'h1', cssClass: 'e-h2' }
                ]
        };
    }

    render() {
        return (
            <div className="control-pane">
                <div className="control-section" id="rteTools">
                    <div className="rte-control-section">
                        <RichTextEditorComponent
                            id="toolsRTE"
                            value={this.props.value}
                            ref={richtexteditor => {
                                this.rteObj = richtexteditor;
                            }}
                            showCharCount={true}
                            actionComplete={this.actionCompleteHandler.bind(this)}
                            maxLength={2000}
                            toolbarSettings={this.toolbarSettings}
                            format={this.format}
                        >
                            <div />
                            <Inject
                                services={[
                                    Toolbar,
                                    HtmlEditor,
                                    Count,
                                    QuickToolbar
                                ]}
                            />
                        </RichTextEditorComponent>
                    </div>
                </div>
            </div>
        );
    }

    private actionCompleteHandler(e: any) {
        if (
            e.targetItem &&
            (e.targetItem === "SourceCode" || e.targetItem === "Preview")
        ) {
            this.rteObj.sourceCodeModule.getPanel().style.display = "none";
        } else {
            setTimeout(() => {
                this.rteObj.toolbarModule.refreshToolbarOverflow();
            }, 400);
        }
    }
}

export default RichTextComponent;
