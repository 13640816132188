import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {
  authTokenSelector,
  CurrencyConverter,
  CustomCard,
  DateConverter,
  CustomPagination,
  RestQueryParams,
  Translation,
  formatDateToString
} from "common-web";
import {fixInjectedProperties, lazyInject} from "../../ioc";
import {connect} from "react-redux";
import {RootState} from "../../store/reducers";
import {DateRangePicker} from "rsuite";
import moment from 'moment';
import {isNullOrUndefined} from "../../utils/runtimeUtils";
import {IAlertManagerService} from "../../service/alertManagerService";
import {list, reset} from "../../actions/consultation/list";
import {WithTranslation, withTranslation} from "react-i18next";
import {
  consultationListErrorSelector,
  consultationListLoadingSelector,
  retrievedConsultationsListSelector,
  consultationListEventSourceSelector
} from "../../store/selectors/consultationListSelectors";
// import InsuranceReportDownload from '../InsuranceReportDownload';
// import SelectClinicInput from './SelectClinicInput';

const datepickerRanges: any[] = [
  {
    label: 'Previous Month',
    value: [
      new Date(moment().subtract(1, 'months').startOf('month').format()),
      new Date(moment().subtract(1, 'months').endOf('month').format())
    ]
  },
  {
    label: 'last7Days',
    value: [
      new Date(moment().subtract(7, 'days').format()),
      new Date(moment().endOf('day').format())]
  },
  {
    label: 'today',
    value: [
      new Date(moment().startOf('day').format()),
      new Date(moment().endOf('day').format())
    ]
  },
  {
    label: 'Current Month',
    value: [
      new Date(moment().startOf('month').format()),
      new Date(moment().endOf('month').format())
    ]
  },
];

interface IConnectedConsultationsProps {
  readonly retrieved: {[key: string]: any};
  readonly loading: boolean;
  readonly error: string | null;
  readonly eventSource: EventSource;
  readonly list: any;
  readonly reset: any;
  readonly authToken: string;
}

interface IExternalConsultationsProps {}

interface IConsultationsProps extends
  IConnectedConsultationsProps,
  IExternalConsultationsProps,
  RouteComponentProps,
  WithTranslation {}

interface IConsultationsState {
  isLoading: boolean;
  defaultDateValue: any;
  reportStartDate: string;
  reportEndDate: string;
  reportInsuranceNumber: string | null;
  // todo move commisions to connected props
  consultationsClinicCommissionTotal: {[key: string]: any};
  consultationsClinicHunterCommissionTotal: {[key: string]: any};
}

class Consultations extends React.Component<IConsultationsProps, IConsultationsState> {
  @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

  constructor(props: IConsultationsProps) {
    super(props);

    let date = new Date();
    const defaultStartValue = new Date(date.getFullYear(), date.getMonth(), 1),
      defaultEndValue = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    this.state = {
      isLoading: false,
      defaultDateValue: [defaultStartValue, defaultEndValue],
      reportStartDate: this.getTimeString(defaultStartValue),
      reportEndDate: this.getTimeString(defaultEndValue),
      reportInsuranceNumber: null,
      consultationsClinicCommissionTotal: {
        amount: "2539",
        currency: {code: "EUR"}
        },
      consultationsClinicHunterCommissionTotal:  {
        amount: "2539",
        currency: {code: "EUR"}
      },
    };
    fixInjectedProperties(this);
  }


  componentDidMount() {
  }

  componentDidUpdate(
    prevProps: Readonly<IConsultationsProps>,
    prevState: Readonly<IConsultationsState>,
    snapshot?: any
  ): void {
    if (this.props.error !== prevProps.error) {
      this.alertManager.handleApiError(this.props.error);
    }
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  render() {
    const {t} = this.props;
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-xl-12">
            <div className="view-header">
              <div className="view-title">
                <Translation text={'consultations.title'}/>
              </div>
            </div>
          </div>
        </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="filter-container">
                {/*<SelectClinicInput/>*/}


                  <div className="group-control mr-4">
                      <DateRangePicker placeholder={t('insuranceClinicReport.selectDate')}
                                       onChange={this.handleDateChange}
                                       placement="autoVerticalStart"
                                       ranges={datepickerRanges}
                                       defaultValue={this.state.defaultDateValue}
                                       renderValue={(value) => {
                                         return `${formatDateToString(value[0])} - ${formatDateToString(value[1])}`;
                                       }}
                      />
                  </div>



                {/*<InsuranceReportDownload authToken={this.props.authToken}*/}
                {/*                         reportStartDate={this.state.reportStartDate}*/}
                {/*                         reportEndDate={this.state.reportEndDate} />*/}
              </div>
            </div>
          </div>
          <div className="row">
              <div className="col-xl-12">
                <CustomCard showLocalLoader={this.props.loading}>
                  <CustomCard.Body>
                    {this.renderInsuranceList()}

                    <CustomPagination retrieved={this.props.retrieved}
                                      basePath="dashboard"
                                      path="consultations"
                                      provider={this.getInquiryList}/>
                  </CustomCard.Body>
                </CustomCard>
              </div>
          </div>
      </React.Fragment>
    );
  }

  private renderInsuranceList = () => {
    if (!this.props.retrieved || !this.props.retrieved['hydra:member'] ||
      !Array.isArray(this.props.retrieved['hydra:member']) || !this.props.retrieved['hydra:member'].length) {
      return (
        <p>
          <Translation text={'insuranceClinicReport.noReportData'}/>
        </p>
      );
    }

    return (
      <React.Fragment>
        <table className="data-table">
          <thead>
            <tr>
              <th><Translation text={'consultations.table.headers.bookingDate'}/></th>
              <th><Translation text={'consultations.table.headers.consultationDate'}/></th>
              <th><Translation text={'consultations.table.headers.duration'}/></th>
              <th><Translation text={'consultations.table.headers.patientName'}/></th>
              <th><Translation text={'consultations.table.headers.patientEmail'}/></th>
              <th><Translation text={'consultations.table.headers.clinic'}/></th>
              <th><Translation text={'consultations.table.headers.calendarName'}/></th>
              <th><Translation text={'consultations.table.headers.price'}/></th>
              <th className="text-center"><Translation text={`consultations.table.headers.status`}/></th>
            </tr>
          </thead>
          <tbody>
            {this.renderTableRows()}
          </tbody>
        </table>
      </React.Fragment>
    )
  };

  private renderTableRows() {
    const rows: any[] = [],
      list = this.props.retrieved['hydra:member'].sort(this.sortMethod),
      filteredList = list.filter((item: any) => item.status !== 'cancelled');

    filteredList.map((item: any) => {
      const clinic = item.clinic,
          calendar = item.calendar;

      return rows.push(
        <tr key={item.id}>
          {item.paidAt ? <td><DateConverter date={item.paidAt}/></td> : <td>-</td>}
          <td>{this.renderConsultationTime(item.startsAt, item.endsAt)}</td>
          <td>{this.getConsultationDuration(item.startsAt, item.endsAt)}</td>
          <td>--</td>
          <td>--</td>
          <td className="align-middle">{clinic ? clinic.companyName : '---'}</td>
          <td className="align-middle">{calendar ? calendar.name : '---'}</td>
          <td className="align-middle">{calendar?.price ? (<CurrencyConverter price={calendar.price}/>) : '---'}</td>
          <td className="text-center align-middle">
            <div className={`status-wrapper consultation-${item.status}`}>
              <p className="status">
                <Translation text={`consultations.statuses.${item.status}`}/>
              </p>
            </div>
          </td>
        </tr>
      )
    });

    return rows;
  }

  private handleDateChange = (e: any) => {
    if (!e.length || isNullOrUndefined(e)) {
      return;
    }

    const startDate = this.getTimeString(e[0]),
      endDate = this.getTimeString(e[1]);
    this.props.list(
      `online_consultations?page=1&from[after]=${startDate}&to[before]=${endDate}`,
      this.props.authToken,
    );

    this.setState({
      reportStartDate: startDate,
      reportEndDate: endDate
    })
  };


  private getInquiryList = (searchParams: typeof RestQueryParams) => {
    searchParams = searchParams.add('order[startsAt]', 'DESC');

    this.props.list(
     `online_consultations${searchParams.prepareQuery()}`,
      this.props.authToken
    );
  };

  private getTimeString(value: Date): string {
    return new Date(value.getTime() - (value.getTimezoneOffset() * 60000)).toISOString().split('T')[0];
  }

  private sortMethod(a: any, b: any): number {
    const aDate = new Date(a.startsAt),
          bDate = new Date(b.startsAt),
          aTime = aDate.getTime(),
          bTime = bDate.getTime();

    return aTime > bTime ? -1 : aTime !== bTime ? 1 : 0;
  }

  private renderConsultationTime = (startsAt: Date, endsAt: Date) => {
    return (
        <React.Fragment>
          <span className="d-block">{moment(startsAt).format('YYYY-MM-DD')}</span>
          <span className="d-block font-weight-bold">{moment(startsAt).format('HH:mm')} - {moment(endsAt).format('HH:mm')} </span>
        </React.Fragment>
    )
  };

  private getConsultationDuration = (startsAt: Date, endsAt: Date) => {
    const duration = Math.round(moment.duration(moment(endsAt).diff(moment(startsAt))).asMinutes());
    return (
        <p>{duration} <Translation text={'consultations.list.durationMinutes'}/></p>

    )
  };
}

export default withTranslation()(connect(
  (state: RootState) => ({
    retrieved: retrievedConsultationsListSelector(state),
    loading: consultationListLoadingSelector(state),
    error: consultationListErrorSelector(state),
    eventSource: consultationListEventSourceSelector(state),
    authToken: authTokenSelector(state),
  }),
    {
    list,
    reset,
  }
)(withRouter(Consultations)));
