import { RootState } from "../reducers";
import { createSelector } from "redux-starter-kit";
import {ISubscriptionDefinitionBaseState} from "./subscriptionDefinitionListSelectors";

export interface ISubscriptionDefinitionItemState extends ISubscriptionDefinitionBaseState {
    retrieved: any; // object
    eventSource: EventSource;
}

export interface IDeleteSubscriptionDefinitionItemState extends ISubscriptionDefinitionBaseState {
    deleted: any; // model for subscription
}

export interface ICreateSubscriptionDefinitionItemState extends ISubscriptionDefinitionBaseState {
    created: any; // model for subscription
}

export interface IUpdateSubscriptionDefinitionItemState {
    retrieved: any; // model for subscription
    retrieveError: string;
    retrieveLoading: boolean;
    updateError: string;
    updateLoading: boolean;
    eventSource: EventSource;
    updated: any; // model for subscription
}

export const selectSubscriptionDefinitionItem = (state: RootState): ISubscriptionDefinitionItemState => {
    return state.subscriptionDefinition.show;
};

export const selectDeleteSubscriptionDefinitionItem = (state: RootState): IDeleteSubscriptionDefinitionItemState => {
    return state.subscriptionDefinition.del;
};

export const selectCreateSubscriptionDefinitionItem = (state: RootState): ICreateSubscriptionDefinitionItemState => {
    return state.subscriptionDefinition.create;
};

export const selectUpdateSubscriptionDefinitionItem = (state: RootState): IUpdateSubscriptionDefinitionItemState => {
    return state.subscriptionDefinition.update;
};

export const retrievedSubscriptionDefinitionItemSelector = createSelector(
    [selectSubscriptionDefinitionItem],
    (state: ISubscriptionDefinitionItemState) => state.retrieved
);

export const subscriptionDefinitionItemLoadingSelector = createSelector(
    [selectSubscriptionDefinitionItem],
    (state: ISubscriptionDefinitionItemState) => state.loading
);

export const subscriptionDefinitionItemErrorSelector = createSelector(
    [selectSubscriptionDefinitionItem],
    (state: ISubscriptionDefinitionItemState) => state.error
);

export const subscriptionDefinitionItemEventSourceSelector = createSelector(
    [selectSubscriptionDefinitionItem],
    (state: ISubscriptionDefinitionItemState) => state.eventSource
);

export const subscriptionDefinitionItemDeleteErrorSelector = createSelector(
    [selectDeleteSubscriptionDefinitionItem],
    (state: IDeleteSubscriptionDefinitionItemState) => state.error
);

export const subscriptionDefinitionItemDeleteLoadingSelector = createSelector(
    [selectDeleteSubscriptionDefinitionItem],
    (state: IDeleteSubscriptionDefinitionItemState) => state.loading
);

export const subscriptionDefinitionItemDeletedSelector = createSelector(
    [selectDeleteSubscriptionDefinitionItem],
    (state: IDeleteSubscriptionDefinitionItemState) => state.deleted
);

export const subscriptionDefinitionItemCreateErrorSelector = createSelector(
    [selectCreateSubscriptionDefinitionItem],
    (state: ICreateSubscriptionDefinitionItemState) => state.error
);

export const subscriptionDefinitionItemCreateLoadingSelector = createSelector(
    [selectCreateSubscriptionDefinitionItem],
    (state: ICreateSubscriptionDefinitionItemState) => state.loading
);

export const subscriptionDefinitionItemCreatedSelector = createSelector(
    [selectCreateSubscriptionDefinitionItem],
    (state: ICreateSubscriptionDefinitionItemState) => state.created
);

export const subscriptionDefinitionItemUpdateRetrievedSelector = createSelector(
    [selectUpdateSubscriptionDefinitionItem],
    (state: IUpdateSubscriptionDefinitionItemState) => state.retrieved
);

export const subscriptionDefinitionItemUpdateRetrieveErrorSelector = createSelector(
    [selectUpdateSubscriptionDefinitionItem],
    (state: IUpdateSubscriptionDefinitionItemState) => state.retrieveError
);

export const subscriptionDefinitionItemUpdateRetrieveLoadingSelector = createSelector(
    [selectUpdateSubscriptionDefinitionItem],
    (state: IUpdateSubscriptionDefinitionItemState) => state.retrieveLoading
);

export const subscriptionDefinitionItemUpdateErrorSelector = createSelector(
    [selectUpdateSubscriptionDefinitionItem],
    (state: IUpdateSubscriptionDefinitionItemState) => state.updateError
);

export const subscriptionDefinitionItemUpdateLoadingSelector = createSelector(
    [selectUpdateSubscriptionDefinitionItem],
    (state: IUpdateSubscriptionDefinitionItemState) => state.updateLoading
);

export const subscriptionDefinitionItemUpdatedSelector = createSelector(
    [selectUpdateSubscriptionDefinitionItem],
    (state: IUpdateSubscriptionDefinitionItemState) => state.updated
);

export const subscriptionDefinitionItemUpdateEventSourceSelector = createSelector(
    [selectUpdateSubscriptionDefinitionItem],
    (state: IUpdateSubscriptionDefinitionItemState) => state.eventSource
);
