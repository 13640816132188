import React from 'react';
import {
    BasicModal,
    CustomCard,
    CustomCardType,
    Form,
    IFormConfig,
    Translation,
    RestQueryParams
} from "common-web";
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {IAlertManagerService} from "../../../service/alertManagerService";
import {catchError, map, tap} from "rxjs/operators";
import {of, Subscription} from "rxjs";
import {addTreatmentCategoryAPI, ITreatmentCategoryTranslation} from "../../../api/addTreatmentCategory";
import {addTreatmentCategoryFormConfig} from "./../formConfig";
import {editTreatmentCategoryAPI} from "../../../api/updateTreatmentCategory";


interface IConnectedAddTreatmentCategoryProps {
}

interface IExternalAddTreatmentCategoryProps {
    readonly retrieved: any;
    readonly error: string;
    readonly authToken: string;
    readonly selectedTreatmentCategory: {[key: string]: any} | null;
    readonly addModalShown: boolean;
    readonly toggleAddModal: (item?: { [key: string]: any }) => void;
    readonly closeAddModal: () => void;
    readonly updateTreatmentCategories: (searchParams: typeof RestQueryParams) => void;
}

interface IAddTreatmentCategoryProps extends IConnectedAddTreatmentCategoryProps,
    IExternalAddTreatmentCategoryProps {
}

interface IAddTreatmentCategoryState {
    addModalLoading: boolean;
    formConfig: typeof IFormConfig;
    value: any;
}

class AddTreatmentCategory extends React.Component<IAddTreatmentCategoryProps, IAddTreatmentCategoryState> {
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

    private subscription: Subscription | null = null;

    constructor(props: IAddTreatmentCategoryProps) {
        super(props);

        this.state = {
            addModalLoading: false,
            formConfig: null,
            value: null
        };

        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.setFormConfig();
        if (this.props.selectedTreatmentCategory) {
            this.setDefaultFormValues(this.props.selectedTreatmentCategory);
        }
    }

    componentDidUpdate(
        prevProps: Readonly<IAddTreatmentCategoryProps>,
        prevState: Readonly<{}>,
        snapshot?: any
    ): void {
        if (this.props.error !== prevProps.error) {
            this.alertManager.handleApiError(this.props.error);
        }

        if (this.props.selectedTreatmentCategory !== prevProps.selectedTreatmentCategory) {
            this.setFormConfig();

            if (this.props.selectedTreatmentCategory !== null) {
                this.setDefaultFormValues(this.props.selectedTreatmentCategory);
            }
        }
    }

    componentWillUnmount() {
        if (null !== this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    render() {
        return (
            <BasicModal isModalShown={this.props.addModalShown} closeModal={() => {
                this.setState({value: null});
                this.props.closeAddModal();
            }}>
                <CustomCard showLocalLoader={this.state.addModalLoading} type={CustomCardType.MODAL_CARD}>
                    <CustomCard.Body>
                        <div className="modal-header">
                            {this.props.selectedTreatmentCategory ?
                                <Translation text={'treatmentCategories.editModal.title'}/> :
                                <Translation text={'treatmentCategories.addModal.title'}/>
                            }
                            <button className="btn-modal-close" onClick={() => this.props.toggleAddModal()}>
                                <span className="feather icon-x"/>
                            </button>
                        </div>
                        <div className="modal-body">
                            <Form config={this.state.formConfig}
                                  submitForm={this.addCategory}
                                  value={this.state.value}
                                  controlName={'addTreatmentCategoryForm'}/>
                        </div>
                    </CustomCard.Body>
                </CustomCard>
            </BasicModal>
        );
    }

    private addCategory = (event: any, value: any, valid: boolean, touched: boolean): void => {
        if (!valid || !touched) {
            return;
        }

        this.setState({addModalLoading: true});
        const nameTranslation: ITreatmentCategoryTranslation = {
            en: value.enTranslation,
            pl: value.plTranslation
        };

        if (this.props.selectedTreatmentCategory) {
            this.subscription = this.handleEditTreatmentCategoryAPI(this.props.selectedTreatmentCategory.id, value.name, nameTranslation).subscribe();
        } else {
            this.subscription = this.handleAddTreatmentCategoryAPI(value.name, nameTranslation).subscribe();
        }
    };

    private handleAddTreatmentCategoryAPI(name: string, nameTranslation: ITreatmentCategoryTranslation) {
        return addTreatmentCategoryAPI(
            this.props.authToken,
            name,
            nameTranslation
        ).pipe(
            map((category: { [key: string]: any }) => {
                let treatmentCategories;
                let treatmentCategoriesState = this.props.retrieved;

                if (this.props.retrieved) {
                    treatmentCategories = [...this.props.retrieved['hydra:member']];
                    if (treatmentCategories) {
                        treatmentCategories.push(category);
                    }
                }

                const modifiedTreatmentCategories = Object.assign({}, treatmentCategoriesState, {'hydra:member': treatmentCategories});

                this.alertManager.addAlert('Treatment type was successfully added');
                return modifiedTreatmentCategories;
            }),
            tap((categories: any) => {
                this.setState({
                    addModalLoading: false,
                });
                this.props.updateTreatmentCategories(categories);
                this.props.closeAddModal();
            }),
            catchError((error: any) => {
                const message = error ? error.message : 'Something went wrong';
                this.setState({addModalLoading: false});
                this.alertManager.addAlert(message);
                return of();
            })
        );
    }

    private handleEditTreatmentCategoryAPI(categoryId: string, name: string, nameTranslation: ITreatmentCategoryTranslation) {
        return editTreatmentCategoryAPI(
            this.props.authToken,
            categoryId,
            name,
            nameTranslation
        ).pipe(
            map(() => {
                this.alertManager.addAlert('Treatment type was successfully updated');
            }),
            tap(() => {
                this.setState({
                    addModalLoading: false,
                });

                let params = {
                    page: 1,
                    itemsPerPage: 10
                }
                this.props.updateTreatmentCategories(new RestQueryParams(params));
                this.props.closeAddModal();
            }),
            catchError((error: any) => {
                const message = error ? error.message : 'Something went wrong';
                this.setState({addModalLoading: false});
                this.alertManager.addAlert(message);
                return of();
            })
        );
    }

    private setDefaultFormValues = (category: {[key: string]: any}) => {
        this.setState({
            value: {
                name: category.name,
                enTranslation: category.nameTranslations?.en ? category.nameTranslations?.en : null,
                plTranslation: category.nameTranslations?.pl ? category.nameTranslations.pl : null,
            }
        });
    }

    private setFormConfig = () => {
        const isFormEdit = this.props.selectedTreatmentCategory !== null;
        this.setState({formConfig: addTreatmentCategoryFormConfig(isFormEdit)})
    }
}

export default AddTreatmentCategory;
