import {
    FormButtonType,
    FormControlType,
    IFormConfig,
    InputType,
    ValidationRules
} from 'common-web';

export const addSubscriptionFormConfig: typeof IFormConfig = {
    controlType: 'group',
    class: '',
    dataAccessor: (data: any) => data, // data is not grouped
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);

        return previousStateSnapshot;
    },
    controls: [
        {
            controlType: 'group',
            key: 'planName',
            class: 'form-row custom-row',
            controls: {
                planName: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                    ],
                    placeholder: 'subscriptionPlans.form.planName',
                    label: 'subscriptionPlans.form.planName',
                    type: InputType.TEXT,
                    hostClass: 'col-50'
                },
                planPrice: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    placeholder: 'subscriptionPlans.form.planPrice',
                    label: 'subscriptionPlans.form.planPrice',
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                    ],
                    type: InputType.NUMBER,
                    hostClass: 'col-50'
                }
            }
        },
        // {
        //     controlType: 'group',
        //     key: 'reviews',
        //     class: 'form-row custom-row',
        //     controls: {
        //         reviews: {
        //             controlType: 'control',
        //             defaultValue: false,
        //             formControlType: FormControlType.SWITCH,
        //             // validationRules: [{name: ValidationRules.IS_TRUE, params: {}}],
        //             placeholder: '',
        //             label: 'subscriptionPlans.form.reviews',
        //             outputDataMapper: (data: any) => {
        //                 if (data === 'true' || data === 'on') {
        //                     return true;
        //                 }
        //                 if (data === 'false') {
        //                     return false;
        //                 }
        //
        //                 return data;
        //             },
        //             hostClass: 'col-xl-12 d-flex flex-row justify-content-between mt-3'
        //         }
        //     }
        // },
        // {
        //     controlType: 'group',
        //     key: 'aftercare',
        //     class: 'form-row custom-row',
        //     controls: {
        //         aftercare: {
        //             controlType: 'control',
        //             defaultValue: false,
        //             formControlType: FormControlType.SWITCH,
        //             // validationRules: [{name: ValidationRules.IS_TRUE, params: {}}],
        //             placeholder: '',
        //             label: 'subscriptionPlans.form.afterCare',
        //             outputDataMapper: (data: any) => {
        //                 if (data === 'true' || data === 'on') {
        //                     return true;
        //                 }
        //                 if (data === 'false') {
        //                     return false;
        //                 }
        //
        //                 return data;
        //             },
        //             hostClass: 'col-xl-12 d-flex flex-row justify-content-between mt-3'
        //         }
        //     }
        // },
        {
            controlType: 'group',
            key: 'onlineConsultation',
            class: 'form-row',
            controls: {
                onlineConsultation: {
                    controlType: 'control',
                    defaultValue: false,
                    formControlType: FormControlType.SWITCH,
                    // validationRules: [{name: ValidationRules.IS_TRUE, params: {}}],
                    placeholder: '',
                    label: 'subscriptionPlans.form.onlineConsultation',
                    outputDataMapper: (data: any) => {
                        if (data === 'true' || data === 'on') {
                            return true;
                        }
                        if (data === 'false') {
                            return false;
                        }

                        return data;
                    },
                    hostClass: 'col-xl-12 d-flex flex-row justify-content-between mt-3'
                }
            }
        },
        {
            controlType: 'group',
            key: 'telemedicineHours',
            class: 'form-row',
            controls: {
                telemedicineHours: {
                    hostClass: 'col-50',
                    controlType: 'control',
                    disabled: true,
                    defaultValue: '',
                    placeholder: 'subscriptionPlans.form.telemedicineHours',
                    label:'subscriptionPlans.form.telemedicineHours',
                    formControlType: FormControlType.INPUT,
                    type: InputType.NUMBER,
                },
            }
        },
        {
            controlType: 'group',
            key: 'clinicWidget',
            class: 'form-row clinic-widget-row',
            controls: {
                clinicWidget: {
                    controlType: 'control',
                    defaultValue: false,
                    formControlType: FormControlType.CHECKBOX,
                    // validationRules: [{name: ValidationRules.IS_TRUE, params: {}}],
                    placeholder: '',
                    label: '',
                    disabled: true,
                    isLabelHidden: true,
                    isCheckboxLabelRaw: true,
                    checkboxLabel: 'Clinic Widget available',
                    outputDataMapper: (data: any) => {
                        if (data === 'true' || data === 'on') {
                            return true;
                        }
                        if (data === 'false') {
                            return false;
                        }

                        return data;
                    },
                    hostClass: 'col-100'
                },
                clinicHunterWidget: {
                    controlType: 'control',
                    defaultValue: false,
                    formControlType: FormControlType.CHECKBOX,
                    // validationRules: [{name: ValidationRules.IS_TRUE, params: {}}],
                    placeholder: '',
                    label: '',
                    disabled: true,
                    isLabelHidden: true,
                    isCheckboxLabelRaw: true,
                    checkboxLabel: 'Clinic Hunter Widget available',
                    outputDataMapper: (data: any) => {
                        if (data === 'true' || data === 'on') {
                            return true;
                        }
                        if (data === 'false') {
                            return false;
                        }

                        return data;
                    },
                    hostClass: 'col-100'
                }
            }
        },
        {
            controlType: 'group',
            key: 'widgetAmount',
            class: 'form-row custom-row',
            controls: {
                widgetAmount: {
                    hostClass: 'col-50',
                    controlType: 'control',
                    defaultValue: '',
                    disabled: true,
                    placeholder: 'subscriptionPlans.form.clinicWidgetNo',
                    label:'subscriptionPlans.form.clinicWidgetNumber',
                    formControlType: FormControlType.INPUT,
                    type: InputType.NUMBER,
                },
            }
        },
        // {
        //     controlType: 'group',
        //     key: 'activePlan',
        //     class: 'form-row',
        //     controls: {
        //         isPlanActive: {
        //             controlType: 'control',
        //             defaultValue: false,
        //             formControlType: FormControlType.SWITCH,
        //             // validationRules: [{name: ValidationRules.IS_TRUE, params: {}}],
        //             placeholder: '',
        //             label: 'subscriptionPlans.form.active',
        //             outputDataMapper: (data: any) => {
        //                 if (data === 'true' || data === 'on') {
        //                     return true;
        //                 }
        //                 if (data === 'false') {
        //                     return false;
        //                 }
        //
        //                 return data;
        //             },
        //             hostClass: 'col-xl-12 d-flex flex-row justify-content-between mt-3'
        //         }
        //     }
        // },
        {
            controlType: 'group',
            key: 'subscription_submit',
            class: 'row',
            controls: {
                submitButton: {
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    hostClass: 'col-xl-12 mb-0',
                    defaultContainerStyles: '',
                    containerStyles: 'button-container mt-2',
                    defaultValue: null,
                    btnText: 'subscriptionPlans.createPlan',
                    buttonDisabled: (mappedOutputValue: any, isFormValid: boolean, isFormTouched: boolean) => {
                        if (mappedOutputValue.planName) {
                            return false;
                        }

                        return !isFormValid;
                    }
                },
            },
        },
    ]
};
