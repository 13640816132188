import React from "react";
import {RootState} from "../../../../store/reducers";
import {connect} from "react-redux";
import {
    BasicModal,
    Checkbox,
    clinicProfileLoadingSelector,
    CustomCard,
    CustomCardType,
    Form,
    IFormConfig,
    Translation
} from 'common-web';
import {specialistStateLoading} from "../../../../store/selectors/specialistSelectors";
import {specialistFormConfig} from "./specialistFormConfig";
import {getClinicSpecialistsAPI} from "../../../../api/getClinicSpecialists";
import {catchError, map, tap} from "rxjs/operators";
import {of, Subscription} from "rxjs";
import {Link} from "react-router-dom";
import {createClinicSpecialistAPI} from "../../../../api/createSpecialist";
import {fixInjectedProperties, lazyInject} from "../../../../ioc";
import {IAlertManagerService} from "../../../../service/alertManagerService";

interface IClinicSpecialistsConnectedProps {
    readonly isLoading: boolean;
    readonly specialistStateLoading: boolean;
}

interface IClinicSpecialistsExternalProps {
    readonly clinicId: string;
    readonly authToken: string;
}

interface IClinicSpecialistsProps extends IClinicSpecialistsConnectedProps,
    IClinicSpecialistsExternalProps {
}

interface IClinicSpecialistsState {
    isLoading: boolean;
    formConfig: typeof IFormConfig;
    modalShown: boolean;
    isSpecialistLoading: boolean;
    specialistList: any[];
}

class ClinicSpecialists extends React.Component<IClinicSpecialistsProps, IClinicSpecialistsState> {
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;
    private subscriptions: Subscription[] = [];

    constructor(props: IClinicSpecialistsProps) {
        super(props);

        this.state = {
            isLoading: true,
            formConfig: specialistFormConfig,
            modalShown: false,
            isSpecialistLoading: false,
            specialistList: []
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        this.subscriptions.push(this.getClinicSpecialists().subscribe());
    }

    componentDidUpdate(
        prevProps: Readonly<IClinicSpecialistsProps>,
        prevState: Readonly<IClinicSpecialistsState>,
        snapshot?: any
    ): void {
        if (this.props.isLoading !== prevProps.isLoading
            && !this.props.isLoading) {
            this.setState({isLoading: false})
        }
    }

    componentWillUnmount() {
        if (this.subscriptions.length) {
            this.subscriptions.forEach(subscription => subscription.unsubscribe());
        }
    }

    render() {
        return (
            <React.Fragment>
                <CustomCard showLocalLoader={this.state.isLoading}>
                    <CustomCard.Header>
                        <div className="d-flex justify-content-between align-items-center">
                            <Translation text={'profile.specialists.title'}/>
                            <button className="btn btn-theme"
                                    type="button"
                                    onClick={() => this.toggleModal()}>
                                Add Specialist
                            </button>
                        </div>
                    </CustomCard.Header>
                    <CustomCard.Body>
                        {this.renderSpecialistList()}
                    </CustomCard.Body>
                </CustomCard>

                <BasicModal isModalShown={this.state.modalShown} closeModal={this.closeModal}>
                    <CustomCard showLocalLoader={this.state.isSpecialistLoading} type={CustomCardType.MODAL_CARD}>
                        <CustomCard.Body>
                            <div className="modal-header">
                                Create New Specialist
                                <button className="btn-modal-close" onClick={() => this.toggleModal()}>
                                    <span className="feather icon-x"/>
                                </button>
                            </div>
                            <div className="modal-body">
                                <Form config={this.state.formConfig}
                                      submitForm={this.addSpecialist}
                                      controlName={'clinicSpecialistsForm'}/>
                            </div>
                        </CustomCard.Body>
                    </CustomCard>
                </BasicModal>
            </React.Fragment>
        );
    }

    private renderSpecialistList() {
        if (!this.state.specialistList.length) {
            return (<p>There are no specialist added to the clinic</p>);
        }

        return (
            <table className="data-table">
                <tbody>
                    {this.state.specialistList.map((specialist: any, index: number) => {
                        const label = `${specialist.academicDegree ?
                            specialist.academicDegree + ' ' : ''}${specialist.account.firstName} ${specialist.account.lastName}`;

                        return (
                            <tr key={index}>
                                <td className="align-middle p-0">
                                    <Checkbox // checked={specialist.isAvailable}
                                        name={specialist.name}
                                        label={label}
                                        handleChange={this.handleChange}
                                    />
                                </td>
                                <td className="text-right">
                                    <Link className="btn btn-action"
                                          to={`/dashboard/specialists/${specialist.id}`}>
                                        <span className="feather icon-edit" aria-hidden="true"/>
                                    </Link>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        )
    }

    private handleChange(e: any) {
        console.log(e.target.checked);
    }

    private toggleModal = () => {
        this.setState({modalShown: !this.state.modalShown});
    };
    private closeModal = () => {
        return this.setState({modalShown: false});
    };

    private getClinicSpecialists() {
        return getClinicSpecialistsAPI(this.props.authToken, this.props.clinicId).pipe(
            map((resp: any) => {
                if (resp['hydra:member'].length) {
                    return this.setState({specialistList: resp['hydra:member']})
                }
            })
        )
    }

    private addSpecialist = (event: any, value: any, valid: boolean, touched: boolean): void => {
        if (!valid || !touched) {
            return;
        }

        let languages = [],
            certificates = [],
            membershipAndAwards = [],
            treatmentTypes = [],
            specializations = [],
            clinics = [];
        languages.push({title: value.languages, description: ''});
        certificates.push({title: value.certificates, description: ''});
        membershipAndAwards.push({title: value.membershipAndAwards, description: ''});
        treatmentTypes.push(value.treatments);
        specializations.push(value.specializations);
        clinics.push(value.clinics);

        const payload = {
            login: value.email ? value.email : null,
            plainPassword: value.password ? value.password : null,
            account: {
                firstName: value.firstName,
                lastName: value.lastName,
                birthdate: '1990-06-26T08:13:23.178Z', //should not be null
                phone: '',
                address: null,
                invoiceAddress: null,
                residency: ''
            },
            description: value.description ? value.description : '',
            education: value.education ? value.education : '',
            yearsExperience: value.yearsExperience ? value.yearsExperience : '',
            languages: languages,
            certificates: certificates,
            membershipAndAwards: membershipAndAwards,
            treatments: value.treatments ? treatmentTypes : [],
            specializations: value.specializations ? specializations : [],
            clinics: [this.props.clinicId],
        };

        this.setState({isSpecialistLoading: true});
        this.subscriptions.push(this.handleAddSpecialistAPI(payload).subscribe());
    };

    private handleAddSpecialistAPI(payload: {[key: string]: any}) {
        return createClinicSpecialistAPI(
            this.props.authToken,
            payload
        ).pipe(
            map(() => this.alertManager.addAlert('Specialist added successfully.')),
            tap(() => {
                this.setState({
                    isSpecialistLoading: false,
                    modalShown: false,
                });
                this.subscriptions.push(this.getClinicSpecialists().subscribe());
            }),
            catchError((error: any) => {
                this.setState({isSpecialistLoading: false});
                return of(this.alertManager.handleApiError(error));
            })
        );
    }
}

export default connect(
    (state: RootState) => ({
        isLoading: clinicProfileLoadingSelector(state),
        specialistStateLoading: specialistStateLoading(state),
    }),
    {}
)(ClinicSpecialists);
