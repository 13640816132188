import {FormButtonType, FormControlType, InputType, ValidationRules, IFormConfig} from "common-web";
import moment from "moment";

export const editInsuranceDateFormConfig = (): typeof IFormConfig => {
    return {
        controlType: 'form',
        class: 'insurance-date-form',
        dataAccessor: (data: any) => data, // data is not grouped
        outputDataMapper: (data: any, previousStateSnapshot: any) => {
            Object.assign(previousStateSnapshot, data);

            return previousStateSnapshot;
        },
        controls: [
            {
                controlType: 'group',
                key: 'visit_date',
                class: 'form-row custom-date-row',
                controls: {
                    startDate: {
                        hostClass: 'col-100',
                        controlType: 'control',
                        defaultValue: '',
                        additionalStyles: '',
                        formControlType: FormControlType.DATE,
                        validationRules: [
                            {name: ValidationRules.IS_REQUIRED},
                        ],
                        placeholder: 'insuranceClinicReport.editReport.form.placeholders.insuranceStartDate.',
                        label: "insuranceClinicReport.editReport.form.labels.insuranceStartDate",
                        maxDate: '2100-01-01',
                        minDate: moment().format('YYYY-MM-DD'),
                        openToDate: moment().format('YYYY-MM-DD'),
                        type: InputType.DATE,
                    },
                }
            },
            {
                controlType: 'group',
                key: 'submit_button',
                class: 'form-row',
                controls: {
                    submitButton: {
                        controlType: 'button',
                        formControlType: FormControlType.BUTTON,
                        buttonType: FormButtonType.SUBMIT,
                        hostClass: 'col-100 mb-0',
                        defaultContainerStyles: 'button-container mt-2',
                        containerStyles: '',
                        defaultValue: null,
                        btnText: "insuranceClinicReport.editReport.edit",
                        disabledStyles: 'disabled',
                        enabledStyles: '',
                        isLabelHidden: true,
                        buttonDisabled: (mappedOutputValue: any, isFormValid: boolean) => {
                            return !isFormValid;
                        }
                    }
                }
            },
        ]
    }
};
