import { Observable } from "rxjs";
import { RestQueryParams } from "common-web";
import {treatmentTypeAPI} from "./provider/treatmentTypeAPI";
import {IAddSubscriptionPayload} from "./addSubscriptionDefinition";

export function updateSubscriptionDefinitionAPI(
    authToken: string,
    subscriptionId: string,
    subscriptionDetails: IAddSubscriptionPayload,
    isPublic: boolean,
    itemOrder: number | null,
    active: boolean
): Observable<any> {
    return treatmentTypeAPI.put(
        `subscription_definitions/${subscriptionId}`,
        {
            subscriptionDetails: subscriptionDetails,
            public: isPublic,
            itemOrder: itemOrder,
            active: active
        },
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`
        }
    );
}
