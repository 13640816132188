import React from 'react';
import {Translation} from "common-web";
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {saveAs} from 'file-saver';
import {IAlertManagerService} from "../../../service/alertManagerService";
import {WithTranslation, withTranslation} from "react-i18next";
import {from, Subscription} from "rxjs";

interface IConnectedInsuranceReportDownloadProps {}

interface IExternalInsuranceReportDownloadProps {
  authToken: string;
  reportStartDate: string;
  reportEndDate: string;
}

interface IInsuranceReportDownloadProps extends
  IConnectedInsuranceReportDownloadProps,
  IExternalInsuranceReportDownloadProps,
  WithTranslation {}

interface IInsuranceReportDownloadState {}

class InsuranceReportDownload extends React.Component<IInsuranceReportDownloadProps, IInsuranceReportDownloadState> {
  @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

  private subscription: Subscription | null = null;

  constructor(props: IInsuranceReportDownloadProps) {
    super(props);

    fixInjectedProperties(this);
  }

  componentWillUnmount() {
    if (null !== this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  render() {
    return (
        <button className="btn btn-no-outline"
                type="button"
                onClick={() => this.downloadReport()}>
          <span className="feather icon-download"/>
          <Translation text={'insuranceClinicReport.downloadReport'}/>
        </button>
    );
  }

  private downloadReport = () => {
    const requestUrl = this.props.reportStartDate && this.props.reportEndDate ?
      `inquiries/export?paid_at[after]=${this.props.reportStartDate}&paid_at[before]=${this.props.reportEndDate}` : 'inquiries/export';
    let url = `${process.env.REACT_APP_AUTH_API_URL}/${requestUrl}`;
    this.subscription = from(this.fetchInquiryReport(this.props.authToken, url)).subscribe();
  };

  private fetchInquiryReport(authToken: string, url: string): any {
    return fetch(url, {
      headers: {
        "Accept": "text/csv",
        "Content-type": "application/json",
        "Authorization": `Bearer ${authToken}`
      },
    }).then(response => response.blob())
      .then(blob => {
        saveAs(blob, 'report.csv');
      })
      .catch(error => this.alertManager.handleApiError(error));
  };
}

export default withTranslation()(InsuranceReportDownload);
