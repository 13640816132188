import React from "react";
import { connect } from "react-redux";
import {
  CustomCard,
  Form,
  Translation,
  IFormConfig,
  updateClinicProfile,
  IClinicProfileState,
  clinicProfileLoadingSelector
} from "common-web";
import { clinicDetailsFormConfig } from "./clinicDetailsFormConfig";
import {RootState} from "../../../../store/reducers";
import {paymentOptionsSelector} from "../../../../store/selectors/metadataSelectors";
import {IMetadataCategory, retrievePaymentOptions} from "../../../../store/reducers/metadataSlice";

interface IClinicDetailsConnectedProps {
  readonly isLoading: boolean;
  readonly paymentOptions: IMetadataCategory[];
  readonly retrievePaymentOptions: typeof retrievePaymentOptions;
  readonly updateClinicProfile: typeof updateClinicProfile;
}

interface IClinicDetailsExternalProps {
  readonly authToken: string;
  clinicProfile: typeof IClinicProfileState;
}

interface IClinicDetailsProps extends
  IClinicDetailsConnectedProps,
  IClinicDetailsExternalProps {}

interface IClinicDetailsState {
  isLoading: boolean;
  formConfig: typeof IFormConfig;
  value: any;
}

class ClinicDetails extends React.Component<IClinicDetailsProps, IClinicDetailsState> {
  constructor(props: IClinicDetailsProps) {
    super(props);

    this.state = {
      isLoading: true,
      formConfig: clinicDetailsFormConfig,
      value: null
    };
  }

  componentDidMount(): void {
    this.props.retrievePaymentOptions(this.props.authToken);
    if (this.props.clinicProfile) {
      this.updateFormFromState();
    }
  }

  componentDidUpdate(
    prevProps: Readonly<IClinicDetailsProps>,
    prevState: Readonly<IClinicDetailsState>,
    snapshot?: any
  ): void {
    if(this.props.isLoading !== prevProps.isLoading
      && !this.props.isLoading) {
      this.setState({isLoading: false})
    }

    if(this.props.paymentOptions !== prevProps.paymentOptions) {
      this.setPaymentOptions();
    }

    if (this.props.clinicProfile !== prevProps.clinicProfile) {
      this.updateFormFromState();
    }
  }

  render() {
    return (
      <CustomCard showLocalLoader={this.state.isLoading}>
        <CustomCard.Header>
          <Translation text={'profile.clinicDetails.title'}/>
        </CustomCard.Header>
        <CustomCard.Body>
          <Form config={this.state.formConfig}
                value={this.state.value}
                controlName={'clinicDetailsForm'}
                submitForm={this.changeClinicDetails}/>
        </CustomCard.Body>
      </CustomCard>
    );
  }

  private mapAccountToFormData(clinicProfile: any): any {
    return {
      languages: clinicProfile.languages,
      // openingHours: clinicProfile.openingHours,
      paymentOptions: clinicProfile.paymentOptions,
      accreditation: clinicProfile.accreditations
    };
  }

  updateFormFromState() {
    this.setState({
      value: this.mapAccountToFormData(this.props.clinicProfile)
    })
  }

  private setPaymentOptions = () => {
    if (!this.props.paymentOptions) {
      return;
    }

    const payments = [...this.props.paymentOptions];
    payments.map(((item: {[key: string]: any}) => {
      return {
        label: item.name,
        value: item.id
      }
    }));

    clinicDetailsFormConfig.controls.map((control: any) => {
      if (control.hasOwnProperty("controls")) {
        Object.keys(control.controls).map((key: string) => {
          if (key === 'paymentOptions') {
            control.controls[key].multiselectOptions = payments;
          }
        });
      }

      return control;
    });

    this.setState({formConfig: clinicDetailsFormConfig});
  };

  private changeClinicDetails = (event: any, value: any, valid: boolean, touched: boolean): void => {
    if (!valid || !touched) {
      return;
    }
    this.setState({isLoading: true});

    this.props.updateClinicProfile(
        this.props.clinicProfile.id,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        value.languages,
        value.paymentOptions,
        value.accreditation,
        null,
        null,
        null
    )
  };
}

export default connect(
  (state: RootState) => ({
    isLoading: clinicProfileLoadingSelector(state),
    paymentOptions: paymentOptionsSelector(state)
  }),
  {
    retrievePaymentOptions,
    updateClinicProfile
  }
)(ClinicDetails);
