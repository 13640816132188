import React, {CSSProperties} from 'react';
import {Link, RouteComponentProps, withRouter} from 'react-router-dom';
import {RootState} from "../../store/reducers";
import {
    authTokenSelector,
    CustomCard,
    RestQueryParams,
    Translation,
    CurrencyConverter,
    CustomPagination,
    Switch,
    MultiSelect,
    MultiSelectType,
    IMultiselectOption
} from "common-web";
import {connect} from "react-redux";
import {WithTranslation, withTranslation} from "react-i18next";
import {IAlertManagerService} from '../../service/alertManagerService';
import {fixInjectedProperties, lazyInject} from "../../ioc";
import {of, Subscription} from "rxjs";
import AddSubscriptionPlan from './AddSubscriptionPlan';
import {
    retrievedSubscriptionDefinitionListSelector,
    subscriptionDefinitionListErrorSelector,
    subscriptionDefinitionListEventSourceSelector,
    subscriptionDefinitionListLoadingSelector
} from "../../store/selectors/subscriptionDefinitionListSelectors";
import {list, reset} from '../../actions/subscriptionDefinition/list';
import {del} from "../../actions/subscriptionDefinition/delete";
import {catchError, map, tap} from "rxjs/operators";
import {IAddSubscriptionPayload} from "../../api/addSubscriptionDefinition";
import {updateSubscriptionDefinitionAPI} from "../../api/updateSubscriptionDefinition";
import {getSubscriptionDefinitionsAPI} from "../../api/getSubscriptionDefinitions";

declare global {
    namespace JSX {

        export interface IntrinsicElements {
            'x-subscription-plans-widget': ICalendarWidgetProps;
        }

        interface ICalendarWidgetProps
            extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>,
                HTMLElement> {
            baseUrl: string;
            readonly style?: CSSProperties;
        }
    }
}

const orderOptions =  [
    {
        label: '0',
        value: 0,
        isDisabled: false
    },
    {
        label: '1',
        value: 1,
        isDisabled: false
    },
    {
        label: '2',
        value: 2,
        isDisabled: false
    },
    {
        label: '3',
        value: 3,
        isDisabled: false
    },
];

interface IConnectedSubscriptionPlansProps {
    readonly authToken: string;
    readonly retrieved: any;
    readonly loading: boolean;
    readonly error: string;
    readonly eventSource: EventSource;
    readonly list: any;
    readonly reset: any;
    readonly del: typeof del;
}

interface ISubscriptionPlansProps extends IConnectedSubscriptionPlansProps,
    RouteComponentProps,
    WithTranslation {
}

interface ISubscriptionPlansState {
    addSubscriptionPlanModalShown: boolean;
    selectedSubscriptionPlan: {[key: string]: any} | null;
    subscriptionPlans: {[key: string]: any} | null;
    orderOptions: typeof IMultiselectOption[];
    isActivePlanEnabled: boolean;
}

class SubscriptionPlans extends React.Component<ISubscriptionPlansProps, ISubscriptionPlansState> {
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;
    private subscriptions: Subscription[] = [];

    constructor(props: ISubscriptionPlansProps) {
        super(props);

        this.state = {
            addSubscriptionPlanModalShown: false,
            selectedSubscriptionPlan: null,
            subscriptionPlans: null,
            orderOptions: orderOptions,
            isActivePlanEnabled: false
        };

        fixInjectedProperties(this);
    }

    get widget() {
        return `<x-subscription-plans-widget 
                    baseUrl="${process.env.REACT_APP_SUBSCRIPTION_WIDGET_URL}"
                    style="min-height: 280px; min-width: 100%;"></x-subscription-plans-widget>
                <script src="${process.env.REACT_APP_SUBSCRIPTION_WIDGET_URL}/subscription-plans-widget.js"></script>`;
    }

    componentDidMount() {
        this.subscriptions.push(
            getSubscriptionDefinitionsAPI(this.props.authToken).pipe(
                map((response: {[key: string]: any}) => {
                    if (response && response['hydra:member']) {
                        let publicPlans = response['hydra:member'].filter((plan: {[key: string]: any}) => plan.public);
                        let activePlans = publicPlans.filter((plan: {[key: string]: any}) => plan.active);
                        let isDisabled: boolean;
                        activePlans.length >= 3 ? isDisabled = true : isDisabled = false;
                        this.setState({isActivePlanEnabled: !isDisabled});
                    }
                })
            ).subscribe()
        );
    }

    componentDidUpdate(
        prevProps: Readonly<ISubscriptionPlansProps>,
        prevState: Readonly<ISubscriptionPlansState>,
        snapshot?: any
    ): void {
        if (this.props.error !== prevProps.error) {
            this.alertManager.handleApiError(this.props.error);
        }

        if (JSON.stringify(this.props.retrieved) !== JSON.stringify(prevProps.retrieved)) {
            this.setState({subscriptionPlans: this.props.retrieved});
        }
    }

    componentWillUnmount() {
        this.props.reset(this.props.eventSource);
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="view-header">
                            <div className="view-title">
                                <Translation text={'subscriptionPlans.title'}/>
                            </div>
                            <div className="action-container">
                                <button className="btn btn-no-outline mr-4"
                                        type="button"
                                        onClick={() => this.copyWidget()}>
                                    <i className="feather icon-copy"/>
                                    <Translation text={'subscriptionPlans.copyPlansWidget'}/>
                                </button>

                                <button className="btn btn-theme"
                                        type="button"
                                        onClick={() => this.toggleAddSubscriptionPlanModal()}>
                                    <Translation text={'subscriptionPlans.newPlan'}/>
                                </button>
                            </div>
                        </div>
                        <div className="additional-info">
                            <span className="feather icon-alert-triangle"/>
                            <p><Translation text="subscriptionPlans.warning"/></p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-12">
                        <CustomCard showLocalLoader={this.props.loading}>
                            <CustomCard.Body>
                                {this.renderPlansList()}

                                <CustomPagination retrieved={this.state.subscriptionPlans}
                                                  basePath="dashboard"
                                                  provider={this.getSubscriptionPlanList}/>
                            </CustomCard.Body>
                        </CustomCard>
                    </div>
                </div>

                {this.state.addSubscriptionPlanModalShown &&
                <AddSubscriptionPlan addSubscriptionModalShown={this.state.addSubscriptionPlanModalShown}
                                             closeSubscriptionModal={this.closeAddSubscriptionPlanModal}
                                             toggleSubscriptionModal={this.toggleAddSubscriptionPlanModal}
                                             selectedSubscription={this.state.selectedSubscriptionPlan}
                />
                }
            </React.Fragment>
        );
    }

    private renderPlansList = () => {
        if (!this.state.subscriptionPlans ||
            !(this.state.subscriptionPlans as any)['hydra:member'] ||
            !Array.isArray((this.state.subscriptionPlans as any)['hydra:member'])) {
            return <p>There is no data available</p>;
        }

        return (
            <React.Fragment>
                <table className="data-table">
                    <thead>
                    <tr>
                        <th><Translation text={'subscriptionPlans.list.name'}/></th>
                        <th><Translation text={'subscriptionPlans.list.price'}/></th>
                        <th><Translation text={'subscriptionPlans.list.status'}/></th>
                        <th />
                        <th />
                    </tr>
                    </thead>
                    <tbody>
                        {this.renderTableRows()}
                    </tbody>
                </table>
            </React.Fragment>
        )
    };

    private renderTableRows() {
        const rows: any[] = [];
            // retrievedSubscriptionPlans = Object.assign({}, this.state.retrievedSubscriptionPlans),
            // retrievedSubscriptionPlans = Object.assign({}, {}),
            // list = (retrievedSubscriptionPlans as any)['hydra:member'].sort(this.sortMethod);

        (this.state.subscriptionPlans as any)['hydra:member'].map((plan: any) => {
            rows.push((
                <tr key={plan.id}>
                    <td>
                        {plan?.subscriptionDetails?.name ? plan?.subscriptionDetails?.name : '---'}
                    </td>
                    <td>
                        {plan?.subscriptionDetails?.price ?
                            (<CurrencyConverter price={{
                                amount: plan.subscriptionDetails.price.amount,
                                currency: {
                                    code: plan.subscriptionDetails.price.currency.code
                                }
                            }}/>)
                            : '---'}
                    </td>
                    <td>
                        {this.renderPlanStatus(plan)}
                    </td>
                    <td>
                        {this.renderPlanOrderControl(plan)}
                    </td>
                    <td className="align-middle text-right">
                        <button className="btn btn-action mr-2"
                                onClick={() => {
                                    this.setState({selectedSubscriptionPlan: plan});
                                    this.toggleAddSubscriptionPlanModal()
                                }}>
                            <span className="feather icon-copy"/>
                        </button>

                        <Link className={"btn btn-action"}
                              to={{
                                  pathname: `/dashboard/subscription-plans/${plan['id']}`
                              }}>
                            <span className="feather icon-file-text"/>
                        </Link>
                    </td>
                </tr>
            ))
        });
        return rows;
    }

    private renderPlanStatus = (plan: {[key: string]: any}) => {
        return (
            <Switch checked={plan.active}
                    name={plan.id}
                    disabled={!this.state.isActivePlanEnabled && !plan.active && plan.public}
                    handleChange={(e: any) => this.handleChange(e, plan)}/>
        )
    };

    private renderPlanOrderControl = (plan: {[key: string]: any}) => {
        const {t} = this.props;
        let controlValue = plan.itemOrder ? {value: plan.itemOrder, label: plan.itemOrder.toString()} : null;

      return (
          <MultiSelect
              multiselectType={MultiSelectType.SINGLE}
              handleChange={
                  (e: any) => {
                      controlValue = {value: e.value, label: e.label};
                      this.onPlanOrderChange(e, plan)
                  }
              }
              options={this.state.orderOptions}
              name={'select_clinics'}
              placeholder={t('subscriptionPlans.list.order')}
              value={[controlValue]}
          />
      )
    };

    private handleChange = (e: any, plan: {[key: string]: any}) => {
        const isChecked = e.target.checked,
            itemOrder = isChecked ? plan.itemOrder : null;
        this.setState({isActivePlanEnabled: false});
        this.subscriptions.push(this.updateSubscriptionPlan(plan, itemOrder, isChecked).pipe(
            tap(() => {
                this.subscriptions.push(
                    getSubscriptionDefinitionsAPI(this.props.authToken).pipe(
                        map((response: {[key: string]: any}) => {
                            if (response && response['hydra:member']) {
                                let activePlans = response['hydra:member'].filter((plan: {[key: string]: any}) => plan.active);
                                let isDisabled: boolean;
                                activePlans.length >= 3 ? isDisabled = true : isDisabled = false;
                                this.setState({isActivePlanEnabled: !isDisabled});
                            }
                        })
                    ).subscribe()
                );
            })
        ).subscribe());


    };

    private onPlanOrderChange = (orderValue: any, plan: {[key: string]: any}) => {
        this.subscriptions.push(this.updateSubscriptionPlan(plan, orderValue.value, null).subscribe());
    };

    private getSubscriptionPlanList = (searchParams: typeof RestQueryParams) => {
        searchParams = searchParams.add('order[createdAt]', 'DESC');
        searchParams = searchParams.add('public', true);

        this.props.list(
            `subscription_definitions${searchParams.prepareQuery()}`,
            this.props.authToken
        );
    };

    private sortMethod(a: any, b: any): number {
        const aDate = new Date(a.createdAt),
            bDate = new Date(b.createdAt),
            aTime = aDate.getTime(),
            bTime = bDate.getTime();

        return aTime > bTime ? -1 : aTime !== bTime ? 1 : 0;
    }

    private toggleAddSubscriptionPlanModal = () => {
        if  (this.state.addSubscriptionPlanModalShown) {
            this.setState({
                addSubscriptionPlanModalShown: !this.state.addSubscriptionPlanModalShown,
                selectedSubscriptionPlan: null
            })
        } else {
            this.setState({
                addSubscriptionPlanModalShown: !this.state.addSubscriptionPlanModalShown
            })
        }
    };

    private closeAddSubscriptionPlanModal = () => {
        return this.setState({
            addSubscriptionPlanModalShown: false,
            selectedSubscriptionPlan: null
        });
    };

    private updateSubscriptionPlan = (
        plan: {[key: string]: any},
        itemOrder: number | null,
        isPlanActive: boolean | null
    ) => {
        const planDetails = plan.subscriptionDetails,
            planRestrictions = planDetails.subscriptionRestrictions,
            subscriptionDetails: IAddSubscriptionPayload = {
                name: planDetails.name,
                description: planDetails.description,
                price: {
                    amount: planDetails.price.amount,
                    currency: {
                        code: planDetails.price.currency.code
                    }
                },
                duration: 30,
                subscriptionRestrictions: {
                    consultationTimeLimit: planRestrictions.consultationTimeLimit,
                    clinicCalendarWidgetAvailable: planRestrictions.clinicCalendarWidgetAvailable,
                    appCalendarWidgetAvailable: planRestrictions.appCalendarWidgetAvailable,
                    treatmentPlanEnabled: planRestrictions.treatmentPlanEnabled,
                    calendarCountLimit: planRestrictions.calendarCountLimit
                }
            };

        return updateSubscriptionDefinitionAPI(
            this.props.authToken,
            plan.id,
            subscriptionDetails,
            plan.public,
            itemOrder !== null ? itemOrder : 0,
            isPlanActive !== null ? isPlanActive : plan.active).pipe(
            map(() => {
                const searchParams = this.props.location.search;
                this.props.list(
                    `subscription_definitions${searchParams}&public=true`,
                    this.props.authToken
                );
                this.alertManager.addAlert("Subscription plan details were updated");
            }),
            catchError((error: any) => {
                return of(this.alertManager.handleApiError(error));
            })
        );
    };

    private copyWidget = () => {
        navigator.clipboard.writeText(this.widget);
        this.alertManager.addAlert('subscriptionPlans.widgetCopied');
    };
}

export default withTranslation()(connect(
    (state: RootState) => ({
        retrieved: retrievedSubscriptionDefinitionListSelector(state),
        loading: subscriptionDefinitionListLoadingSelector(state),
        error: subscriptionDefinitionListErrorSelector(state),
        eventSource: subscriptionDefinitionListEventSourceSelector(state),
        authToken: authTokenSelector(state),
    }),
    {
        list,
        reset,
        del
    }
)(withRouter(SubscriptionPlans)));
