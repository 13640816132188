import { Observable } from "rxjs";
import { RestQueryParams } from "common-web";
import {treatmentTypeAPI} from "./provider/treatmentTypeAPI";
import {ITreatmentCategoryTranslation} from "./addTreatmentCategory";

export function editTreatmentTypeAPI(
    accessToken: string,
    typeId: string,
    treatmentCategory: string,
    name: string,
    nameTranslation: ITreatmentCategoryTranslation,
    specialists: string[] = []
): Observable<any> {
    return treatmentTypeAPI.put(
        `treatment-type/${typeId}`,
        {
            treatmentCategory: treatmentCategory,
            name: name,
            nameTranslation: nameTranslation,
            doctors: specialists,
        },
        new RestQueryParams(),
        {
            Authorization: `Bearer ${accessToken}`
        }
    );
}
