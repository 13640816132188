import React from "react";
import {
    accountSelector,
    Loader,
    NavBar,
    Sidebar,
    NotFound,
    setAuthState,
    Toast,
    usernameSelector
} from 'common-web';
import {withRouter, RouteComponentProps, Switch, Route} from 'react-router-dom';
import navigation from "./menu-items";
import { connect } from "react-redux";
// import Dashboard from '../Dashboard';
import Settings from '../Settings';
import { RootState } from "../../store/reducers";
import { showLoaderSelector } from "../../store/selectors/sagaSelectors";
import Clinics from "../Clinics";
import TreatmentCategories from "../TreatmentCategories";
import ClinicView from "../Clinics/ClinicView";
import TreatmentCategoryView from "../TreatmentCategories/TreatmentCategoryView";
import InsuranceClinicReport from "../Insurances/InsuranceClinicReport";
import InsuranceAxaReport from "../Insurances/InsuranceAxaReport";
import SpecialistView from "../Specialists/SpecialistView";
import Consultations from "../Consultations";
import PredefinedRecommendationsList from "../TreatmentPlanner/PredefinedRecommendationsList";
import SubscriptionPlans from "../SubscriptionPlans";
import SubscriptionPlanView from "../SubscriptionPlans/SubscriptionPlanView";

interface IConnectedPanelHostProps {
  readonly setAuthState: typeof setAuthState;
  readonly showLoader: boolean;
  readonly account: any;
  readonly username: string;
}

interface IExternalPanelHostProps {
  fullWidthLayout?: any;
}

interface IPanelHostProps extends
    IConnectedPanelHostProps,
    IExternalPanelHostProps,
  RouteComponentProps {}

interface IPanelHostState {
  isSidebarCollapsed: boolean;
}

class PanelHost extends React.Component<IPanelHostProps, IPanelHostState> {
  private navMenu: any[];

  constructor(props: IPanelHostProps) {
    super(props);

    this.state = {
        isSidebarCollapsed: false
    };

    this.navMenu = [
      {
        url: '/dashboard/settings',
        icon: 'icon-settings',
        title: 'Settings'
      },
      {
        url: '#',
        icon: 'icon-log-out',
        title: 'Logout',
        onClick: this.onLogout
      }
    ];
  }

  render() {
    const collapsed = this.state.isSidebarCollapsed ? 'collapsed' : '',
        username = this.props.username ? this.props.username : null,
        name = this.props.account ? `${this.props.account.firstName} ${this.props.account.lastName}` : username;
    return (
      <div className="dashboard-view">
        <NavBar onToggleNavigation={this.toggleSidebar}
                onLogout={this.onLogout}
                isDropdownVisible={false}
                isSearchVisible={false}
                generalNotificationsDisplayed={false}
                profileNotificationsDisplayed={false}
                navMenu={this.navMenu}
                username={name}/>
        <div className="content-main">
          <Sidebar navigation={navigation}
                      collapseMenu={this.state.isSidebarCollapsed}/>

          <div className={`main-container ${collapsed}`}>
            <div className="content">

              <Switch>
                  {/*<Route path="/dashboard" component={Dashboard} exact strict key="dashboard"/>*/}
                  <Route path="/dashboard/clinics" component={Clinics} exact strict key="clinics" />
                  <Route path="/dashboard/clinics/:id" component={ClinicView} exact strict key="clinicView" />
                  <Route path="/dashboard/specialists/:id" component={SpecialistView} exact key="specialistView" />
                  <Route path="/dashboard/insurance-axa-report" component={InsuranceAxaReport} exact key="insuranceAxaReport" />
                  <Route path="/dashboard/insurance-clinic-report" component={InsuranceClinicReport} exact key="insuranceClinicReport" />
                  <Route path="/dashboard/consultations" component={Consultations} exact key="consultations" />
                  <Route path="/dashboard/treatment-categories" component={TreatmentCategories} exact strict key="treatmentCategories" />
                  <Route path="/dashboard/treatment-categories/:id" component={TreatmentCategoryView} exact key="treatmentCategoryView" />
                  <Route path="/dashboard/settings" component={Settings} exact strict key="settings" />
                  <Route path="/dashboard/recommendations" component={PredefinedRecommendationsList} exact strict
                         key="recommendationsList"/>
                  <Route path="/dashboard/subscription-plans" component={SubscriptionPlans} exact strict key="subscriptionPlans"/>
                  <Route path="/dashboard/subscription-plans/:id" component={SubscriptionPlanView} exact strict key="subscriptionPlanView" />
                  <Route key="not-found" component={NotFound} />
              </Switch>

            </div>
          </div>
        </div>
        <Loader showLoader={this.props.showLoader}/>
        <Toast />
      </div>
    );
  }

  toggleSidebar = (): void => {
    this.setState({isSidebarCollapsed: !this.state.isSidebarCollapsed});
  };

  private onLogout = () => {
    this.props.setAuthState(null, null, null, null, null, false);
    window.location.href = '/auth/login';
  };
}

export default connect(
  (state: RootState) => ({
     showLoader: showLoaderSelector(state),
     account: accountSelector(state),
     username: usernameSelector(state)
  }),
  {
    setAuthState
  }
)(withRouter(PanelHost));
