const en = {
  setting: {
    title: "Settings",
    addressData: {
      title: "Address Information"
    },
    changePassword: {
      title: "Change Password"
    },
    treatmentCategories: {
      title: "Treatment Categories"
    },
    invoiceData: {
      title: "Invoice Data"
    },
    personalData: {
      title: "Personal Data"
    },
    clinicLogo: {
      title: "Clinic Logo",
      placeholder: "Click or Drag files to this area to upload"
    }
  },
    profile: {
        title: "Profile",
        clinicDetails: {
            title: "Clinic Details"
        },
        clinicPhotos: {
            title: "Clinic Photo"
        },
        specialists: {
            title: "Specialists"
        },
        generalInformation: {
            title: "General Information"
        },
        treatmentCategories: {
            title: "Treatment Categories"
        },
        clinicCommission: {
            title: "Clinic Commission"
        }
    },
  treatmentCategories: {
    title: "Treatment Categories",
    btnAdd: "Create New Treatment Category",
    deleteModal: {
      title: "Are you sure you want to delete treatment category?"
    },
    addModal: {
      title: "Create New Treatment Category",
      addBtn: "Create Category"
    },
    editModal: {
      title: "Update Treatment Category Details",
      updateBtn: "Update Category"
    },
    category: {
      title: "Treatment Category",
      btnAdd: "Add Treatment Type"
    },
    treatmentTypesTable: {
      headers: {
        treatmentType: "Treatment Type"
      }
    },
    deleteTreatmentTypeModal: {
      title: "Are you sure you want to delete treatment type?"
    },
    addTreatmentTypeModal: {
      title: "Add New Treatment Type",
      button: 'Add Treatment Type'
    },
    editTreatmentTypeModal: {
      title: "Update Treatment Type Details",
      button: 'Update',
    }
  },
  axaClinicList: {
    title: "Clinics",
    name: "Clinic name",
    address: "Clinic address",
    clinicId: "Clinic id",
    plan: "Plan",
    treatmentCategory: {
      title: "Treatment category",
      noTreatmentCategories: "No treatment categories"
    },
    clinicOwner: "Clinic owner",
    phone: "Phone",
    consultations: "Online Consultations",
    insurance: "Insurance",
    aftercare: "Aftercare",
    panelAccessStatus: {
      access_allowed: "allowed.",
      access_denied: "denied."
    },
    hasAccessToPanel: {
      telemedicine_panel: "Telemedicine panel",
      insurance_panel: "Insurance panel",
      aftercare_panel: "Aftercare panel",
      description: "The access to {{panelType}} is {{isPanelAllowed}}"
    },
    actions: {
      title: "Actions",
      view: "View details",
      delete: "Delete Clinic",
      addClinic: "Create New Clinic",
      downloadClinicList: "Download csv"
    },
    clinicReportDownloadSuccess: "Clinics' list has been downloaded.",
    clinicReportDownloadError: "Something went wrong. Please try again later",
  },
  clinicView: {
    form: {
      labels: {
        login: "Login"
      },
      placeholders: {
        login: "Clinic's login"
      }
    },
  },
  insuranceClinicReport: {
    title: "Axa Insurance Clinic Report",
    selectClinic: "Search insurances by Clinic name",
    selectDate: "Select date range",
    downloadReport: "Download report",
    imgDownloadAlt: "Download",
    noReportData: "There is no data available",
    noClinic: "No Clinics found",
    clinicHunterOnly: "Clinic Hunter only",
    allInsurances: "All insurances",
    reportTable: {
      headers: {
        date: "Purchase date",
        policyNo: "Policy No",
        clinic: "Clinic / Customer",
        customer: "Customer",
        price: "Price",
        commissionClinicHunter: "Commission Clinic Hunter",
        commissionClinic: "Commission Clinic",
        commissions: "Commissions",
        settledWithClinic: "Settled with clinic",
        addedByClinic: "Added by clinic",
        status: 'Status',
        vendorPaymentId: 'Stripe Payment Id',
        actions: 'Actions'
      },
      commissionLabels: {
        axa: 'AXA:',
        clinic: 'Clinic:',
        clinicHunter: 'CH:'
      },
      status: {
        status_paid: "paid",
        status_active: "active",
        status_expired: "expired",
        status_draft: "draft",
        status_modified: "cancelled",
      },
    },
    editReport: {
      title: 'Edit insurance details',
      form: {
        labels: {
          firstName: 'First name',
          lastName: 'Last name',
          email: 'Email',
          birthdate: 'Date of birth',
          companionFirstName: 'Companion\'s first name',
          companionLastName: 'Companion\'s last name',
          companionBirthdate: 'Companion\'s date of birth',
          visitDate: "Surgery date / First visit date",
          source: "How did you hear about us?",
          customSource: 'Type other option',
          insuranceStartDate: 'Insurance start date',
        },
        placeholders: {
          firstName: 'First name',
          lastName: 'Last name',
          email: 'Email',
          birthdate: 'Date of birth',
          companionFirstName: 'First name',
          companionLastName: 'Last name',
          companionBirthdate: 'Date of birth',
          visitDate: "DD - MM - YYYY",
          source: "Select",
          customSource: 'Type option',
          insuranceStartDate: 'Insurance start date',
        }
      },
      edit: 'Edit',
      editedSuccessMessage: 'Inquiry has been updated',
    }
  },
  insuranceAxaReport: {
    title: "Axa Insurances Report",
    selectDate: "Select date range",
    reportTable: {
      headers: {
        date: "Report Date Range",
        insurancesAmount: "Insurances' Number",
        download: "Download",
      },
      buttons: {
        download: "Download",
        downloadExpanded: "Download the report"
      }
    },
    noReportData: "There is no data available"
  },
  consultations: {
    title: 'Telemedicine Clinic Report',
    list: {
      durationMinutes: 'min'
    },
    table: {
      headers: {
        bookingDate: 'Booking date',
        consultationDate: 'Consultation date',
        duration: 'Duration',
        patientName: 'Patient name',
        patientEmail: 'Patient email',
        clinic: 'Clinic',
        calendarName: 'Calendar name',
        price: 'Price',
        status: 'Status'
      }
    },
    statuses: {
      scheduled: 'Scheduled',
      draft: 'Draft',
      cancelled: 'Canceled',
      not_started: 'Not started',
      finished: 'Finished'
    }
  },
  aftercare: {
    predefinedEvents: {
      title: "Predefined Events",
      addEvent: "Create New Event",
      addEventButton: "Create Event",
      list: {
        eventName: "Event Name",
        eventDescription: "Event Description",
        eventSingle: "Single",
        eventConsecutive: "Consecutive",
        actions: "Actions"
      },
      addEventModal: {
        title: "Create New Event",
        form: {
          eventDate: "Event Date",
          eventHour: "Hour",
          eventDayPeriod: "AM/PM",
          eventType: "Select Event from List",
          eventName: "Event Name",
          eventDescription: "Description",
          eventDescriptionPlaceholder: "Add Event Custom Note for Patient",
          selectTypePlaceholder: "Type",
          selectAmountPlaceholder: "Amount",
          reminderDate: "When to remind about Event",
          before: "before",
          eventOccurrence: "Event Type",
          singleEvent: "Single",
          consecutiveEvent: "Consecutive",
          consecutiveType: "Consecutive Type",
          interval: "Interval"
        },
      },
      editEventModal: {
        title: "Edit Event"
      },
      deleteEventModal: {
        title: "Delete Event",
        description: "Are you sure you want to delete an event?",
        buttons: {
          yes: "yes",
          no: "no"
        }
      }
    },
  },
  subscriptionPlans: {
    title: "Subscription Plans",
    newPlan: "Create New Subscription Plan",
    copyPlansWidget: "Copy Subscription Plans Widget",
    widgetCopied: "Snippet copied to clipboard. Paste it into the code of your website.",
    createPlan: "Create Subscription Plan",
    duplicatePlan: "Duplicate Subscription Plan",
    updatePlan: "Update Subscription Plan",
    proposePlan: "Propose Subscription Plan",
    warning: "You can have only three plans set as active at a time.",
    list: {
      name: "Plan Name",
      price: "Price",
      status: "Status",
      actions: "Actions",
      order: "Order"
    },
    form: {
      planName: "Plan Name",
      planPrice: "Plan Price",
      reviews: "Reviews",
      afterCare: "AfterCare",
      onlineConsultation: "Online Consultations",
      telemedicineHours: "Monthly hours",
      clinicHunterWidget: "Clinic Hunter Widget",
      clinicWidget: "Clinic Widget",
      clinicWidgetNumber: "Number of Clinic Widgets",
      clinicWidgetNo: "Clinic Widgets No",
      active: "Active",
      date: "Date",
      paid: "Paid",
      planAmount: "Amount",
      planCurrency: "Currency"
    },
    statuses: {
      active: "Active",
      draft: "Draft"
    },
    view: {
      title: 'Subscription Plan:',
      details: 'Plan details',
      description: 'Plan description',
      addDescription: 'Add description'
    },
    details: {
      title: "Clinic Plan",
      planName: "Name:",
      planPrice: "Price:",
      purchaseDate: "Purchase Date:",
      telemedicineHours: "Telemedicine Monthly Hours:",
      telemedicineHoursUsage: "Telemedicine Current Monthly Hours Usage:",
      clinicHunterWidget: "Clinic Hunter Widget:",
      clinicWidget: "Clinic Widget:",
      proposedPlan: "Proposed plan",
      proposePlan: "Propose New Plan"
    },
    clinicInvoices: {
      title: "Clinic Invoices",
      addInvoice: "Add Invoice",
      editInvoice: "Edit Invoice",
      dropInvoice: "Drop your invoice here or",
      chooseInvoice: "choose from your computer",
      uploadInvoice: "Upload Invoice",
      updateInvoice: "Update Invoice",
      table: {
        name: 'Name',
        date: 'Date',
        price: 'Price',
        status: 'Status'
      },
      statuses: {
        paid: 'Paid',
        unpaid: 'Unpaid'
      },
      deleteModal: {
        title: "Are you sure you want to delete uploaded invoice?"
      },
      form: {
        paid: "Paid At",
      },
    }
  },
  formValidation: {
    errors: {
      minLength: "The input is too short",
      isEmailValid: "Provide a valid email address",
      isRequired: "The field is required",
      isTrue: "Must be checked",
      isPasswordSame: "Passwords not match",
      isStartDateValid: "Date must be after current day",
      isStartDateGreaterOrEvenValid: "Date must be at or after current day",
      isEndDateValid: "Date of return must be after departure",
      sameEmail: 'The emails should be the same',
      samePassword: 'The passwords should be the same'
    }
  },
  modal: {
    confirm: "Confirm"
  },
  button: {
    back: "Back",
    edit: "Edit",
    delete: "Delete",
    showDetails: "Show details",
    add: "Add",
    update: "Update",
    duplicate: "Duplicate",
    download: "Download",
    yes: "Yes",
    no: "No"
  }
};

export default en;
