import { RootState } from "../reducers";
import { createSelector } from "redux-starter-kit";

export interface IInquiryBaseState {
  loading: boolean;
  error: string;
}

export interface IInquiryListState extends IInquiryBaseState {
  retrieved: {[key: string]: any}
  eventSource: EventSource;
}

export const selectInquiryList = (state: RootState): IInquiryListState => {
  return state.inquiryList.list;
};

export const retrievedInquiryListSelector = createSelector(
  [selectInquiryList],
  (state: IInquiryListState) => state.retrieved
);

export const inquiryListLoadingSelector = createSelector(
  [selectInquiryList],
  (state: IInquiryListState) => state.loading
);

export const inquiryListErrorSelector = createSelector(
  [selectInquiryList],
  (state: IInquiryListState) => state.error
);

export const inquiryListEventSourceSelector = createSelector(
  [selectInquiryList],
  (state: IInquiryListState) => state.eventSource
);
