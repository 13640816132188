import React from 'react';
import {Translation,} from 'common-web';
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {IAlertManagerService} from "../../../service/alertManagerService";
import {from, Subscription} from "rxjs";

interface IConnectedDownloadClinicListButtonProps {
}

interface IExternalDownloadClinicListButtonProps {
    readonly authToken: string;
}

interface IDownloadClinicListButtonProps extends IConnectedDownloadClinicListButtonProps,
    IExternalDownloadClinicListButtonProps {
}

interface IDownloadClinicListButtonState {}

class DownloadClinicListButton extends React.Component<IDownloadClinicListButtonProps, IDownloadClinicListButtonState> {
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;
    readonly subscriptions: Subscription[] = [];

    constructor(props: IDownloadClinicListButtonProps) {
        super(props);

        this.state = {};

        fixInjectedProperties(this);
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <button className="btn btn-theme mr-4"
                    onClick={() => this.downloadClinicList()}>
                <Translation text={'axaClinicList.actions.downloadClinicList'}/>
            </button>
        );
    }

    private downloadClinicList() {
        this.subscriptions.push(
            from(this.downloadClinicCsv(
                this.props.authToken as string,
                `${process.env.REACT_APP_AUTH_API_URL}/clinics/report`
            )).subscribe()
        );
    }

    downloadClinicCsv = async (authToken: string, url: string) => {
        try {
            const response = await fetch(url, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    'Content-Type': 'application/json',
                    'Accept': 'text/csv',
                },
            });

            if (!response.ok) {
                throw new Error(`Error downloading clinic report: ${response.statusText}`);
            }

            const blob = await response.blob();
            await saveAs(blob, `report.csv`);
            await this.alertManager.addAlert('axaClinicList.clinicReportDownloadSuccess');
        } catch (error) {
            await this.alertManager.addAlert('axaClinicList.clinicReportDownloadError');
        }
    };
}

export default DownloadClinicListButton;
