import React from 'react';
import {Link, RouteComponentProps, withRouter} from 'react-router-dom';
import {
    authTokenSelector,
    BasicModal,
    CustomCard,
    CustomCardType,
    CustomPagination,
    IFormConfig,
    RestQueryParams,
    Translation
} from "common-web";
import {fixInjectedProperties, lazyInject} from "../../ioc";
import {connect} from "react-redux";
import {RootState} from "../../store/reducers";
import {list, reset} from '../../actions/treatmentcategory/list';
import {del} from "../../actions/treatmentcategory/delete";
import {
    retrievedTreatmentCategoryListSelector,
    treatmentCategoryListErrorSelector,
    treatmentCategoryListEventSourceSelector,
    treatmentCategoryListLoadingSelector
} from "../../store/selectors/treatmentCategoriesListSelector";
import {IAlertManagerService} from "../../service/alertManagerService";
import {WithTranslation, withTranslation} from "react-i18next";
import {Subscription} from "rxjs";
import {addTreatmentCategoryFormConfig} from "./formConfig";
import AddTreatmentCategory from "./AddTreatmentCategory";

interface IConnectedTreatmentCategoriesProps {
    readonly retrieved: any;
    readonly loading: boolean;
    readonly error: string;
    readonly eventSource: EventSource;
    readonly list: any;
    readonly reset: any;
    readonly del: typeof del;
    readonly authToken: string;
}

interface IExternalTreatmentCategoriesProps {
}

interface ITreatmentCategoriesProps extends IConnectedTreatmentCategoriesProps,
    IExternalTreatmentCategoriesProps,
    RouteComponentProps,
    WithTranslation {
}

interface ITreatmentCategoriesState {
    addModalShown: boolean;
    addModalLoading: boolean;
    deleteModalShown: boolean;
    isLoading: boolean;
    formConfig: typeof IFormConfig;
    treatmentCategories: { [key: string]: any } | null;
    selectedTreatmentCategory: { [key: string]: any } | null;
}

class TreatmentCategories extends React.Component<ITreatmentCategoriesProps, ITreatmentCategoriesState> {
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

    private subscription: Subscription | null = null;

    constructor(props: ITreatmentCategoriesProps) {
        super(props);

        this.state = {
            addModalShown: false,
            addModalLoading: false,
            deleteModalShown: false,
            isLoading: false,
            formConfig: addTreatmentCategoryFormConfig,
            treatmentCategories: null,
            selectedTreatmentCategory: null
        };

        fixInjectedProperties(this);
    }

    componentDidUpdate(
        prevProps: Readonly<ITreatmentCategoriesProps>,
        prevState: Readonly<{}>,
        snapshot?: any
    ): void {
        if (this.props.error !== prevProps.error) {
            this.alertManager.handleApiError(this.props.error);
        }

        if (this.props.retrieved !== prevProps.retrieved) {
            this.setState({treatmentCategories: this.props.retrieved});
        }
    }

    componentWillUnmount() {
        this.props.reset(this.props.eventSource);

        if (null !== this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-xl-8">
                        <div className="view-header">
                            <div className="view-title">
                                <Translation text={'treatmentCategories.title'}/>
                            </div>
                            <div className="action-container">
                                <button className="btn btn-theme"
                                        type="button"
                                        onClick={() => this.toggleAddModal()}>
                                    <Translation text={'treatmentCategories.btnAdd'}/></button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-8">
                        <CustomCard showLocalLoader={this.props.loading}>
                            <CustomCard.Body>
                                {this.renderTreatmentCategoriesList()}

                                <CustomPagination retrieved={this.props.retrieved}
                                                  basePath="dashboard"
                                                  provider={this.getTreatmentCategoriesList}/>
                            </CustomCard.Body>
                        </CustomCard>
                    </div>
                </div>

                <AddTreatmentCategory authToken={this.props.authToken}
                                      error={this.props.error}
                                      retrieved={this.props.retrieved}
                                      selectedTreatmentCategory={this.state.selectedTreatmentCategory}
                                      addModalShown={this.state.addModalShown}
                                      closeAddModal={this.closeAddModal}
                                      toggleAddModal={this.toggleAddModal}
                                      updateTreatmentCategories={this.getTreatmentCategoriesList}/>

                <BasicModal isModalShown={this.state.deleteModalShown} closeModal={this.closeDeleteModal}>
                    <CustomCard type={CustomCardType.MODAL_CARD}>
                        <CustomCard.Body>
                            <div className="modal-header">
                                <Translation text={'modal.confirm'}/>
                                <button className="btn-modal-close" onClick={() => this.toggleDeleteModal()}>
                                    <span className="feather icon-x"/>
                                </button>
                            </div>
                            <div className="modal-body">
                                <Translation text={'treatmentCategories.deleteModal.title'}/>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-danger-outline mr-4"
                                        onClick={() => this.toggleDeleteModal()}>
                                    <Translation text={'button.no'}/>
                                </button>
                                <button className="btn btn-secondary-theme"
                                        onClick={() => this.deleteTreatmentCategory()}>
                                    <Translation text={'button.yes'}/>
                                </button>
                            </div>
                        </CustomCard.Body>
                    </CustomCard>
                </BasicModal>
            </React.Fragment>
        );
    }

    private renderTreatmentCategoriesList = () => {
        if (!this.state.treatmentCategories || !this.state.treatmentCategories['hydra:member'] ||
            !Array.isArray(this.state.treatmentCategories['hydra:member']) || !this.state.treatmentCategories['hydra:member'].length) {
            return (
                <p>
                    <Translation text={'insuranceClinicReport.noReportData'}/>
                </p>
            );
        }

        return (
            <React.Fragment>
                <table className="data-table">
                    <thead>
                    <tr>
                        <th>Treatment Category</th>
                        <th colSpan={2}/>
                    </tr>
                    </thead>
                    <tbody>
                    {this.renderTableRows()}
                    </tbody>
                </table>

            </React.Fragment>
        )
    };

    private renderTableRows() {
        if (!this.state.treatmentCategories) {
            return;
        }

        const rows: any[] = [];
        this.state.treatmentCategories['hydra:member'].map((item: any) => {
            return rows.push(
                <tr key={item['id']}>
                    <td>{item['name']}</td>
                    <td className="text-right">
                        <Link className="btn btn-action mr-4" to={`/dashboard/treatment-categories/${item['id']}`}>
                            <span className="feather icon-file-text"/>
                        </Link>

                        <button className="btn btn-action mr-4"
                                onClick={() => this.toggleAddModal(item)}>
                            <span className="feather icon-edit"/>
                        </button>

                        <button className="btn btn-action"
                                onClick={() => this.toggleDeleteModal(item)}>
                            <span className="feather icon-trash"/>
                        </button>
                    </td>
                </tr>
            )
        });

        return rows;
    }

    private toggleAddModal = (item?: { [key: string]: any }) => {
        if (item) {
            this.setState({selectedTreatmentCategory: item})
        }

        this.setState({addModalShown: !this.state.addModalShown});
    };

    private toggleDeleteModal = (item?: { [key: string]: any }) => {
        if (item) {
            this.setState({selectedTreatmentCategory: item})
        }

        this.setState({
            deleteModalShown: !this.state.deleteModalShown,
        });
    };

    private closeAddModal = () => {
        return this.setState({addModalShown: false, selectedTreatmentCategory: null});
    };

    private closeDeleteModal = () => {
        return this.setState({deleteModalShown: false});
    };

    private deleteTreatmentCategory = () => {
        this.props.del(this.state.selectedTreatmentCategory, this.props.authToken);
        let treatmentCategoriesState = this.state.treatmentCategories;
        let treatmentCategories;
        if (treatmentCategoriesState) {
            treatmentCategories = treatmentCategoriesState['hydra:member'].filter((el: any) => {
                if (this.state.selectedTreatmentCategory) {
                    return el.id !== this.state.selectedTreatmentCategory.id;
                }
            });
        }

        const modifiedTreatmentCategories = Object.assign({}, treatmentCategoriesState, {'hydra:member': treatmentCategories});

        this.setState({
            deleteModalShown: false,
            treatmentCategories: modifiedTreatmentCategories,
            selectedTreatmentCategory: null,
        });

        this.alertManager.addAlert('Treatment category was deleted');
    };

    private getTreatmentCategoriesList = (searchParams: typeof RestQueryParams) => {
        this.props.list(
            `treatment_categories${searchParams.prepareQuery()}`,
            this.props.authToken
        );
    };
}

export default withTranslation()(connect(
    (state: RootState) => ({
        retrieved: retrievedTreatmentCategoryListSelector(state),
        loading: treatmentCategoryListLoadingSelector(state),
        error: treatmentCategoryListErrorSelector(state),
        eventSource: treatmentCategoryListEventSourceSelector(state),
        authToken: authTokenSelector(state)
    }),
    {
        list,
        reset,
        del,
    }
)(withRouter(TreatmentCategories)));
