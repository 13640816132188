import { Observable } from "rxjs";
import { RestQueryParams } from "common-web";
import {treatmentTypeAPI} from "./provider/treatmentTypeAPI";

export interface ITreatmentCategoryTranslation {
    en?: string;
    es?: string;
    pl?: string;
    de?: string;
}

export function addTreatmentCategoryAPI(
    accessToken: string,
    name: string,
    nameTranslation: ITreatmentCategoryTranslation,
): Observable<any> {
    return treatmentTypeAPI.post(
        `treatment_categories`,
        {
            name: name,
            nameTranslation: nameTranslation
        },
        new RestQueryParams(),
        {
            Authorization: `Bearer ${accessToken}`
        }
    );
}
