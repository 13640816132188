import {combineEpics, Epic, ofType, StateObservable} from "redux-observable";
import { catchError, switchMap, mergeMap } from "rxjs/operators";
import { of } from "rxjs";
import { PayloadAction } from "redux-starter-kit";
import {
    IMetadataCategory,
    retrievePaymentOptions,
    changePaymentOptions,
    changeTreatmentCategories,
    setMetadataError,
    retrieveExtraServices,
    changeExtraServices,
    IRetrieveMetadataCategory
} from "../reducers/metadataSlice";
import {getClinicPaymentOptionsAPI} from "../../api/getClinicPaymentOptions";
import {getTreatmentCategoriesAPI} from "common-web";
import {getClinicExtraServicesAPI} from "../../api/getClinicExtraServices";

const changePaymentOptionsMetadata: Epic = (action$, state$: StateObservable<any>) =>
  action$.pipe(
    ofType(retrievePaymentOptions.type),
    switchMap((action: PayloadAction<IRetrieveMetadataCategory>): any => {
      return getClinicPaymentOptionsAPI((action.payload as any).authToken).pipe(
        mergeMap((response: any) => {
          let paymentOptions: IMetadataCategory[] = [];

          response['hydra:member'].map((dataItem: any) => paymentOptions.push({
            value: dataItem.id,
            label: dataItem.name,
          }));

          return of(
            changePaymentOptions(paymentOptions)
          );
        }),
        catchError((error: any) => {
          return of(
            setMetadataError(error.toString()),
          )
        })
      )
    })
  );

const changeTreatmentCategoriesMetadata: Epic = (action$, state$: StateObservable<any>) =>
  action$.pipe(
    ofType(retrievePaymentOptions.type),
    switchMap((action: PayloadAction<IRetrieveMetadataCategory>): any => {
      return getTreatmentCategoriesAPI((action.payload as any).authToken).pipe(
        mergeMap((response: any) => {
        //   return of(changeMetadataCategory(response, changeTreatmentCategoriesMetadata));
          let treatmentCategories: IMetadataCategory[] = [];

          response['hydra:member'].map((dataItem: any) => treatmentCategories.push({
            value: dataItem.id,
            label: dataItem.name,
          }));

           //let services = changeMetadataCategory(response);
          return of(
            changeTreatmentCategories(treatmentCategories)
          );
        }),
        catchError((error: any) => {
          return of(
            setMetadataError(error.toString()),
          )
        })
      )
    })
  );

const changeExtraServicesMetadata: Epic = (action$, state$: StateObservable<any>) =>
  action$.pipe(
    ofType(retrieveExtraServices.type),
    switchMap((action: PayloadAction<IRetrieveMetadataCategory>): any => {
      return getClinicExtraServicesAPI((action.payload as any).authToken).pipe(
        mergeMap((response: any) => {
          let services: IMetadataCategory[] = [];

          response['hydra:member'].map((dataItem: any) => services.push({
            value: dataItem.id,
            label: dataItem.name,
          }));

          return of(
            changeExtraServices(services)
          );
        }),
        catchError((error: any) => {
          return of(
            setMetadataError(error.toString()),
          )
        })
      )
    })
  );

const metadataEpic = combineEpics(
  changePaymentOptionsMetadata,
  changeTreatmentCategoriesMetadata,
  changeExtraServicesMetadata
);

export default metadataEpic;
