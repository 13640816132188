import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {
  authTokenSelector,
  CustomCard,
  DateConverter,
  CustomPagination,
  RestQueryParams,
  Translation,
  formatDateToString
} from "common-web";
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {connect} from "react-redux";
import {RootState} from "../../../store/reducers";
import {DateRangePicker} from "rsuite";
import moment from 'moment';
import { saveAs } from 'file-saver';
import {isNullOrUndefined} from "../../../utils/runtimeUtils";
import {IAlertManagerService} from "../../../service/alertManagerService";
import {WithTranslation, withTranslation} from "react-i18next";
import { retrieveInsuranceAxaReport } from '../../../store/reducers/insuranceAxaReportSlice';
import {
  insuranceAxaReportErrorSelector,
  insuranceAxaReportLoaderSelector,
  insuranceAxaReportSelector
} from "../../../store/selectors/insuranceAxaReportSelectors";

const queryString = require('query-string');

const datepickerRanges: any[] = [
  {
    label: 'Previous Month',
    value: [
      new Date(moment().subtract(1, 'months').startOf('month').format()),
      new Date(moment().subtract(1, 'months').endOf('month').format())
    ]
  },
  {
    label: 'last7Days',
    value: [
      new Date(moment().subtract(7, 'days').format()),
      new Date(moment().endOf('day').format())]
  },
  {
    label: 'today',
    value: [
      new Date(moment().startOf('day').format()),
      new Date(moment().endOf('day').format())
    ]
  },
  {
    label: 'Current Month',
    value: [
      new Date(moment().startOf('month').format()),
      new Date(moment().endOf('month').format())
    ]
  },
];

interface IConnectedInsuranceAxaReportProps {
  readonly insuranceAxaReport: {[key: string]: any} | null;
  readonly loading: boolean;
  readonly error: string | null;
  readonly authToken: string;
  readonly retrieveInsuranceAxaReport: typeof retrieveInsuranceAxaReport
}

interface IExternalInsuranceAxaReportProps {}

interface IInsuranceAxaReportProps extends
  IConnectedInsuranceAxaReportProps,
  IExternalInsuranceAxaReportProps,
  RouteComponentProps,
  WithTranslation {}

interface IInsuranceAxaReportState {
  isLoading: boolean;
  defaultDateValue: any;
  reportStartDate: string;
  reportEndDate: string;
}

class InsuranceAxaReport extends React.Component<IInsuranceAxaReportProps, IInsuranceAxaReportState> {
  @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

  constructor(props: IInsuranceAxaReportProps) {
    super(props);

    const date = new Date(),
      defaultStartValue = new Date(date.getFullYear(), date.getMonth(), 1),
      defaultEndValue = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    this.state = {
      isLoading: false,
      defaultDateValue: [defaultStartValue, defaultEndValue],
      reportStartDate: this.getTimeString(defaultStartValue),
      reportEndDate: this.getTimeString(defaultEndValue),
    };
    fixInjectedProperties(this);
  }

  componentDidUpdate(
    prevProps: Readonly<IInsuranceAxaReportProps>,
    prevState: Readonly<IInsuranceAxaReportState>,
    snapshot?: any
  ): void {
    if (this.props.error !== prevProps.error) {
      this.alertManager.handleApiError(this.props.error);
    }
  }

  componentWillUnmount() {}

  render() {
    const {t} = this.props;
    return (
      <React.Fragment>
          <div className="row">
              <div className="col-xl-6">
                <div className="view-header">
                  <div className="view-title">
                    <Translation text={'insuranceAxaReport.title'}/>
                  </div>
                </div>
              </div>
          </div>

        <div className="row">
          <div className="col-xl-6">
            <div className="filter-container">
              <div className="group-control mr-4">
              <DateRangePicker placeholder={t('insuranceAxaReport.selectDate')}
                               onChange={this.handleDateChange}
                               placement="autoVerticalStart"
                               ranges={datepickerRanges}
                               defaultValue={this.state.defaultDateValue}
                               renderValue={(value) => {
                                 return `${formatDateToString(value[0])} - ${formatDateToString(value[1])}`;
                               }}
              />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className='col-xl-6'>
            <CustomCard showLocalLoader={this.props.loading}>
              <CustomCard.Body>
                {this.renderReportList()}

                <CustomPagination retrieved={this.props.insuranceAxaReport}
                                  basePath="dashboard"
                                  path="axa-report"
                                  provider={this.getInsuranceAxaReportList}/>
              </CustomCard.Body>
            </CustomCard>


          </div>
        </div>
      </React.Fragment>
    );
  }

  private renderReportList = () => {
    if (!this.props.insuranceAxaReport || !this.props.insuranceAxaReport['hydra:member'] ||
      !Array.isArray(this.props.insuranceAxaReport['hydra:member']) || !this.props.insuranceAxaReport['hydra:member'].length) {
      return (
        <p>
          <Translation text={'insuranceAxaReport.noReportData'}/>
        </p>
      );
    }

    return (
        <table className="data-table">
          <thead>
            <tr>
              <th><Translation text={'insuranceAxaReport.reportTable.headers.date'}/></th>
              <th><Translation text={'insuranceAxaReport.reportTable.headers.insurancesAmount'}/></th>
              <th />
            </tr>
          </thead>
          <tbody>
            {this.renderTableRows()}
          </tbody>
        </table>
    )
  };

  private renderTableRows() {
    if(!this.props.insuranceAxaReport) {
      return;
    }

    const rows: any[] = [],
      list = this.props.insuranceAxaReport['hydra:member'].sort(this.sortMethod);

    list.map((item: any) => {
      let uploadedAt = new Date(new Date(item.uploadedAt).getTime());
      uploadedAt.setDate(uploadedAt.getDate() - 1);

      return rows.push(
        <tr key={item.id}>
          <td className="align-middle"><DateConverter date={uploadedAt}/></td>
          <td className="align-middle text-center">{item.itemsInFile}</td>
          <td className="text-right">
            <button className='btn btn-action' onClick={() => this.downloadReport(item)}>
              <span className="feather icon-download"/>
            </button>
          </td>
        </tr>
      )
    });

    return rows;
  }

  private handleDateChange = (e: any) => {
    if (!e.length || isNullOrUndefined(e)) {
      return;
    }

    const startDate = this.getTimeString(e[0]),
      endDate = this.getTimeString(e[1]),
      searchParams = queryString.parse(this.props.location.search),
      itemsPerPage = searchParams.itemsPerPage ? `&itemsPerPage=${searchParams.itemsPerPage}` : null;
    const params = `page=1${itemsPerPage}&uploadedAt[after]=${startDate}&uploadedAt[before]=${endDate}`;
    this.props.retrieveInsuranceAxaReport(params);

    this.setState({
      reportStartDate: startDate,
      reportEndDate: endDate
    })
  };

  private downloadReport = (item: {[key: string]: any}) => {
    return saveAs(item.file, item.filePath);
  };

  private getInsuranceAxaReportList = (searchParams: typeof RestQueryParams) => {
    this.props.retrieveInsuranceAxaReport(`axa_report${searchParams}`);
  };

  private getTimeString(value: Date): string {
    return new Date(value.getTime() - (value.getTimezoneOffset() * 60000)).toISOString().split('T')[0];
  }

  private sortMethod(a: any, b: any): number {
    const aDate = new Date(a.uploadedAt),
      bDate = new Date(b.uploadedAt),
      aTime = aDate.getTime(),
      bTime = bDate.getTime();

    return aTime > bTime ? -1 : aTime !== bTime ? 1 : 0;
  }
}

export default withTranslation()(connect(
  (state: RootState) => ({
    insuranceAxaReport: insuranceAxaReportSelector(state),
    loading: insuranceAxaReportLoaderSelector(state),
    error: insuranceAxaReportErrorSelector(state),
    authToken: authTokenSelector(state),
  }),
  {
    retrieveInsuranceAxaReport
  }
)(withRouter(InsuranceAxaReport)));
