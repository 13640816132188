import {Observable} from "rxjs";
import {RestQueryParams} from "common-web";
import {clinicAPI} from "./provider/clinicAPI";

export interface IInquirySubject {
    id?: string;
    firstName: string;
    lastName: string;
    birthDate: string;
    address?: {
        addressLine1: string | null,
        addressLine2: string | null,
        addressLine3: string | null,
        postcode: string | null,
        city: string | null,
        country: string | null,
    }
    main?: boolean;
}

export function changeInquiryAPI(
    inquiryId: string,
    authToken: string,
    from: Date,
    to: Date,
    treatmentCategoryId: string,
    destinationId: string,
    inquirySubjects: IInquirySubject[],
    clinicId: string | null,
    clinicName: string | null,
    patientEmail: string | null,
    visitDate: string | null,
    source: string | null,
    settledWithClinic: boolean,
    lifeThreat: boolean = true,
    reference: null = null,
): Observable<any> {
    const payload: any = {
        lifeThreat: lifeThreat,
        inquirySubjects: inquirySubjects,
        reference: reference,
        from: from,
        to: to,
        treatmentCategoryId: treatmentCategoryId,
        destination: destinationId,
        clinicName: clinicName,
        patientEmail: patientEmail,
        visitDate: visitDate,
        source: source,
        settledWithClinic: settledWithClinic,
    };
    let headers = undefined;
    if (undefined !== authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return clinicAPI.put(
        `inquiry/${inquiryId}/update`,
        payload,
        new RestQueryParams(),
        headers
    );
}
