import {combineEpics, Epic, ofType, StateObservable} from "redux-observable";
import { catchError, switchMap, mergeMap } from "rxjs/operators";
import { of } from "rxjs";
import { PayloadAction } from "redux-starter-kit";
import {addAlert, setClinicStateFailure} from "common-web";
import {addClinic, IAddClinic, changeClinicStateLoading} from "../reducers/clinicSlice";
import { list } from '../../actions/clinic/list';
import {createClinicAPI} from "../../api/createClinic";

const addClinicEpic: Epic = (action$, state$: StateObservable<any>) =>
  action$.pipe(
    ofType(addClinic.type),
      switchMap((action: PayloadAction<IAddClinic>): any => {
        return createClinicAPI(
          (state$ as any).value.auth.authToken,
          action.payload
        ).pipe(
          mergeMap((resp) => {
              const query = state$.value.router.location.query;
              const page = query.page ? query.page : 1;
              const itemsPerPage = query.itemsPerPage ? query.itemsPerPage : 10;

            return of(
              changeClinicStateLoading(false),
              addAlert({message: 'Clinic has been added successfully.'}),
              list(
                `clinics?page=${page}&itemsPerPage=${itemsPerPage}&order[createdAt]=DESC`,
                  state$.value.auth.authToken
              )
            )
          }),
          catchError((error: any) => {
              const message = error.response ? error.response['hydra:description'] : error.message;

            return of(
              changeClinicStateLoading(false),
              setClinicStateFailure(error.toString()),
              addAlert({message: message})
            )
          })
        )
      })
    );

const clinicStateEpic = combineEpics(
  addClinicEpic
);

export default clinicStateEpic;
