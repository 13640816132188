import React from "react";
import { connect } from "react-redux";
import {
  clinicProfileLoadingSelector,
  CustomCard,
  DateConverter,
  Form,
  IFormConfig,
  transformToPriceString,
  Translation
} from "common-web";
import {RootState} from "../../../../store/reducers";
import styles from "./styles.module.scss";
import {clinicDetailsFormConfig} from "./clinicDetialsFormConfig";
import ProposePlanModal from "./ProposePlanModal";

interface IClinicSubscriptionPlanConnectedProps {
  readonly isLoading: boolean;
}

interface IClinicSubscriptionPlanExternalProps {
  clinicLogin: string;
  clinic: {[key: string]: any};
  clinicId: string;
}

interface IClinicSubscriptionPlanProps extends
  IClinicSubscriptionPlanConnectedProps,
  IClinicSubscriptionPlanExternalProps {}

interface IClinicSubscriptionPlanState {
  isLoading: boolean;
  formConfig: typeof IFormConfig;
  proposePlanModalShown: boolean;
}

class ClinicSubscriptionPlan extends React.Component<IClinicSubscriptionPlanProps, IClinicSubscriptionPlanState> {
  constructor(props: IClinicSubscriptionPlanProps) {
    super(props);

    this.state = {
      isLoading: true,
      formConfig: clinicDetailsFormConfig,
      proposePlanModalShown: false
    };
  }

  componentDidMount(): void {
    this.updateFormConfig(this.props.clinicLogin);
  }

  componentDidUpdate(
    prevProps: Readonly<IClinicSubscriptionPlanProps>,
    prevState: Readonly<IClinicSubscriptionPlanState>,
    snapshot?: any
  ): void {
    if (this.props.clinicLogin !== prevProps.clinicLogin) {
      this.updateFormConfig(this.props.clinicLogin);
    }

    if(this.props.isLoading !== prevProps.isLoading &&
        !this.props.isLoading) {
      this.setState({isLoading: false})
    }
  }

  componentWillUnmount() {
    this.updateFormConfig(null);
  }

  render() {
    let currentSubscription: {[key: string]: any} | null = null,
        currentSubscriptionDetails: {[key: string]: any} | null = null,
        proposedSubscriptions: {[key: string]: any}[] = [],
        paymentData,
        currentPayment;
    if (this.props.clinic &&
        this.props.clinic.account &&
        this.props.clinic.account.subscription) {
          currentSubscription = this.props.clinic.account.subscription.currentSubscriptionEntry;
          currentSubscriptionDetails = currentSubscription?.subscriptionDefinition?.subscriptionDetails;
          proposedSubscriptions = this.props.clinic.account.subscription.proposedPlans;
          let payments = this.props.clinic.account.subscription.currentSubscriptionEntry?.payments;

      if (payments && payments.length >= 2) {
        payments.reduce((a: { [key: string]: any }, b: { [key: string]: any }) => {
          return currentPayment = Number(a.value.amount) > Number(b.value.amount) ? a : b;
        });
      } else if (payments && payments.length < 2) {
        currentPayment = payments[0];
      } else {
        currentPayment = null;
      }

      if (payments) {
        paymentData = payments.sort((a: any, b: any): number => {
          const aDate = new Date(a.paidAt),
              bDate = new Date(b.paidAt);

          return aDate.getTime() > bDate.getTime() ? -1 : aDate.getTime() !== bDate.getTime() ? 1 : 0;
        });
      }
    }

    return (
      <React.Fragment>
      <CustomCard showLocalLoader={this.state.isLoading}>
        <CustomCard.Header>
          <div className="d-flex justify-content-between align-items-center">
            <Translation text={'subscriptionPlans.details.title'}/>
            <button className="btn btn-theme"
                    onClick={() => this.toggleModal()}>
              <Translation text={'subscriptionPlans.details.proposePlan'}/>
            </button>
          </div>
        </CustomCard.Header>
        <CustomCard.Body>
          <div className="mb-3">
            <Form  config={this.state.formConfig}
                   controlName={'clinicDetailsForm'}/>
          </div>
          <div className={styles.planContainer}>
            <div className={styles.planDetails}>
              <p className={styles.planTitle}>
                <Translation text={'subscriptionPlans.details.planName'}/>
                <span>{currentSubscriptionDetails ? currentSubscriptionDetails?.name : '---'}</span>
              </p>
              <p><Translation text={'subscriptionPlans.details.planPrice'}/>
                <span>
                  {paymentData && paymentData.length && paymentData[0].value ?
                    transformToPriceString(paymentData[0].value.currency.code, paymentData[0].value.amount / 100) :
                    '---'}
                </span>
              </p>
              <p><Translation text={'subscriptionPlans.details.purchaseDate'}/>
                {currentPayment ? <DateConverter date={currentPayment.paidAt}/> : '---'}
              </p>
            </div>

            <div className={styles.planDetails}>
              <p><Translation text={'subscriptionPlans.details.telemedicineHours'}/>
                <span>{currentSubscriptionDetails ?
                    currentSubscriptionDetails?.subscriptionRestrictions?.consultationTimeLimit / 60:
                    '---'}</span>
              </p>
              <p><Translation text={'subscriptionPlans.details.clinicHunterWidget'}/>
                <span>
                  {currentSubscriptionDetails &&
                    currentSubscriptionDetails?.subscriptionRestrictions?.appCalendarWidgetAvailable ?
                    <i className={`feather icon-check ${styles.available}`}/> :
                    <i className="feather icon-x"/>}
                </span>
              </p>
              <p><Translation text={'subscriptionPlans.details.clinicWidget'}/>
                <span>{currentSubscriptionDetails ?
                    currentSubscriptionDetails?.subscriptionRestrictions?.calendarCountLimit :
                    '---'}</span>
              </p>
            </div>

            <div className={styles.planDetails}>
              <h5><Translation text={'subscriptionPlans.details.proposedPlan'}/></h5>
              <p><Translation text={'subscriptionPlans.details.planPrice'}/>
                <span>
                  {proposedSubscriptions.length ?
                    transformToPriceString(
                        proposedSubscriptions[0].subscriptionDetails.price.currency.code,
                        proposedSubscriptions[0].subscriptionDetails.price.amount / 100) :
                    '---'}
                </span>
              </p>
              <p><Translation text={'subscriptionPlans.details.telemedicineHours'}/>
                <span>
                  {proposedSubscriptions.length ?
                    proposedSubscriptions[0].subscriptionDetails?.subscriptionRestrictions?.consultationTimeLimit / 60
                    : '---'}
                </span>
              </p>
              <p><Translation text={'subscriptionPlans.details.clinicHunterWidget'}/>
                <span>
                  {proposedSubscriptions.length &&
                    proposedSubscriptions[0].subscriptionDetails?.subscriptionRestrictions?.appCalendarWidgetAvailable ?
                    <i className={`feather icon-check ${styles.available}`}/> :
                    <i className="feather icon-x"/>}
                </span>
              </p>
              <p><Translation text={'subscriptionPlans.details.clinicWidget'}/>
                <span>
                  {proposedSubscriptions.length ?
                      proposedSubscriptions[0].subscriptionDetails?.subscriptionRestrictions?.calendarCountLimit
                      : '---'}
                </span>
              </p>
            </div>
          </div>
        </CustomCard.Body>
      </CustomCard>

      {this.state.proposePlanModalShown &&
      <ProposePlanModal addSubscriptionModalShown={this.state.proposePlanModalShown}
                        closeSubscriptionModal={this.toggleModal}
                        toggleSubscriptionModal={this.toggleModal}
                        clinicId={this.props.clinicId}
                        proposedPlans={this.props.clinic.account.subscription.proposedPlans}
      />
      }
    </React.Fragment>
    );
  }

  private updateFormConfig = (clinicLogin: string | null) => {
    let updatedFormConfig = Object.assign({}, this.state.formConfig);
    updatedFormConfig.controls[0].controls.email.defaultValue = clinicLogin;
    updatedFormConfig.controls[0].controls.email.value = clinicLogin;
    this.setState({formConfig: updatedFormConfig});
  };

  private toggleModal = () => {
    this.setState({proposePlanModalShown: !this.state.proposePlanModalShown});
  }
}

export default connect(
  (state: RootState) => ({
    isLoading: clinicProfileLoadingSelector(state),
  }),
  {}
)(ClinicSubscriptionPlan);
