import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {
    authTokenSelector,
    CustomCard,
    DateConverter,
    initInquirySummary,
    CustomPagination,
    Provision,
    RestQueryParams,
    totalClinicFeeSelector,
    totalPremiumSelector,
    totalProfitAxaSelector,
    totalSystemFeeSelector,
    Translation,
    formatDateToString
} from "common-web";
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {connect} from "react-redux";
import {RootState} from "../../../store/reducers";
import {DateRangePicker} from "rsuite";
import moment from 'moment';
import {isNullOrUndefined} from "../../../utils/runtimeUtils";
import {IAlertManagerService} from "../../../service/alertManagerService";
import {
    inquiryListErrorSelector,
    inquiryListEventSourceSelector,
    inquiryListLoadingSelector,
    retrievedInquiryListSelector,
} from "../../../store/selectors/inquiryListSelectors";
import {list, reset} from "../../../actions/inquiry/list";
import {WithTranslation, withTranslation} from "react-i18next";
import InsuranceOfferDetails, {IOfferDetailType} from "../InsuranceOfferDetails";
import SettleWithClinic from "../SettleClinicCommission";
import InsuranceReportDownload from '../InsuranceReportDownload';
import SelectClinicInput from './SelectClinicInput';
import EditInsuranceReport from "./EditInsuranceReport";
import InsuranceStatusBadge, {InsuranceStatus} from "../InsuranceStatusBadge";
import EditInsuranceDate from './EditInsuranceDate';

const datepickerRanges: any[] = [
    {
        label: 'Previous Month',
        value: [
            new Date(moment().subtract(1, 'months').startOf('month').format()),
            new Date(moment().subtract(1, 'months').endOf('month').format())
        ]
    },
    {
        label: 'last7Days',
        value: [
            new Date(moment().subtract(7, 'days').format()),
            new Date(moment().endOf('day').format())]
    },
    {
        label: 'today',
        value: [
            new Date(moment().startOf('day').format()),
            new Date(moment().endOf('day').format())
        ]
    },
    {
        label: 'Current Month',
        value: [
            new Date(moment().startOf('month').format()),
            new Date(moment().endOf('month').format())
        ]
    },
];

interface IConnectedInsuranceClinicReportProps {
    readonly retrieved: { [key: string]: any };
    readonly loading: boolean;
    readonly error: string | null;
    readonly eventSource: EventSource;
    readonly list: any;
    readonly reset: any;
    readonly authToken: string;
    readonly totalCommission: { [key: string]: any };
    readonly totalClinicFee: { [key: string]: any };
    readonly totalClinicHunterCommission: { [key: string]: any };
    readonly totalAxaCommission: { [key: string]: any };
    readonly initInquirySummary: typeof initInquirySummary;
}

interface IExternalInsuranceClinicReportProps {
}

interface IInsuranceClinicReportProps extends IConnectedInsuranceClinicReportProps,
    IExternalInsuranceClinicReportProps,
    RouteComponentProps,
    WithTranslation {
}

interface IInsuranceClinicReportState {
    defaultDateValue: any;
    reportStartDate: string;
    reportEndDate: string;
    reportInsuranceNumber: string | null;
    isEditModalShown: boolean;
    isEditInsuranceDateModalShown: boolean;
    selectedInquiry: {[key: string]: any} | null;
}

class InsuranceClinicReport extends React.Component<IInsuranceClinicReportProps, IInsuranceClinicReportState> {
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

    constructor(props: IInsuranceClinicReportProps) {
        super(props);

        let date = new Date();
        const defaultStartValue = new Date(date.getFullYear(), date.getMonth(), 1),
            defaultEndValue = new Date(date.getFullYear(), date.getMonth() + 1, 0);

        this.state = {
            defaultDateValue: [defaultStartValue, defaultEndValue],
            reportStartDate: this.getTimeString(defaultStartValue),
            reportEndDate: this.getTimeString(defaultEndValue),
            reportInsuranceNumber: null,
            isEditModalShown: false,
            isEditInsuranceDateModalShown: false,
            selectedInquiry: null
        };

        fixInjectedProperties(this);
    }


    componentDidMount() {
        this.getInquiriesSummary(this.props.authToken);
    }

    componentDidUpdate(
        prevProps: Readonly<IInsuranceClinicReportProps>,
        prevState: Readonly<IInsuranceClinicReportState>,
        snapshot?: any
    ): void {
        if (this.props.error !== prevProps.error) {
            this.alertManager.handleApiError(this.props.error);
        }

        if (this.state.reportStartDate !== prevState.reportStartDate ||
            this.state.reportEndDate !== prevState.reportEndDate) {
            this.getInquiriesSummary(this.props.authToken);
        }
    }

    componentWillUnmount() {
        this.props.reset(this.props.eventSource);
    }

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="view-header">
                            <div className="view-title">
                                <Translation text={'insuranceClinicReport.title'}/>
                            </div>
                            <Provision dataRange={[this.state.reportStartDate, this.state.reportEndDate]}
                                       data={[
                                           {label: 'Insurance Total', value: this.props.totalCommission, class: "insurance"},
                                           {label: 'AXA Commission Total', value: this.props.totalAxaCommission},
                                           {label: 'Clinic Commission Total', value: this.props.totalClinicFee},
                                           {label: 'CH Commission Total', value: this.props.totalClinicHunterCommission}
                                       ]}/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="filter-container justify-content-between">
                            <div className="d-flex">
                                <SelectClinicInput/>

                                <div className="group-control mr-4">
                                    <DateRangePicker placeholder="Select Date Range"
                                                     onChange={this.handleDateChange}
                                                     placement="autoVerticalStart"
                                                     ranges={datepickerRanges}
                                                     defaultValue={this.state.defaultDateValue}
                                                     renderValue={(value) => {
                                                         return `${formatDateToString(value[0])} - ${formatDateToString(value[1])}`;
                                                     }}
                                    />
                                </div>
                            </div>

                            <InsuranceReportDownload authToken={this.props.authToken}
                                                     reportStartDate={this.state.reportStartDate}
                                                     reportEndDate={this.state.reportEndDate}/>
                        </div>
                    </div>
                </div>

                <CustomCard showLocalLoader={this.props.loading}>
                    <CustomCard.Body>
                        {this.renderInsuranceList()}

                        <CustomPagination retrieved={this.props.retrieved}
                                          basePath="dashboard"
                                          provider={this.getInquiryList}/>
                    </CustomCard.Body>
                </CustomCard>

                {this.state.isEditModalShown ?
                    <EditInsuranceReport isModalShown={this.state.isEditModalShown}
                                         authToken={this.props.authToken}
                                         toggleModal={this.toggleEditInquiry}
                                         inquiry={this.state.selectedInquiry}
                                         getInquiryList={this.getInquiryList}/> : null}

                {this.state.isEditInsuranceDateModalShown ?
                    <EditInsuranceDate isModalShown={this.state.isEditInsuranceDateModalShown}
                                         authToken={this.props.authToken}
                                         toggleModal={this.toggleEditInquiryDate}
                                         inquiry={this.state.selectedInquiry}
                                         getInquiryList={this.getInquiryList}/> : null}
            </React.Fragment>
        );
    }

    private renderInsuranceList = () => {
        if (!this.props.retrieved || !this.props.retrieved['hydra:member'] ||
            !Array.isArray(this.props.retrieved['hydra:member']) || !this.props.retrieved['hydra:member'].length) {
            return (
                <p>
                    <Translation text={'insuranceClinicReport.noReportData'}/>
                </p>
            );
        }
        const rows: any[] = [],
            list = this.props.retrieved['hydra:member'].sort(this.sortMethod);

        list.map((item: any) => {
            if (item.acceptedOffer !== null && item.acceptedOffer.paidAt !== null) {
                const mainSubject = item.inquirySubjects.find((subject: any) => subject.main === true);
                const isClinic = ('clinic' === item.createdBy?.type),
                    offerDetails = item.offers[0],
                    isOfferModified = offerDetails?.status === InsuranceStatus.MODIFIED,
                    isStartDateAdvanced = this.isStartDateEditable(item.from),
                    paymentId = offerDetails?.payments?.[0]?.vendorPaymentId;

                return rows.push(
                    <tr key={item.id}>
                        <td><DateConverter date={item.acceptedOffer.paidAt}/></td>
                        <td>{item.acceptedOffer ? item.acceptedOffer.reference : '-'}</td>
                        <td>
                            <span className="d-block"><b>{item.clinic ? item.clinic.companyName : '-'}</b></span>
                            <span>{`${mainSubject.firstName} ${mainSubject.lastName}`}</span>
                        </td>
                        <td><InsuranceOfferDetails item={item}
                                                   type={IOfferDetailType.PRICE}/>
                        </td>

                        <td>{this.renderCommissionsDetails(item)}</td>

                        <td className="align-middle text-center">
                            <SettleWithClinic checked={item.settledWithClinic}
                                              name={item.id}
                                              authToken={this.props.authToken}/>
                        </td>
                        <td className="align-middle text-center">
                             <span
                                 className={`feather icon-${isClinic ? 'check icon-active' : 'minus'}`}
                                 aria-hidden="true"/>
                        </td>
                        <td>
                            {offerDetails ? <InsuranceStatusBadge status={offerDetails.status}/> : '---'}
                        </td>
                        <td>
                            {paymentId ? paymentId : '---'}
                        </td>
                        <td className="align-middle text-center">
                            <a className="btn btn-no-outline" href={`${process.env.REACT_APP_AUTH_API_URL}/inquiry/${item.id}/to-pdf`}
                               target={'_blank'} rel="noopener noreferrer">
                                <span className="feather icon-download custom-icon"/>
                            </a>

                            <button className="btn btn-no-outline"
                                    type="button"
                                    disabled={new Date().getTime() > new Date(item.from).getTime()}
                                    onClick={() => this.toggleEditInquiry(item)}>
                                <span className="feather icon-edit-2 custom-icon"/>
                            </button>

                            <button className="btn btn-no-outline"
                                    type="button"
                                    disabled={isOfferModified || !isStartDateAdvanced}
                                    onClick={() => this.toggleEditInquiryDate(item)}>
                                <span className="feather icon-calendar custom-icon"/>
                            </button>
                        </td>
                    </tr>
                );
            }
            return null;
        });
        if (rows.length) {
             return <table className="data-table">
                <thead>
                <tr>
                    <th><Translation text={'insuranceClinicReport.reportTable.headers.date'}/></th>
                    <th><Translation text={'insuranceClinicReport.reportTable.headers.policyNo'}/></th>
                    <th><Translation text={'insuranceClinicReport.reportTable.headers.clinic'}/></th>
                    <th><Translation text={'insuranceClinicReport.reportTable.headers.price'}/></th>
                    <th><Translation text={'insuranceClinicReport.reportTable.headers.commissions'}/></th>
                    <th className="text-center"><Translation text={'insuranceClinicReport.reportTable.headers.settledWithClinic'}/></th>
                    <th className="text-center"><Translation text={'insuranceClinicReport.reportTable.headers.addedByClinic'}/></th>
                    <th className="text-center"><Translation text={`insuranceClinicReport.reportTable.headers.status`}/></th>
                    <th className="text-center"><Translation text={`insuranceClinicReport.reportTable.headers.vendorPaymentId`}/></th>
                    <th className="text-center"><Translation text={'insuranceClinicReport.reportTable.headers.actions'}/></th>
                </tr>
                </thead>
                <tbody>
                {rows}
                </tbody>
            </table>
        }
        return <p>
            <Translation text={'insuranceClinicReport.noReportData'}/>
        </p>

    };

    private renderCommissionsDetails(item: any) {
        return (
            <div>
                <div className="commission-info">
                    <div><Translation text={'insuranceClinicReport.reportTable.commissionLabels.axa'}/></div>
                    <div><InsuranceOfferDetails item={item} type={IOfferDetailType.AXA_COMMISSION}/></div>
                </div>

                <div className="commission-info">
                    <div><Translation text={'insuranceClinicReport.reportTable.commissionLabels.clinic'}/></div>
                    <div><InsuranceOfferDetails item={item} type={IOfferDetailType.CLINIC_COMMISSION}/></div>
                </div>

                <div className="commission-info">
                    <div><Translation text={'insuranceClinicReport.reportTable.commissionLabels.clinicHunter'}/></div>
                    <div><InsuranceOfferDetails item={item} type={IOfferDetailType.CLINIC_HUNTER_COMMISSION}/></div>
                </div>
            </div>
        )
    }

    private handleDateChange = (e: any) => {
        if (!e.length || isNullOrUndefined(e)) {
            return;
        }

        const startDate = this.getTimeString(e[0]),
            endDate = this.getTimeString(e[1]);
        this.props.list(
            `inquiries?page=1&paid_at[after]=${startDate}&paid_at[before]=${endDate}`,
            this.props.authToken,
        );

        this.setState({
            reportStartDate: startDate,
            reportEndDate: endDate
        })
    };


    private getInquiryList = (searchParams: typeof RestQueryParams) => {
        searchParams = searchParams.add('paid_at_sorting', 'DESC');
        searchParams = searchParams.add('paid_at[after]', this.state.reportStartDate);
        searchParams = searchParams.add('paid_at[before]', this.state.reportEndDate);

        this.props.list(
            `inquiries${searchParams.prepareQuery()}`,
            this.props.authToken
        );
    };

    private getInquiriesSummary(authToken: string) {
        // TODO: waiting for backend
        const dateRange = `paid_at[after]=${this.state.reportStartDate}&paid_at[before]=${this.state.reportEndDate}`;

        return this.props.initInquirySummary(authToken, true, dateRange);
    }

    private getTimeString(value: Date): string {
        return new Date(value.getTime() - (value.getTimezoneOffset() * 60000)).toISOString().split('T')[0];
    }

    private sortMethod(a: any, b: any): number {
        const aDate = new Date(a.createdAt),
            bDate = new Date(b.createdAt),
            aTime = aDate.getTime(),
            bTime = bDate.getTime();

        return aTime > bTime ? -1 : aTime !== bTime ? 1 : 0;
    }

    private toggleEditInquiry = (inquiry?: {[key: string]: any} | null) => {
        this.setState({isEditModalShown: !this.state.isEditModalShown, selectedInquiry: inquiry ? inquiry : null});
    }

    private toggleEditInquiryDate = (inquiry?: {[key: string]: any} | null) => {
        this.setState({isEditInsuranceDateModalShown: !this.state.isEditInsuranceDateModalShown, selectedInquiry: inquiry ? inquiry : null});
    }

    private isStartDateEditable = (date: string) => {
        const msInOneDay = 24 * 60 * 60 * 1000,
            now = new Date(),
            diffInMs = new Date(date).getTime() - now.getTime();
        return diffInMs >= msInOneDay;
    }
}

export default withTranslation()(connect(
    (state: RootState) => ({
        retrieved: retrievedInquiryListSelector(state),
        loading: inquiryListLoadingSelector(state),
        error: inquiryListErrorSelector(state),
        eventSource: inquiryListEventSourceSelector(state),
        authToken: authTokenSelector(state),
        totalCommission: totalPremiumSelector(state),
        totalAxaCommission: totalProfitAxaSelector(state),
        totalClinicHunterCommission: totalSystemFeeSelector(state),
        totalClinicFee: totalClinicFeeSelector(state)
    }),
    {
        list,
        reset,
        initInquirySummary
    }
)(withRouter(InsuranceClinicReport)));
