import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {list, reset} from '../../../actions/recommendationDefinition/list';
import {del} from '../../../actions/recommendationDefinition/delete';
import {RootState} from "../../../store/reducers";
import {
    authTokenSelector,
    CustomCard,
    RestQueryParams,
    Translation,
    CustomCardType,
    BasicModal,
    CustomPagination
} from "common-web";
import {connect} from "react-redux";
import {WithTranslation, withTranslation} from "react-i18next";
import {IAlertManagerService} from '../../../service/alertManagerService';
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {Subscription} from "rxjs";
import {
    recommendationDefinitionListErrorSelector,
    recommendationDefinitionListEventSourceSelector,
    recommendationDefinitionListLoadingSelector,
    retrievedRecommendationDefinitionListSelector
} from "../../../store/selectors/recommendationDefinitionListSelectors";
import AddPredefinedRecommendation from './AddPredefinedRecommendation';

export enum RecommendationDefinitionType {
    SINGLE = 'single',
    CONSECUTIVE = 'consecutive',
    CONSECUTIVE_NUMBER = 'number',
    CONSECUTIVE_PERIOD = 'period'
}

interface IConnectedPredefinedRecommendationsListProps {
    readonly retrieved: any;
    readonly loading: boolean;
    readonly error: string;
    readonly eventSource: EventSource;
    readonly list: any;
    readonly reset: any;
    readonly del: typeof del;
    readonly authToken: string;
}

interface IPredefinedRecommendationsListProps extends IConnectedPredefinedRecommendationsListProps,
    RouteComponentProps,
    WithTranslation {
}

interface IPredefinedRecommendationsListState {
    addRecommendationModalShown: boolean;
    deleteRecommendationModalShown: boolean;
    retrievedRecommendations: {[key: string]: any}[] | null;
    selectedRecommendation: {[key: string]: any} | null;
}

class PredefinedRecommendationsList extends React.Component<IPredefinedRecommendationsListProps, IPredefinedRecommendationsListState> {
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;
    private subscription: Subscription | null = null;

    constructor(props: IPredefinedRecommendationsListProps) {
        super(props);

        this.state = {
            addRecommendationModalShown: false,
            deleteRecommendationModalShown: false,
            retrievedRecommendations: null,
            selectedRecommendation: null
        };

        fixInjectedProperties(this);
    }

    componentDidMount() {

    }

    componentDidUpdate(
        prevProps: Readonly<IPredefinedRecommendationsListProps>,
        prevState: Readonly<IPredefinedRecommendationsListState>,
        snapshot?: any
    ): void {
        if (this.props.error !== prevProps.error) {
            this.alertManager.handleApiError(this.props.error);
        }

        if (this.props.retrieved !== prevProps.retrieved) {
          this.setState({retrievedRecommendations: JSON.parse(JSON.stringify(this.props.retrieved))});
        }
    }

    componentWillUnmount() {
        this.props.reset(this.props.eventSource);

        if (null !== this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="view-header">
                            <div className="view-title">
                                <Translation text={'aftercare.predefinedEvents.title'}/>
                            </div>
                            <div className="action-container">
                                <button className="btn btn-theme"
                                        type="button"
                                        onClick={() => this.toggleAddRecommendationModal()}>
                                    <Translation text={'aftercare.predefinedEvents.addEvent'}/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-12">
                        <CustomCard showLocalLoader={this.props.loading}>
                            <CustomCard.Body>
                                {this.renderAfterCareList()}

                                <CustomPagination retrieved={this.state.retrievedRecommendations}
                                                  basePath="dashboard"
                                                  path="recommendations"
                                                  provider={this.getRecommendationsList}/>
                            </CustomCard.Body>
                        </CustomCard>
                    </div>
                </div>

                {this.state.addRecommendationModalShown &&
                    <AddPredefinedRecommendation addRecommendationModalShown={this.state.addRecommendationModalShown}
                                                 closeRecommendationModal={this.closeAddRecommendationModal}
                                                 toggleRecommendationModal={this.toggleAddRecommendationModal}
                                                 selectedRecommendation={this.state.selectedRecommendation}
                    />
                }

                <BasicModal isModalShown={this.state.deleteRecommendationModalShown} closeModal={this.toggleDeleteModal}>
                    <CustomCard type={CustomCardType.MODAL_CARD}>
                        <CustomCard.Body>
                            <div className="modal-header">
                                <Translation text={'aftercare.predefinedEvents.deleteEventModal.title'}/>
                                <button className="btn-modal-close" onClick={() => this.toggleDeleteModal(null)}>
                                    <span className="feather icon-x"/>
                                </button>
                            </div>
                            <div className="modal-body">
                                <Translation text={'aftercare.predefinedEvents.deleteEventModal.description'}/>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-danger-outline mr-4"
                                        onClick={() => this.toggleDeleteModal(null)}>
                                    <Translation text={'button.no'}/>
                                </button>
                                <button className="btn btn-secondary-theme"
                                        onClick={() => this.deleteRecommendation()}>
                                    <Translation text={'button.yes'}/>
                                </button>
                            </div>
                        </CustomCard.Body>
                    </CustomCard>
                </BasicModal>
            </React.Fragment>
        );
    }

    private renderAfterCareList = () => {
        if (!this.state.retrievedRecommendations ||
            !(this.state.retrievedRecommendations as any)['hydra:member'] ||
            !Array.isArray((this.state.retrievedRecommendations as any)['hydra:member'])) {
            return <p>There is no data available</p>;
        }

        return (
            <React.Fragment>
                <table className="data-table">
                    <thead>
                    <tr>
                        <th><Translation text={'aftercare.predefinedEvents.list.eventName'}/></th>
                        <th><Translation text={'aftercare.predefinedEvents.list.eventDescription'}/></th>
                        <th />
                    </tr>
                    </thead>
                    <tbody>
                        {this.renderTableRows()}
                    </tbody>
                </table>
            </React.Fragment>
        )
    };

    private renderTableRows() {
        const rows: any[] = [],
            retrievedRecommendations = Object.assign({}, this.state.retrievedRecommendations),
            list = (retrievedRecommendations as any)['hydra:member'].sort(this.sortMethod);


        list.map((item: any) => {
            return rows.push((
                <tr key={item['id']}>
                    <td>
                        {item.name}
                    </td>
                    <td>
                        {item.description}
                    </td>
                    <td className="align-middle text-right">
                        <button className="btn btn-action mr-2"
                                onClick={() => {
                                    this.setState({selectedRecommendation: item});
                                    this.toggleAddRecommendationModal()
                                }}>
                            <span className="feather icon-edit"/>
                        </button>

                        <button className="btn btn-action"
                                onClick={() => this.toggleDeleteModal(item)}>
                            <span className="feather icon-trash"/>
                        </button>
                    </td>
                </tr>
            ))
        });
        return rows;
    }

    private getRecommendationsList = (searchParams: typeof RestQueryParams) => {
        searchParams = searchParams.add('order[createdAt]', 'DESC');

        this.props.list(
            `treatment_point_definitions${searchParams.prepareQuery()}`,
            this.props.authToken
        );
    };

    private sortMethod(a: any, b: any): number {
        const aDate = new Date(a.createdAt),
            bDate = new Date(b.createdAt),
            aTime = aDate.getTime(),
            bTime = bDate.getTime();

        return aTime > bTime ? -1 : aTime !== bTime ? 1 : 0;
    }

    private toggleAddRecommendationModal = () => {
        if  (this.state.addRecommendationModalShown) {
            this.setState({
                addRecommendationModalShown: !this.state.addRecommendationModalShown,
                selectedRecommendation: null
            })
        } else {
            this.setState({
                addRecommendationModalShown: !this.state.addRecommendationModalShown
            })
        }
    };

    private closeAddRecommendationModal = () => {
        return this.setState({
            addRecommendationModalShown: false,
            selectedRecommendation: null
        });
    };

    private toggleDeleteModal = (recommendation: {[key: string]: any} | null) => {
        if  (this.state.deleteRecommendationModalShown) {
            this.setState({
                deleteRecommendationModalShown: !this.state.deleteRecommendationModalShown,
                selectedRecommendation: null
            })
        } else {
            this.setState({
                deleteRecommendationModalShown: !this.state.deleteRecommendationModalShown,
                selectedRecommendation: recommendation
            })
        }
    };

    private deleteRecommendation = () => {
        this.props.del(this.state.selectedRecommendation, this.props.authToken);

        let retrievedRecommendations = Object.assign({}, this.state.retrievedRecommendations);
        let updatedRecommendations;
        if (retrievedRecommendations) {
            updatedRecommendations = (retrievedRecommendations as any)['hydra:member'].filter((el: any) => {
                if (this.state.selectedRecommendation) {
                    return el.id !== this.state.selectedRecommendation.id;
                }
            });
        }

        const modifiedRecommendations = Object.assign({}, retrievedRecommendations, {'hydra:member': updatedRecommendations});
        this.setState({
            deleteRecommendationModalShown: false,
            retrievedRecommendations: modifiedRecommendations,
            selectedRecommendation: null,
        });

        this.alertManager.addAlert('Recommendation was deleted');
    };
}

export default withTranslation()(connect(
    (state: RootState) => ({
        retrieved: retrievedRecommendationDefinitionListSelector(state),
        loading: recommendationDefinitionListLoadingSelector(state),
        error: recommendationDefinitionListErrorSelector(state),
        eventSource: recommendationDefinitionListEventSourceSelector(state),
        authToken: authTokenSelector(state),
    }),
    {
        list,
        reset,
        del
    }
)(withRouter(PredefinedRecommendationsList)));
